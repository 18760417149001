import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import img from "assets/img/icon/629a22523fe41.png";

const Tab1 = observer(() => (
  <div className="min-h-[500px] bg-[#f5f5f5] px-[15%]">
    <div
      className="w-full h-[290px] bg-white rounded-[5px] mb-[20px]"
      style={{ boxShadow: "0 2px 10px 0 rgb(0 0 0 / 10%)" }}
    >
      <div className="w-full h-[240px]">
        <div className="w-[50%] h-[240px] p-[5px] float-left">
          <div className="w-full h-[240px] bg-white">
            <div className="w-full h-[230px]">
              <div className="w-[25%] h-[230px] leading-[150px] text-center float-left">
                <img src={img} alt="" className="w-[60%] inline" />
              </div>
              <div className="py-[10px]">
                <div className="w-full min-h-[30px]">
                  <span className="f16 fch">ETH mining mgytf</span>
                </div>
                <div className="w-full min-h-[30px]">
                  <span className="f14 fcc">
                    Liệt kê thời gian：2023-03-20 16:37:43
                  </span>
                </div>
                <div className="w-full min-h-[30px]">
                  <span className="f14 fcc">Loại：USDT</span>
                </div>
                <div className="w-full min-h-[30px]">
                  <span className="f14 fcc">
                    Mỏ：
                    <span className="f16 fcy fw">
                      Mô hình hoàn toàn thuộc sở hữu
                    </span>
                  </span>
                </div>
                <div className="w-full min-h-[30px]">
                  <span className="f14 fcc">
                    Loại hình： Giá trị sản phẩm đầu ra
                  </span>
                </div>
                <div className="w-full min-h-[30px]">
                  <span className="f14 fcc">
                    Phương pháp lợi ích：Thu nhập tự động
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[50%] h-[240px] p-[5px] float-left">
          <div className="w-full h-[240px]">
            <div className="w-full h-[240px] py-[10px] pl-[10px]">
              <div className="w-full min-h-[30px]">
                <span className="f16 fch">Chi tiết máy khai thác</span>
              </div>
              <div className="w-full min-h-[30px]">
                <span className="f14 fcc">
                  Đơn vị máy khai thác ： 500.000000 USDT
                </span>
              </div>
              <div className="w-full min-h-[30px]">
                <span className="f14 fcc">
                  Mô tả đầu ra ： Mã CK：10.000000 USDT
                </span>
              </div>
              <div className="w-full min-h-[30px]">
                <span className="f14 fcc">
                  Điều kiện mua ： Vị trí tối thiểu：0Tiền tệ nền tảng
                </span>
              </div>
              <div className="w-full min-h-[30px]">
                <span className="f14 fcc">
                  Inviting friends to mine can get 10% of the platform&lsquo;s
                  rewards for friends&lsquo; mining profits.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-[50px] mt-[10px] px-[20px]">
        <div className="w-[80%] h-[40px] float-left">
          <div className="progress">
            <div className="progress-bar" style={{ width: "68.5%" }}>
              68.5%
            </div>
          </div>
        </div>
        <div className="w-[20%] h-[40px] float-left">
          <div className="w-full h-[40px] text-center">
            <Link
              to="/issue/detail/3"
              className="css-bhso1m !text-white !py-[5px] !px-[15px]"
            >
              Mua ngay
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
));

export default Tab1;
