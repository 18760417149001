import React from "react";
import { observer } from "mobx-react-lite";

import img from "assets/img/647dbf44d6e1f.jpg";

const NotiDetailPage = observer(() => (
  <main className="css-1wr4jig !bg-white">
    <div className="css-wp2li4">
      <div className="mirror-bread-crumb css-19zx9ks">
        <span className="css-o4bcl2">Trung tâm trợ giúp</span>
      </div>
      <div className="css-4cffwv">
        <div className="css-vkw2w1">
          <h1 className="css-kxziuu">
            Remitano Thay thế Cơ chế Clawback bằng ADL cho Hợp đồng Tương lai Ký
            quỹ Coin
          </h1>
          <div className="css-ivlfe5">
            <div className="css-nqy1gg">
              <img
                src="/Public/Home/static/imgs/alogn.png"
                alt=""
                className="css-178uoae"
              />
            </div>
            <div className="css-vurnku">
              <div className="css-ccbn3d">Usdz</div>
              <div className="css-17s7mnd">2023-06-03 22:41:27</div>
            </div>
          </div>
          <div className="css-1ybfxxc">
            <article className="css-1ii68zy">
              <div className="css-3fpgoh">
                <img src={img} alt="" className="w-full" />
              </div>
              <div className="css-3fpgoh">
                <strong>
                  <span>
                    <span>
                      <span>
                        <span>
                          Remitano Thay thế Cơ chế thu hồi bằng ADL cho Hợp đồng
                          Tương lai Ký thu hồi Coin
                        </span>
                      </span>
                    </span>
                  </span>
                </strong>
              </div>
            </article>
          </div>
        </div>
      </div>
    </div>
  </main>
));

export default NotiDetailPage;
