import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { NotificationManager } from "react-notifications";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import { Portal } from "react-portal";

import { gameTypeList } from "_common/constants/statusType";

const ModalBet = observer(({ open, onClose, data }) => {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, dataGame, betGame } = useNotify();
  const {
    authStore: { user, loading, setLoading, general },
  } = useStores();

  const profit = useMemo(() => {
    let result = 0;
    const itemType = gameTypeList?.find((obj) => obj?.time === data?.type);
    if (data?.type) {
      const numRate = Number(itemType?.profit) / 100;
      const amount = Number(data?.amount);
      result = Math.round(amount * numRate);
    }

    return result;
  }, [data?.type]);

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      GameApi.betGame({
        params: {
          game_id: data.game_id,
          ...variables,
        },
      }),
    {
      onSuccess: (res, variables) => {
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["game-spot", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );

        betGame({
          user_id: user?.id,
          time: variables?.type,
          game_id: res?.data?.id,
          ...variables,
        });
        pushNotify({
          type: "betGame",
          status: "new_bet",
        });
        onClose();
      },
      onError: (error) => {
        setLoading(false);
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleSubmit = async () => {
    setLoading(true);
    if (loading) return null;
    if (!data?.amount)
      return NotificationManager.error(i18next.t("enter_large_amount_msg"));
    if (!data?.game_type)
      return NotificationManager.error(i18next.t("select_game_type_msg"));
    if (!data?.game_id)
      return NotificationManager.error(i18next.t("select_game_type_msg"));
    if (!data?.type)
      return NotificationManager.error(i18next.t("select_game_type_msg"));

    onSubmit({
      amount: data.amount,
      profit,
      type: data?.type,
      bet_game: data?.game_type,
      price: dataGame?.lastPrice,
    });
    return null;
  };

  const usdtBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "usdt"),
    [user]
  );

  if (!open) return <div />;

  return (
    <Portal>
      <div className="modal modal-bet">
        <div className="mask" />
        <div className="modal-content">
          <div className="modal-header">
            <h5>{i18next.t("confirm_order")}</h5>
            <span className="btn-close" onClick={onClose}>
              x
            </span>
          </div>
          <div className="modal-body">
            <div className="info-table mb-2">
              <div className="item">
                <span>{i18next.t("first_name")}</span>
                <span>
                  <b className="text-uppercase">
                    {data.game_id?.split("usdt")?.[0]}
                  </b>
                  /USDT
                </span>
              </div>
              <div className="item">
                <span>{i18next.t("direction")}</span>
                {data?.game_type === "sell" && (
                  <span className="text-red">{i18next.t("sell")} </span>
                )}
                {data?.game_type === "buy" && (
                  <span className="text-green">{i18next.t("buy")} </span>
                )}
              </div>
              <div className="item">
                <span>{i18next.t("type")}</span>
                <span>{Number(get(data, "type", 0)) / 60} Phút</span>
              </div>
              <div className="item">
                <span>{i18next.t("number_of_transactions")}</span>
                <span>{formatNumber(data?.amount, "0,0.[0000]")}USDT</span>
              </div>
              <div className="item">
                <span>{i18next.t("profit")}</span>
                <span>{formatNumber(profit, "0,0.[0000]")}USDT</span>
              </div>
            </div>
            <div className="item-row">
              <span className="text-black">
                {i18next.t("available")}:{" "}
                {formatNumber(usdtBalance?.amount, "0,0.[0000]")}USDT
              </span>
            </div>
            <button
              type="button"
              className="btn-confirm"
              onClick={() => handleSubmit()}
            >
              {i18next.t("order_comfirmation")}
            </button>
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default ModalBet;
