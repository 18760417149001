import React, { useEffect, useMemo, useState } from "react";
import { get } from "lodash";
import { observer } from "mobx-react-lite";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { GameApi } from "states/api/game";
import { NotificationManager } from "react-notifications";
import { useNotify } from "_common/component/NotifyProvider";
import i18next from "i18next";
import { Portal } from "react-portal";

import { PriceApi } from "states/api/price";
import HistoryData from "./HistoryData";
import MoneyInput from "./MoneyInput";
import MoneySelect from "./MoneySelect";

const gameTypeList = [
  {
    time: 60,
    profit: 10,
    text: "1Phút",
  },
  {
    time: 120,
    profit: 20,
    text: "2Phút",
  },
  {
    time: 180,
    profit: 30,
    text: "3Phút",
  },
  {
    time: 240,
    profit: 50,
    text: "4Phút",
  },
  {
    time: 300,
    profit: 80,
    text: "5Phút",
  },
  {
    time: 360,
    profit: 100,
    text: "6Phút",
  },
];

const ModalBet = observer(({ open, onClose, type, game_id }) => {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, dataGame, betGame } = useNotify();
  const {
    authStore: { user, loading, setLoading, general },
  } = useStores();

  const [game_type, setGameType] = useState(30);
  const [amount, setAmount] = useState(Number(get(general, "bet_default", 0)));

  const { mutate: onSubmit } = useMutation(
    (variables) =>
      GameApi.betGame({
        params: {
          game_id,
          ...variables,
        },
      }),
    {
      onSuccess: (res, variables) => {
        setAmount(Number(get(general, "bet_default", 0)));
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["game-player", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );

        betGame({
          user_id: user?.id,
          time: variables?.type,
          game_id: res?.data?.id,
          ...variables,
        });
        pushNotify({
          type: "betGame",
          status: "new_bet",
        });
        onClose();
      },
      onError: (error) => {
        setLoading(false);
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const handleSubmit = async () => {
    setLoading(true);
    if (loading) return null;
    if (!amount)
      return NotificationManager.error(i18next.t("enter_large_amount_msg"));
    if (!game_type)
      return NotificationManager.error(i18next.t("select_game_type_msg"));
    if (!game_id)
      return NotificationManager.error(i18next.t("select_game_type_msg"));
    const itemType = gameTypeList?.find((obj) => obj?.time === game_type);

    let profit = 0;
    if (itemType) {
      if (itemType?.time === 30 && amount < 20) {
        return NotificationManager.error(
          `${i18next.t("enter_large_amount_msg")} 20$`
        );
      }
      if (itemType?.time === 60 && amount < 10000) {
        return NotificationManager.error(
          `${i18next.t("enter_large_amount_msg")} 10.000$`
        );
      }
      if (itemType?.time === 120 && amount < 30000) {
        return NotificationManager.error(
          `${i18next.t("enter_large_amount_msg")} 30.000$`
        );
      }
      if (itemType?.time === 180 && amount < 100000) {
        return NotificationManager.error(
          `${i18next.t("enter_large_amount_msg")} 100.000$`
        );
      }
      const numRate = Number(itemType?.profit) / 100;
      profit = Math.round(amount * numRate);
    }

    if (profit <= 0) {
      return NotificationManager.error(i18next.t("enter_large_amount_msg"));
    }

    onSubmit({
      amount,
      profit,
      type: game_type,
      bet_game: type,
      price: dataGame?.lastPrice,
    });
    return null;
  };

  const usdtBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "usdt"),
    [user]
  );

  if (!open) return <div />;

  return (
    <Portal>
      <div className="modal modal-bet">
        <div className="mask" />
        <div className="modal-content !bg-[#2a405c] !border-0 !rounded-b-none !top-[unset] !bottom-[0] !translate-x-[-50%] !translate-y-[0]">
          <div className="modal-header !bg-[#2a405c] !border-b-0">
            <span className="btn-close" onClick={onClose}>
              x
            </span>
          </div>
          <div className="modal-body">
            <div className="info-table mb-2 !text-white !bg-[transparent] !border-[#d6d6d9] !border-b-2 !rounded-none">
              <span>
                <b className="text-uppercase">{game_id?.split("usdt")?.[0]}</b>
                /USDT
              </span>
            </div>
            <p className="dong_order_p">Chu kỳ lựa chọn</p>
            <div className="list-bet">
              {gameTypeList.map((item) => (
                <div
                  key={item.time}
                  className={classNames("item", {
                    active: item.time === game_type,
                  })}
                  onClick={() => setGameType(item.time)}
                >
                  <p>{item.text}</p>
                  <p>{formatNumber(item.profit, "0,0.0")}%</p>
                </div>
              ))}
            </div>
            <p className="dong_order_p">Số tiền tùy chọn</p>
            <div className="list-bet">
              {["100", "200", "500", "1000"].map((item) => (
                <div
                  key={item}
                  className={classNames("item", {
                    active: amount === item,
                  })}
                  onClick={() => setAmount(item)}
                >
                  <p>{item}</p>
                  <p>USDT</p>
                </div>
              ))}
            </div>
            <p className="dong_order_p">Số lượng tùy chỉnh</p>
            <div className="info-table">
              <input
                type="number"
                className="input-number"
                inputMode="decimal"
                placeholder={`${i18next.t("minimum")} 5 ${i18next.t(
                  "start_buying"
                )}`}
                value={amount}
                onChange={(e) => setAmount(e?.target?.value)}
              />
            </div>
            <p className="dong_order_p !mt-[20px]">
              Số dư tài khoản：{formatNumber(usdtBalance?.amount, "0,0.[0000]")}
              USDT
            </p>
            <div className="w-full h-[50px] !border-[#d6d6d9] !border-t-2 pt-[10px] mt-[10px] flex">
              <div className="w-[20%] h-[50px] text-[14px] text-[#768da9] flex flex-col items-center">
                <div>Giao dịch</div>
                <div className="text-uppercase">
                  {game_id?.split("usdt")?.[0]}/USDT
                </div>
              </div>
              <div className="w-[20%] h-[50px] text-[14px] text-[#768da9] flex flex-col items-center">
                <div>Hướng đi</div>
                <div
                  className={`text-uppercase ${
                    type === "buy" ? "text-[#0ecb81]" : "text-[#f5465c]"
                  }`}
                >
                  {type}
                </div>
              </div>
              <div className="w-[20%] h-[50px] text-[14px] text-[#768da9] flex flex-col items-center">
                <div>Giá hiện tại</div>
                <div className="text-uppercase">...</div>
              </div>
              <div className="w-[20%] h-[50px] text-[14px] text-[#768da9] flex flex-col items-center">
                <div>Số lượng</div>
                <div className="text-uppercase">{amount} USDT</div>
              </div>
              <div className="w-[20%] h-[50px] text-[14px] text-[#768da9] flex flex-col items-center">
                <div>Lợi nhuận</div>
                <div className="text-uppercase text-[#0ecb81]">
                  {game_id?.split("usdt")?.[0]}/USDT
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn-confirm"
              onClick={() => handleSubmit()}
            >
              Đặt lệnh mua
            </button>
          </div>
        </div>
      </div>
    </Portal>
  );
});

export default ModalBet;
