import React from "react";
import { useStores } from "_common/hooks";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { handleCopyLink } from "_common/utils/helper";
import QRCode from "react-qr-code";

import iconLogo from "assets/img/icon/644b9cc38fec7.png";

export default function ShareLink() {
  const {
    authStore: { user },
  } = useStores();

  return (
    <div className="container !w-[90%] !mt-[50px]">
      <HeaderTop title="Mời" />
      <div className="no_content w-[90%] mx-auto">
        <div className="no_inbox">
          <div className="imgbox">
            <img src={iconLogo} alt="" className="h-[80px]" />
          </div>
          <div className="logobox">
            <div className="w-full h-[30px] leading-[30px] text-center">
              <b className="fe6im text-[22px]">Remitano</b>
            </div>
          </div>
          <div className="txtbox">
            <div className="txtbox_tb">
              <span />
            </div>
          </div>
          <div className="logobox !h-[100px] mt-[30px] flex justify-between">
            <div className="logobox_left !w-[45%] flex justify-end">
              <div className="w-[80px] h-[80px] mt-[20px]">
                {user.link_ref && (
                  <QRCode
                    size={256}
                    viewBox="0 0 256 256"
                    className="w-[80px] h-[80px]"
                    value={user.link_ref}
                  />
                )}
              </div>
            </div>
            <div className="logobox_right !w-[52%]">
              <div className="w-full h-[20px] leading-[20px] mt-[60px]">
                <span className="fe6im text-[14px]">Mã lời mời của tôi</span>
              </div>
              <div className="w-full h-[20px] leading-[20px]">
                <span className="fe6im text-[14px]">{user.ref_no}</span>
              </div>
            </div>
          </div>
          <div
            onClick={() => handleCopyLink(user.link_ref)}
            className="w-[60%] h-[50px] leading-[50px] text-white text-center bg-[#00b897] m-[10px] mx-auto rounded-[15px]"
          >
            <span className="text-[14px] text-white">
              Sao chép liên kết lời mời
            </span>
          </div>
          <div className="fe6im w-full h-[300px]">
            <div className="msg-box  w-full h-[100px]">
              <b className="w-full h-[30px] leading-[30px] text-[16px]">
                Thống kê thành viên ba tập hợp
              </b>
              <div className="w-full h-[70px] flex">
                <div className="w-[50%] h-[70px]">
                  <div className="w-full h-[30px] leading-[30px] text-center">
                    đã xác minh
                  </div>
                  <div className="w-full h-[30px] leading-[30px] text-center">
                    0 Mọi người
                  </div>
                </div>
                <div className="w-[50%] h-[70px]">
                  <div className="w-full leading-[30px] text-center">
                    không công nhận, không chứng nhận
                  </div>
                  <div className="w-full h-[30px] leading-[30px] text-center">
                    0 Mọi người
                  </div>
                </div>
              </div>
            </div>
            <div className="msg-box w-full h-[100px]">
              <b className="msg-box w-full h-[30px] leading-[30px] text-[16px]">
                Thành viên một -Generation
              </b>
              <div className="w-full h-[70px] flex">
                <div className="w-[50%] h-[70px]">
                  <div className="w-full h-[30px] leading-[30px] text-center">
                    đã xác minh
                  </div>
                  <div className="w-full h-[30px] leading-[30px] text-center">
                    0 Mọi người
                  </div>
                </div>
                <div className="w-[50%] h-[70px]">
                  <div className="w-full leading-[30px] text-center">
                    không công nhận, không chứng nhận
                  </div>
                  <div className="w-full h-[30px] leading-[30px] text-center">
                    0 Mọi người
                  </div>
                </div>
              </div>
            </div>
            <div className="msg-box w-full h-[100px]">
              <b className="msg-box w-full h-[30px] leading-[30px] text-[16px]">
                Thành viên thứ hai
              </b>
              <div className="w-full h-[70px] flex">
                <div className="w-[50%] h-[70px]">
                  <div className="w-full h-[30px] leading-[30px] text-center">
                    đã xác minh
                  </div>
                  <div className="w-full h-[30px] leading-[30px] text-center">
                    0 Mọi người
                  </div>
                </div>
                <div className="w-[50%] h-[70px]">
                  <div className="w-full leading-[30px] text-center">
                    không công nhận, không chứng nhận
                  </div>
                  <div className="w-full h-[30px] leading-[30px] text-center">
                    0 Mọi người
                  </div>
                </div>
              </div>
            </div>
            <div className="msg-box w-full h-[100px]">
              <b className="msg-box w-full h-[30px] leading-[30px] text-[16px]">
                Thành viên ba tập hợp
              </b>
              <div className="w-full h-[70px] flex">
                <div className="w-[50%] h-[70px]">
                  <div className="w-full h-[30px] leading-[30px] text-center">
                    đã xác minh
                  </div>
                  <div className="w-full h-[30px] leading-[30px] text-center">
                    0 Mọi người
                  </div>
                </div>
                <div className="w-[50%] h-[70px]">
                  <div className="w-full leading-[30px] text-center">
                    không công nhận, không chứng nhận
                  </div>
                  <div className="w-full h-[30px] leading-[30px] text-center">
                    0 Mọi người
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
