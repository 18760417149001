import React from "react";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const NotiCenterPage = observer(() => (
  <main className="css-1wr4jig !bg-white">
    <div className="css-pnjgxq">
      <div className="css-163lzuo">Vui lòng nhập câu hỏi của bạn</div>
      <div className=" css-8pdsjp">
        <input
          placeholder="Tìm kiếm ở đây"
          maxLength="50"
          className="css-16fg16t"
        />
        <div className="bn-input-suffix css-vurnku">
          <FontAwesomeIcon icon={faSearch} className="css-1qppfsi" />
        </div>
      </div>
    </div>
    <div className="css-wp2li4">
      <div className="mirror-bread-crumb css-19zx9ks">
        <span className="css-o4bcl2">Trung tâm trợ giúp</span>
      </div>
      <div className="css-wl17qh">
        <div className="css-1s5qj1n">
          <h1 className="css-101kg5g">Trung tâm thông báo</h1>
          <div className="css-vurnku">
            <div className="css-wmvdm0" />
            <div className="css-6f91y1">
              <div className="css-ktxhrn">
                <Link to="detail/43" className="css-qinc3w">
                  Remitano Thay thế Cơ chế Clawback bằng ADL cho Hợp đồng Tương
                  lai Ký quỹ Coin
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
));

export default NotiCenterPage;
