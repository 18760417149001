import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { useNotify } from "_common/component/NotifyProvider";
import { formatNumber } from "_common/utils/formatValue";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import iconList from "assets/icons/icon_list.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import GameBoard from "./GameBoard";

import HistoryPrice from "./HistoryPrice";
import HistoryTrade from "./HistoryTrade";

const Transaction = observer(() => {
  const {
    authStore: { games, user },
  } = useStores();
  const navigate = useNavigate();

  const { updatePrice } = useNotify();

  const [params] = useSearchParams();
  const symbol = params?.get("symbol") || "btcusdt";

  const [game_id, setGameId] = useState(games[0]?.code);
  const { data: dataPrice, refetch: getPrice } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const priceBtc = useMemo(
    () => dataPrice?.find((obj) => obj?.symbol === game_id?.toUpperCase()),
    [dataPrice, game_id]
  );

  useEffect(() => {
    const interval = setInterval(() => {
      getPrice();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!priceBtc?.lastPrice) return;
    updatePrice({ user_id: user?.id, lastPrice: priceBtc?.lastPrice });
  }, [priceBtc?.lastPrice, user]);

  useEffect(() => {
    setGameId(symbol);
  }, [symbol]);

  return (
    <>
      <div className="content_title">
        <div className="content_title_l tleft">
          <img
            src={iconList}
            alt=""
            width="25"
            height="25"
            className="mt-[5px]"
          />
          <span>
            <b className="text-uppercase text-[18px] text-white">
              {game_id?.split("usdt")?.[0]}
            </b>
            <b className="text-uppercase text-[18px] text-white">/USDT</b>
          </span>
          <span className="text-[#f5465c] ml-[5px]">
            <span className="text-[18px] text-[500] text-[#0ecb81]">
              {formatNumber(priceBtc?.priceChangePercent, "0,0.[00]")}%
            </span>
          </span>
        </div>
        <Link to="/trade/trans.html?sytx=BTC/USDT">
          <div className="content_title_r tright">
            <svg
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
            >
              <path
                d="M225.28 303.476364 184.32 303.476364 184.32 613.282909 225.28 613.282909 225.28 720.523636 245.76 720.523636 245.76 613.282909 286.72 613.282909 286.72 303.476364 245.76 303.476364 245.76 184.32 225.28 184.32 225.28 303.476364 225.28 303.476364 225.28 303.476364 225.28 303.476364ZM409.6 506.042182 368.64 506.042182 368.64 753.887418 409.6 753.887418 409.6 839.68 430.08 839.68 430.08 753.887418 471.04 753.887418 471.04 506.042182 430.08 506.042182 430.08 410.717091 409.6 410.717091 409.6 506.042182 409.6 506.042182 409.6 506.042182ZM378.88 517.12 460.8 517.12 460.8 742.4 378.88 742.4 378.88 517.12 378.88 517.12 378.88 517.12ZM778.24 424.391111 737.28 424.391111 737.28 595.968 778.24 595.968 778.24 655.36 798.72 655.36 798.72 595.968 839.68 595.968 839.68 424.391111 798.72 424.391111 798.72 358.4 778.24 358.4 778.24 424.391111 778.24 424.391111 778.24 424.391111ZM747.52 432.06 829.44 432.06 829.44 588.015555 747.52 588.015555 747.52 432.06 747.52 432.06 747.52 432.06ZM593.92 266.405495 552.96 266.405495 552.96 479.827782 593.92 479.827782 593.92 553.704727 614.4 553.704727 614.4 479.827782 655.36 479.827782 655.36 266.405495 614.4 266.405495 614.4 184.32 593.92 184.32 593.92 266.405495 593.92 266.405495 593.92 266.405495Z"
                fill="#3db485"
              />
            </svg>
          </div>
        </Link>
      </div>
      <div className="feature-page spot-page">
        <div className="spot-wrapper">
          <div className="col1">
            <GameBoard game_id={game_id} data_price={priceBtc} />
          </div>
          <div className="col2">
            <HistoryPrice />
          </div>
        </div>
        <div className="feature-page !p-0 !bg-[#121420]">
          <h4 className="h-[40px] px-[25px]">
            <span>Lịch sử đặt lệnh</span>
            <FontAwesomeIcon
              icon={faList}
              onClick={() => navigate("/trade/history")}
            />
          </h4>
        </div>
        <HistoryTrade />
      </div>
    </>
  );
});

export default Transaction;
