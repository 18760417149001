import React from "react";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import KycForm from "./KycForm";

export default function KYC() {
  return (
    <div className="container !mt-[50px]">
      <HeaderTop title="Đã xác minh" />
      <KycForm />
    </div>
  );
}
