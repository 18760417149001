import React from "react";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

import iconMSB from "assets/img/644bd35520ef5.jpg";

export default function MSB() {
  return (
    <div className="container !mt-[50px]">
      <HeaderTop title="Chứng nhận MSB" />
      <div className="box_img text-center pt-[10px]">
        <img src={iconMSB} alt="" />
      </div>
    </div>
  );
}
