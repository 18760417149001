import React from "react";
import { observer } from "mobx-react-lite";
import General from "./General";

const WalletPage = observer(() => (
  <div className="mb-10">
    <General />
  </div>
));

export default WalletPage;
