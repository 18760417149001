import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCircleUser,
  faBell,
  faListCheck,
  faSliders,
  faHammer,
  faUser,
  faGear,
  faShield,
  faUserPlus,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";

import applicationIcon from "assets/icons/application.svg";
import chartIcon from "assets/icons/chart.svg";
import hotIcon from "assets/icons/hot-2.svg";
import btcIcon from "assets/icons/btcIcon.svg";
import downIcon from "assets/icons/downIcon.svg";
import icon5 from "assets/img/icon/icon-5.png";
import qrdownload from "assets/img/qrdownload.png";

const HeaderDesktop = observer(({ is_open, setMenuOpen }) => {
  const {
    authStore: { logo, general, authenticated, user },
  } = useStores();

  return (
    <header className="css-jmskxt">
      <Link to="/" className="css-1mvf8us">
        <img src={logo} className="css-1jgk2rg css-logo" alt="" />
      </Link>
      <div className="css-1tp5kus header-title">
        <div className="css-vurnku  f22 fw header-title">
          {general?.name_website}
        </div>
      </div>
      <div className="css-1tp5kus">
        <Link to="/" className="css-1smf7ma">
          <div className="css-vurnku">Trang chủ</div>
        </Link>
      </div>
      <div className="css-1tp5kus buy_coins">
        <ul
          className="buy-sub"
          style={{
            padding: 0,
            display: "none",
            zIndex: 999999,
          }}
        >
          <div className="order_navlist buy_navlist">
            <li className="li-sub">
              <Link to="https://banxa.com">
                <div className="optionli">
                  <img src={applicationIcon} alt="" />
                  <span className="f16"> Banxa</span>
                </div>
              </Link>
            </li>
            <li className="li-sub">
              <Link to="https://www.simplex.com/account/buy">
                <div className="optionli">
                  <img src={applicationIcon} alt="" />
                  <span className="f16"> Simplex</span>
                </div>
              </Link>
            </li>
            <li className="li-sub">
              <Link to="https://www.simplex.com/account/buy">
                <div className="optionli">
                  <img src={applicationIcon} alt="" />
                  <span className="f16"> Ramp</span>
                </div>
              </Link>
            </li>
          </div>
        </ul>
        <Link to="/" className="css-1smf7ma">
          <div className="css-vurnku">
            Đồng tiền <FontAwesomeIcon icon={faCaretDown} />
          </div>
        </Link>
      </div>
      <div className="css-1tp5kus trad_coins">
        <ul
          className="trad-sub"
          style={{
            padding: 0,
            display: "none",
            zIndex: 999999,
          }}
        >
          <div className="order_navlist trad_navlist">
            <li className="li-sub">
              <Link to="/trade">
                <div className="optionli">
                  <img src={chartIcon} alt="" />
                  <span className="f16">
                    Đặt lệnh
                    <img src={hotIcon} alt="" className="float-right mt-4" />
                  </span>
                </div>
              </Link>
            </li>
            <li className="li-sub">
              <Link to="/board/spot">
                <div className="optionli">
                  <img src={btcIcon} alt="" />
                  <span className="f16"> Giao dịch tiền tệ</span>
                </div>
              </Link>
            </li>
          </div>
        </ul>
        <Link to="/" className="css-1smf7ma">
          <div className="css-vurnku">
            Giao dịch <FontAwesomeIcon icon={faCaretDown} />
          </div>
        </Link>
      </div>
      <div className="css-1tp5kus">
        <Link to="/issue" className="css-1smf7ma">
          <div className="css-vurnku">
            <img src={hotIcon} alt="" className="hot-2" />
            IEO
          </div>
        </Link>
      </div>
      <div className="css-1tp5kus">
        <Link to="/orepool" className="css-1smf7ma">
          <div className="css-vurnku">
            <img src={hotIcon} alt="" className="hot-2" />
            DeFi
          </div>
        </Link>
      </div>
      <div className="css-1tp5kus">
        <Link to="/gglist" className="css-1smf7ma">
          <div className="css-vurnku">Trung tâm thông báo</div>
        </Link>
      </div>
      <div className="css-11y6cix" />
      <ul id="nav" className="nav !items-center">
        <div className="css-wu6zme !items-center">
          <div className="css-mu7imd">
            <Link to={general?.link_support}>
              <div className="css-1smf7ma">
                <div className="css-15owl46" style={{ padding: "0px 5px;" }}>
                  <img src={icon5} alt="" style={{ width: "35px" }} />
                </div>
              </div>
            </Link>
          </div>
          {authenticated ? (
            <>
              <div className="css-mu7imd">
                <Link to="/finance">
                  <div className="css-1smf7ma">
                    <div
                      className="css-15owl46"
                      style={{ padding: "0px 5px;" }}
                    >
                      <div className="css-vurnku">Ví tiền</div>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="css-mu7imd">
                <div className="css-1smf7ma hover-dropdown">
                  <div className="css-15owl46" style={{ padding: "0px 5px;" }}>
                    <div className="css-vurnku">
                      Danh mục <FontAwesomeIcon icon={faCaretDown} />
                    </div>
                  </div>
                  <ul className="sub dropdown">
                    <div className="order_navlist top-[35px] right-[180px]">
                      <li>
                        <Link to="/trade/order">
                          <div className="optionli">
                            <FontAwesomeIcon
                              icon={faListCheck}
                              className="!text-[18px] !text-[#00b897]"
                            />
                            <span className="text-[#EAECEF]">
                              Lệnh giao dịch tiền tệ
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/trade/contractjc">
                          <div className="optionli">
                            <FontAwesomeIcon
                              icon={faSliders}
                              className="!text-[18px] !text-[#00b897]"
                            />
                            <span className="text-[#EAECEF]">
                              Đơn đặt hàng giao dịch hợp đồng
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/issue/normalissue">
                          <div className="optionli">
                            <FontAwesomeIcon
                              icon={faListCheck}
                              className="!text-[18px] !text-[#00b897]"
                            />
                            <span className="text-[#EAECEF]">
                              Đơn đặt hàng đăng ký
                            </span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="/orepool/normalmin">
                          <div className="optionli">
                            <FontAwesomeIcon
                              icon={faHammer}
                              className="!text-[18px] !text-[#00b897]"
                            />
                            <span className="text-[#EAECEF]">
                              Thứ tự máy khai thác
                            </span>
                          </div>
                        </Link>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
              <div className="css-mu7imd">
                <div className="css-1smf7ma hover-dropdown">
                  <Link to="/user">
                    <div
                      className="css-15owl46"
                      style={{ padding: "0px 5px;" }}
                    >
                      <div className="css-vurnku">
                        <FontAwesomeIcon
                          icon={faCircleUser}
                          className="w-[20px] h-[20px]"
                        />
                      </div>
                    </div>
                  </Link>
                  <ul className="sub dropdown">
                    <div className="order_navlist !min-w-[220px] top-[35px] right-[60px]">
                      <li>
                        <div className="w-full h-[50px] leading-[50px] text-left py-[0] px-[15px]">
                          <span className="text-[16px] text-[#EAECEF] font-[900]">
                            than***@gmail.com
                          </span>
                          <span className="f12 fgreen">đã xác minh</span>
                        </div>
                      </li>
                      <li>
                        <a href="/user">
                          <div className="optionli">
                            <FontAwesomeIcon
                              icon={faUser}
                              className="!text-[18px] !text-[#00b897]"
                            />
                            <span className="text-[#EAECEF]">
                              Tổng tài khoản
                            </span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/user/respwd">
                          <div className="optionli">
                            <FontAwesomeIcon
                              icon={faGear}
                              className="!text-[18px] !text-[#00b897]"
                            />
                            <span className="text-[#EAECEF]">
                              Cài đặt hệ thống bảo vệ
                            </span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/user/authrz">
                          <div className="optionli">
                            <FontAwesomeIcon
                              icon={faShield}
                              className="!text-[18px] !text-[#00b897]"
                            />
                            <span className="text-[#EAECEF]">Xác thực</span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="/user/tgcode">
                          <div className="optionli">
                            <FontAwesomeIcon
                              icon={faUserPlus}
                              className="!text-[18px] !text-[#00b897]"
                            />
                            <span className="text-[#EAECEF]">
                              Đề xuất giảm giá
                            </span>
                          </div>
                        </a>
                      </li>
                      <li>
                        <div className="optionli border-t-[1px] border-[#f5f5f5]">
                          <FontAwesomeIcon
                            icon={faRightFromBracket}
                            className="!text-[18px] !text-[#00b897]"
                          />
                          <span className="text-[#EAECEF]">Đăng xuất</span>
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
              <div className="css-mu7imd">
                <Link to="/user/notice">
                  <div className="css-1smf7ma">
                    <div
                      className="css-15owl46"
                      style={{ padding: "0px 5px;" }}
                    >
                      <div className="css-vurnku">
                        <FontAwesomeIcon
                          icon={faBell}
                          className="w-[20px] h-[20px]"
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </>
          ) : (
            <>
              <div className="css-mu7imd">
                <Link to="/auth/login">
                  <div className="css-1smf7ma">
                    <div
                      className="css-15owl46"
                      style={{ padding: "0px 5px;" }}
                    >
                      <div className="css-vurnku">Đăng nhập</div>
                    </div>
                  </div>
                </Link>
              </div>
              <div
                className="css-mu7imd"
                style={{ border: "2px solid #00b897", borderRadius: "5px" }}
              >
                <Link to="/auth/register">
                  <div className="css-1smf7ma">
                    <div
                      className="css-15owl46"
                      style={{ padding: "0px 5px;" }}
                    >
                      <div className="css-vurnku" style={{ color: "#00b897" }}>
                        Đăng ký
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </>
          )}
        </div>
        <div className="css-1smf7ma">
          <span>Việt Nam</span>
        </div>
      </ul>
    </header>
  );
});

export default HeaderDesktop;
