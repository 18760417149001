import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import StackingDesktop from "./StackingDesktop";
import StackingMobile from "./StackingMobile";

const StakingPage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <StackingMobile />;
  return <StackingDesktop />;
});

export default StakingPage;
