import React from "react";
import { useStores } from "_common/hooks";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import HeaderTop from "_common/component/Layout/Header/HeaderTop";

export default function Introduction() {
  const navigate = useNavigate();
  const {
    authStore: { questions },
  } = useStores();

  return (
    <div className="container introduce-page !mt-[50px]">
      <HeaderTop title={i18next.t("support_center")} />
      <div className="list-questions">
        {questions?.map((item, index) => (
          <div
            className="item"
            key={`item-${index.toString()}`}
            onClick={() => navigate(`/member/introduction/detail/${item?.id}`)}
          >
            <FontAwesomeIcon icon={faEnvelope} />
            <span className="!text-[#707A8A]">{i18next.t(item?.title)}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
