/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import { useStores } from "_common/hooks";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import i18next from "i18next";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Loading from "_common/component/Loading";
import { observer } from "mobx-react-lite";
import HeaderDesktop from "_common/component/Layout/Header/HeaderDesktop";
import InputPassword from "_common/component/InputPassword";

const schema = yup
  .object({
    username: yup.string().required(i18next.t("verify_require")),
    password: yup.string().required(i18next.t("verify_require")),
  })
  .required();

const LoginDesktop = observer(() => {
  const navigate = useNavigate();
  const { authStore } = useStores();
  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.login(variables),
    {
      onSuccess: (res) => {
        authStore.updateUser(res?.data);
        setTimeout(() => {
          navigate("/");
        }, 200);
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  return (
    <>
      <HeaderDesktop />
      <div className="starfield">
        <div className="static" />
        <div className="moving-1" />
        <div className="moving-2" />
        <div className="moving-3" />
      </div>
      <div className="container">
        <div className="card">
          <form className="card-form" onSubmit={handleSubmit(handleLogin)}>
            <div className="margin-topbox-px-10">
              <div data-bn-type="text" className="css-1g5tc38  tcc fch f36 fw">
                Chào mừng bạn đến
                <span className="floginbr">
                  {" "}
                  {authStore?.general?.name_website}
                </span>
              </div>
            </div>
            <div className="input">
              <input
                type="text"
                className="input-field"
                name="username"
                {...register("username")}
              />
              <label className="input-label">Tên tài khoản</label>
            </div>
            <div className="input">
              <InputPassword
                type="password"
                className="input-field"
                {...register("password")}
              />
              <label className="input-label">Mật khẩu</label>
            </div>
            <div className="input">
              <input
                type="text"
                className="input-field"
                name="vcode"
                id="vcode"
                style={{ width: "60%" }}
              />
              <label className="input-label">Mã xác nhận</label>
              <div className="action-button login-send-button">
                <img
                  style={{ width: "100%", height: "50px", borderRadius: "5px" }}
                  src="https://remitano-vn.com/Verify/code"
                  alt=""
                  title="Biến đổi"
                />
              </div>
            </div>
            <div className="action">
              <button type="submit" className="action-button">
                Đăng nhập
              </button>
            </div>
          </form>
          <div className="login-reg tcr">
            <Link
              to="/auth/register"
              className="css-utqtyo fbaseblue"
              style={{ fontSize: 18 }}
            >
              Đăng ký ngay
            </Link>
          </div>
        </div>
      </div>
    </>
  );
});

export default LoginDesktop;
