import React, { useEffect, useMemo, useState } from "react";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { PaymentApi } from "states/api/payment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import { handleCopyLink } from "_common/utils/helper";
import UploadFile from "_common/component/UploadFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const schema = yup
  .object({
    amount: yup.number().required(i18next.t("verify_require")),
    otp: yup.string(),
  })
  .required();

export default function Deposit() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const symbol = params.get("symbol");
  const network = params.get("network");

  const [method, setMethod] = useState("vnd");
  const [address, setAddress] = useState();
  const [back_images, setBackImage] = useState();

  const {
    authStore: { user, general, banks, game_wallet, coins },
  } = useStores();

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const mainCoin = useMemo(
    () => coins?.find((obj) => obj?.symbol === symbol),
    [coins, symbol]
  );

  useEffect(() => {
    if (!mainCoin) return;
    if (symbol === "usdt") {
      if (network === "trc20") {
        setAddress(mainCoin?.trc20_address);
      } else if (network === "erc20") {
        setAddress(mainCoin?.erc20_address);
      } else {
        setAddress(mainCoin?.primary_address);
      }
    } else {
      setAddress(mainCoin?.primary_address);
    }
  }, [mainCoin, symbol, network]);

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) =>
      PaymentApi.deposit({
        params: {
          ...variables,
          symbol,
          receive_address: address,
          network,
          payment_image: back_images,
          type: "deposit",
        },
      }),
    {
      onSuccess: (res) => {
        reset();
        setBackImage();
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["payment", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("withdrawal_successful")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    const amount = values?.amount;
    if (amount < Number(general?.min_deposit || 0))
      return NotificationManager.error(
        `${i18next.t("enter_large_amount_msg")} ${formatNumber(
          general?.min_deposit
        )}`
      );
    if (amount > Number(general?.max_deposit || 0))
      return NotificationManager.error(
        `${i18next.t("enter_smaller_amount_msg")} ${formatNumber(
          general?.max_deposit
        )}`
      );
    onSubmit({ ...values });
    return null;
  };

  const onReceiveBackImages = (images) => {
    setBackImage(images[0]?.url);
  };

  return (
    <div className="container">
      <div className="topbox flex justify-between items-center">
        <span className="btn_back" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faLongArrowAltLeft} color="#707A8A" />
        </span>
        <div className="fr allhg txtr w-[10%] leading-[40px]">
          <svg
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            className="inline-block"
          >
            <path
              d="M914.9 158.4H183.5c-15.2 0-27.6-12.4-27.6-27.6v-4.1c0-15.2 12.4-27.6 27.6-27.6h731.4c15.2 0 27.6 12.4 27.6 27.6v4.1c0.1 15.2-12.3 27.6-27.6 27.6zM914.9 819.9H183.5c-15.2 0-27.6-12.4-27.6-27.6v-4.1c0-15.2 12.4-27.6 27.6-27.6h731.4c15.2 0 27.6 12.4 27.6 27.6v4.1c0.1 15.2-12.3 27.6-27.6 27.6zM574.7 489.2H176.6c-11.4 0-20.7-9.3-20.7-20.7v-18.1c0-11.4 9.3-20.7 20.7-20.7h398.1c11.4 0 20.7 9.3 20.7 20.7v18.1c0 11.4-9.3 20.7-20.7 20.7z"
              fill="#00b897"
            />
          </svg>
        </div>
      </div>
      <div id="withdraw" className="pt-[50px] px-[15px]">
        <div className="contentbox_top">
          <span className="fe6im text-[18px] text-[500]">Nạp tiền</span>
        </div>
        <div className="contentbox_line">
          <div className="line_1">
            <span className="fzmmm fe6im">Cặp tiền</span>
          </div>
          <div className="line_2" onClick={() => navigate(-1)}>
            <b className="text-[18px] text-[#00b897] text-uppercase">
              {symbol}
            </b>
            <span className="fzmmm fe6im mr-[5px]">
              &nbsp;&nbsp;{mainCoin?.name}
            </span>
            <FontAwesomeIcon icon={faChevronRight} color="#707A8A" />
          </div>
        </div>
        <div className="imgbox">
          <div className="imgbox_1">
            <span className="fzmmm fe6im">Mạng tiền xu</span>
          </div>
          <div className="imgbox_2">
            <div className="imgbox_3 fl showQr czline">
              <span className="fzmm">TRC20</span>
            </div>
          </div>
          <div className="imgbox_4 address">
            <div className="imgbox_5">
              <span className="fzmmm fe6im">Địa chỉ tiền xu</span>
            </div>
            <div className="imgbox_7">
              <span className="fzmm fe6im">{address}</span>
              <div className="lmcoin">
                <span
                  className="fzmm fe6im"
                  onClick={() => handleCopyLink(address)}
                >
                  sao chép
                </span>
              </div>
            </div>
            <Link to="/cskh" className="imgbox_8">
              <div className="imgbox_9 !my-[15px]">
                <span className="fzmm  fe6im">
                  Liên hệ bộ phận chăm sóc khách hàng để lấy địa chỉ
                </span>
              </div>
            </Link>
          </div>
        </div>
        <form className="imgbox" onSubmit={handleSubmit(onSave)}>
          <div className="imgbox_4 h-[350px] !border-[0] !mt-[0]">
            <div className="imgbox_5">
              <span className="fzmmm fe6im">Số tiền chuyển khoản</span>
            </div>
            <div className="zz_inputbox">
              <input
                type="number"
                inputMode="decimal"
                min={0}
                max={1000000000000}
                step={0.000001}
                className="zz_input"
                placeholder="Vui lòng nhập số tiền chuyển nhượng"
                {...register("amount")}
              />
            </div>
            <div className="imgbox_5">
              <span className="fzmmm fe6im">Tải chứng từ chuyển khoản lên</span>
            </div>
            <div className="imgbox_6">
              <UploadFile
                onReceiveImages={onReceiveBackImages}
                image={back_images}
                folder="payment"
              />
            </div>
            <div className="imgbox_8 mt-[20px]">
              <div className="imgbox_10" id="sumbtn">
                <button
                  type="submit"
                  className="fzmm fe6im"
                  disabled={!isValid}
                >
                  Gửi đi
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
