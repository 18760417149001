import React, { useState } from "react";
import { useStores } from "_common/hooks";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faChevronRight } from "@fortawesome/free-solid-svg-icons";

export default function Recharge() {
  const navigate = useNavigate();
  const {
    authStore: { partners, banks, setBankDeposit },
  } = useStores();

  const [rechargeType, setRechargeType] = useState(1);

  return (
    <div className="container page-deposit">
      <div className="topbox">
        <div className="topbox_1">
          <div className="topbox_1_b">
            <div className="topbox_1_c">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <div className="topbox_1_d">
              <input
                type="text"
                id="symbol"
                className="sybolc"
                placeholder="Tìm kiếm loại tiền bạn cần"
              />
            </div>
          </div>
        </div>
        <div className="topbox_2">
          <span className="fzmmm fe6im" onClick={() => navigate(-1)}>
            Hủy bỏ
          </span>
        </div>
      </div>
      <div id="deposit" className="pt-[50px] p-[10px]">
        <div className="w-full h-[30px] leading-[30px]">
          <span
            className={`fzmmm fe6im p-[5px] ${
              rechargeType === 1 && "border-b-[3px] border-[#00b897]"
            }`}
            onClick={() => setRechargeType(1)}
          >
            Nạp điện
          </span>
          <span
            className={`fzmmm fe6im p-[5px] ${
              rechargeType === 2 && "border-b-[3px] border-[#00b897]"
            }`}
            onClick={() => setRechargeType(2)}
          >
            Thẻ Ngân Hàng
          </span>
        </div>
        <div className="w-full h-[120px] pt-[20px] pr-[20px] pb-[0] pl-[20px]">
          {rechargeType === 1 ? (
            <>
              <Link to="/member/deposit?symbol=usdt&network=erc20">
                <div className="lmcoin">
                  <span className="fzmm fe6im">USDT</span>
                </div>
              </Link>
              <Link to="/member/deposit?symbol=btc">
                <div className="lmcoin">
                  <span className="fzmm fe6im">BTC</span>
                </div>
              </Link>
              <Link to="/member/deposit?symbol=eth">
                <div className="lmcoin">
                  <span className="fzmm fe6im">ETH</span>
                </div>
              </Link>
              <Link to="/member/deposit?symbol=trx">
                <div className="lmcoin">
                  <span className="fzmm fe6im">TRX</span>
                </div>
              </Link>
              <Link to="/member/deposit?symbol=ltc">
                <div className="lmcoin">
                  <span className="fzmm fe6im">LTC</span>
                </div>
              </Link>
              <Link to="/member/deposit?symbol=fil">
                <div className="lmcoin">
                  <span className="fzmm fe6im">FIL</span>
                </div>
              </Link>
            </>
          ) : (
            banks?.map((item) => (
              <div
                onClick={() => {
                  setBankDeposit(item);
                  navigate("/member/deposit-bank");
                }}
                key={item?.id}
              >
                <div className="lmcoin">
                  <span className="fzmm fe6im">{item?.bank_code}</span>
                </div>
              </div>
            ))
          )}
        </div>
        <div className="coinlistbox">
          <div className="coinlistbox_top">
            <span className="fzmmm fe6im p-[5px] border-b-[3px] border-[#00b897]">
              Mua tiền điện tử
            </span>
          </div>
          <div className="list-partner">
            {partners?.map((item) => (
              <a href={item?.link} key={item.id} className="item">
                <div className="coinlistbox_info_list">
                  <div className="coinlistbox_info_list_l">
                    <img src={item?.image_url} alt="" />
                  </div>
                  <div className="coinlistbox_info_list_c">
                    <span className="fzmm fcc">{item.name}</span>
                  </div>
                  <div className="coinlistbox_info_list_r">
                    <span className="fzm fcc">Buy</span>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      color="#707A8A"
                      className="ml-[5px]"
                    />
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
