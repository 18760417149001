import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

const Notice = observer(() => {
  const navigate = useNavigate();

  const {
    authStore: { banners, top_coin, games, authenticated },
  } = useStores();

  return (
    <div className="top">
      <div className="item-top d-inline-flex align-items-start">
        <a
          href="le-ho-leveraged-token"
          title="Lễ hội Leveraged Tokens: Giao dịch để Chia sẻ Nhóm Giải thưởng $ 40.000 và Thưởng thức Airdrop may mắn lên đến $200!"
          className="d-inline-block name mr-2 text-left"
        >
          Lễ hội Leveraged Tokens: Giao dịch để Chia sẻ Nhóm Giải thưởng $
          40.000 và Thưởng thức Airdrop may mắn lên đến $200!
        </a>
        <span>06/09</span>
      </div>
      <div className="item-top d-inline-flex align-items-start">
        <a
          href="le-ho-leveraged-token"
          title="OpenLeverage (OLE) Được Niêm yết trên AutoHold"
          className="d-inline-block name mr-2 text-left"
        >
          OpenLeverage (OLE) Được Niêm yết trên AutoHold
        </a>
        <span>06/09</span>
      </div>
      <div className="item-top d-inline-flex align-items-start">
        <a
          href="le-ho-leveraged-token"
          title="Các chiến dịch Spot đang diễn ra"
          className="d-inline-block name mr-2 text-left"
        >
          Các chiến dịch Spot đang diễn ra
        </a>
        <span>06/09</span>
      </div>
      <div className="item-top d-inline-flex align-items-start">
        <a
          href="le-ho-leveraged-token"
          title="Giao dịch RSR, Hợp đồng vĩnh viễn JST, Giành phần thưởng 20.000 USDT!"
          className="d-inline-block name mr-2 text-left"
        >
          Giao dịch RSR, Hợp đồng vĩnh viễn JST, Giành phần thưởng 20.000 USDT!
        </a>
        <span>06/09</span>
      </div>
    </div>
  );
});

export default Notice;
