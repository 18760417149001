import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

const Banner = observer(() => {
  const navigate = useNavigate();

  const {
    authStore: { banners, top_coin, games, authenticated },
  } = useStores();

  return (
    <div className="css-1pysja1">
      <div className="bg-box">
        <div
          className="css-1t9l9dt"
          style={{ width: "100%", paddingTop: "74px" }}
        >
          <div className="css-8hstpq-bg css-8hstpq">
            <div className="css-b22026 reg-input-box">
              <div className="css-1xamyaw  css-1xamyaw-l">
                <div className="css-1xamyaw-content">
                  <p
                    className="fw"
                    style={{
                      fontSize: "48px",
                      paddingBottom: "40px",
                      textAlign: "initial",
                      color: "#151617",
                    }}
                  >
                    Find Your Next Moonshot
                  </p>
                  <p
                    style={{
                      fontSize: "26px",
                      color: "#444545",
                      width: "500px",
                    }}
                  >
                    {" "}
                    Khối lượng giao dịch 24 -giờ{" "}
                    <span id="amount">$292891373.011</span>
                  </p>
                  <p
                    style={{
                      fontSize: "18px",
                      color: "#444545",
                      width: "500px",
                      height: "39px",
                    }}
                  />
                </div>
                {!authenticated && (
                  <form className="form-inline">
                    <input
                      type="email"
                      className="form-control mb-2 mr-sm-2 reginput"
                      placeholder="Vui lòng nhập hộp thư hoặc điện thoại di động"
                      id="email"
                    />
                    <button
                      type="submit"
                      className=" mb-2 ml-2 regbtn"
                      onClick={() => navigate("/auth/login")}
                    >
                      Đăng ký
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default Banner;
