import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import { useStores } from "_common/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNotify } from "_common/component/NotifyProvider";
import { formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import { gameTypeList } from "_common/constants/statusType";
import ModalBet from "./ModalBet";

const buttons = [20, 50, 75, 100];
const levers = [5, 10, 15, 20, 100];

const typeBet = [
  {
    type: "price",
    text: i18next.t("market_price"),
  },
  {
    type: "price_pending",
    text: i18next.t("waiting_list"),
  },
];

export default function GameBoard({ game_id, data_price }) {
  const queryClient = useQueryClient();
  const { isMobile } = useMediaQuery();
  const { pushNotify, dataGame } = useNotify();
  const {
    authStore: { user, display_balance, loading, setLoading, general },
  } = useStores();

  const [game_type, setGameType] = useState("buy");
  const [bet_type, setBetType] = useState(typeBet[0].type);
  const [amount, setAmount] = useState(0);
  const [last_price, setLastPrice] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [amount_type, setAmountType] = useState();
  const [type, setType] = useState(gameTypeList[0].time);

  const handleChangeInput = (event) => {
    setAmount(parseInt(event?.target?.value, 10));
  };

  const usdtBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "usdt"),
    [user]
  );

  useEffect(() => {
    setLastPrice(data_price !== undefined && Number(data_price?.lastPrice));
  }, [bet_type]);

  return (
    <div className="feature-board">
      <div className="list-bet">
        {gameTypeList.map((item) => (
          <div
            key={item.time}
            className={classNames("item", {
              active: item.time === type,
            })}
            onClick={() => setType(item.time)}
          >
            <p>Lợi nhuận</p>
            <h4>{formatNumber(item.profit, "0,0.0")}%</h4>
            <h5>{item.text}</h5>
          </div>
        ))}
      </div>

      <div className="quantity-type">
        {buttons.map((item) => (
          <button
            key={`num-${item.toString()}`}
            type="button"
            className={classNames("btn", { active: amount_type >= item })}
            onClick={() => {
              const balance = usdtBalance?.amount;

              setAmountType(item);
              setAmount((balance * item) / 100);
            }}
          >
            <div className="blbox_3" />
            {item}%
          </button>
        ))}
      </div>
      <div className="info mt-[20px]">
        <span className="text-[#707A8A]">{i18next.t("balance")}</span>
        <span className="text-[#707A8A]">
          {formatNumber(usdtBalance?.amount, "0,0.[00000]")}&nbsp;USDT
        </span>
      </div>
      <div className="qty-input !w-full mt-[10px]">
        <input
          placeholder={i18next.t("transaction_amount")}
          type="number"
          inputMode="decimal"
          className="sbui-input sbui-input--large"
          min={0}
          step="1"
          value={amount}
          onChange={handleChangeInput}
        />
        <button
          type="button"
          className="btn btn-add"
          style={{
            width: 64,
          }}
        >
          USDT
        </button>
      </div>
      <div className="button-footer mt-[20px]">
        <button
          type="button"
          className="btn btn-buy"
          onClick={() => {
            setGameType("buy");
            setOpenModal(true);
          }}
        >
          {i18next.t("buy")}
        </button>
        <button
          type="button"
          className="btn btn-sell"
          onClick={() => {
            setGameType("sell");
            setOpenModal(true);
          }}
        >
          {i18next.t("sell")}
        </button>
      </div>
      {openModal && (
        <ModalBet
          open={openModal}
          onClose={() => setOpenModal(false)}
          data={{
            game_id,
            game_type,
            type,
            amount,
          }}
        />
      )}
    </div>
  );
}
