import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import TradeMobile from "./Mobile";
import TradeDesktop from "./Desktop";

const TradePage = observer(() => {
  const { isMobile } = useMediaQuery();

  if (isMobile) return <TradeMobile />;
  return <TradeMobile />;
});

export default TradePage;
