import React from "react";
import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";
import { useMediaQuery } from "_common/component/MediaQueryProvider";

const UnAuthLayout = observer(() => {
  const { isMobile } = useMediaQuery();
  if (isMobile)
    return (
      <div className="mobile-layout">
        <Outlet />
      </div>
    );

  return (
    <div className="desktop-layout">
      <Outlet />
    </div>
  );
});

export default UnAuthLayout;
