import classNames from "classnames";
import React, { useEffect, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import { useStores } from "_common/hooks";
import icon1 from "assets/img/icon/index1.png";
import icon2 from "assets/img/icon/index2.png";
import icon3 from "assets/img/icon/index3.png";
import icon4 from "assets/img/icon/index4.png";
import icon5 from "assets/img/icon/index5.png";
import i18next from "i18next";

export default function DesktopFooter() {
  const location = useLocation();
  const pathName = location?.pathname;
  const {
    authStore: { general },
  } = useStores();

  return (
    <footer className="css-4qtnb6 pb-[5rem]">
      <div className="wrapper">
        <div className="col1">
          <div className="logo">
            <span>Remitano</span>
          </div>
          <div className="menu-item">
            <a href="/" className="footer-box-span">
              Tuyên bố chính sách quyền riêng tư
            </a>
          </div>
          <div className="menu-item">
            <a href="/" className="footer-box-span">
              Điều khoản dịch vụ
            </a>
          </div>
          <div className="menu-item">
            <a href="/" className="footer-box-span">
              Giấy chứng nhận MSB
            </a>
          </div>
          <div className="menu-item">
            <a href="/" className="footer-box-span">
              về chúng tôi
            </a>
          </div>
        </div>
        <div className="copying">
          <span>CopyRight © 2017 - 2022 Remitano. All Rights Reserved.</span>
        </div>
      </div>
    </footer>
  );
}
