import React from "react";
import { observer } from "mobx-react-lite";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faGear,
  faJournalWhills,
  faRectangleList,
  faShield,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

const LeftNav = observer(() => {
  const location = useLocation();
  const pathName = location?.pathname;

  return (
    <div className="css-foka8b">
      <Link
        to="/user"
        className={pathName === "/user" ? "css-z87e9z" : "css-6ijtmk"}
      >
        <div className="css-10j588g">
          <FontAwesomeIcon
            icon={faUser}
            className={pathName === "/user" ? "css-14thuu2" : "css-hd27fe"}
          />
          <div className={pathName === "/user" ? "css-iizq59" : "css-1n0484q"}>
            Nhìn tổng thể
          </div>
        </div>
      </Link>
      <Link
        to="/user/addresslist"
        className={
          pathName === "/user/addresslist" ? "css-z87e9z" : "css-6ijtmk"
        }
      >
        <div className="css-10j588g">
          <FontAwesomeIcon
            icon={faJournalWhills}
            className={
              pathName === "/user/addresslist" ? "css-14thuu2" : "css-hd27fe"
            }
          />
          <div
            className={
              pathName === "/user/addresslist" ? "css-iizq59" : "css-1n0484q"
            }
          >
            Quản lý địa chỉ
          </div>
        </div>
      </Link>
      <Link
        to="/user/authrz"
        className={pathName === "/user/authrz" ? "css-z87e9z" : "css-6ijtmk"}
      >
        <div className="css-10j588g">
          <FontAwesomeIcon
            icon={faShield}
            className={
              pathName === "/user/authrz" ? "css-14thuu2" : "css-hd27fe"
            }
          />
          <div
            className={
              pathName === "/user/authrz" ? "css-iizq59" : "css-1n0484q"
            }
          >
            Đã xác minh
          </div>
        </div>
      </Link>
      <Link
        to="/user/respwd"
        className={pathName === "/user/respwd" ? "css-z87e9z" : "css-6ijtmk"}
      >
        <div className="css-10j588g">
          <FontAwesomeIcon
            icon={faGear}
            className={
              pathName === "/user/respwd" ? "css-14thuu2" : "css-hd27fe"
            }
          />
          <div
            className={
              pathName === "/user/respwd" ? "css-iizq59" : "css-1n0484q"
            }
          >
            Đổi mật khẩu
          </div>
        </div>
      </Link>
      <Link
        to="/user/tgcode"
        className={pathName === "/user/tgcode" ? "css-z87e9z" : "css-6ijtmk"}
      >
        <div className="css-10j588g">
          <FontAwesomeIcon
            icon={faUserPlus}
            className={
              pathName === "/user/tgcode" ? "css-14thuu2" : "css-hd27fe"
            }
          />
          <div
            className={
              pathName === "/user/tgcode" ? "css-iizq59" : "css-1n0484q"
            }
          >
            Đề xuất giảm giá
          </div>
        </div>
      </Link>
      <Link
        to="/user/notice"
        className={pathName === "/user/notice" ? "css-z87e9z" : "css-6ijtmk"}
      >
        <div className="css-10j588g">
          <FontAwesomeIcon
            icon={faBell}
            className={
              pathName === "/user/notice" ? "css-14thuu2" : "css-hd27fe"
            }
          />
          <div
            className={
              pathName === "/user/notice" ? "css-iizq59" : "css-1n0484q"
            }
          >
            Thông báo của tôi
          </div>
        </div>
      </Link>
      <Link
        to="/user/mybill"
        className={pathName === "/user/mybill" ? "css-z87e9z" : "css-6ijtmk"}
      >
        <div className="css-10j588g">
          <FontAwesomeIcon
            icon={faRectangleList}
            className={
              pathName === "/user/mybill" ? "css-14thuu2" : "css-hd27fe"
            }
          />
          <div
            className={
              pathName === "/user/mybill" ? "css-iizq59" : "css-1n0484q"
            }
          >
            Hóa đơn của tôi
          </div>
        </div>
      </Link>
    </div>
  );
});

export default LeftNav;
