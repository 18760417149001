import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import icon_margin from "assets/icons/icon_margin.svg";
import icon_contract from "assets/icons/icon_contract.svg";
import icon_etf from "assets/icons/icon_etf.svg";

const Feature1 = observer(() => {
  const navigate = useNavigate();

  const {
    authStore: { banners, top_coin, games, authenticated },
  } = useStores();

  return (
    <>
      <div className="css-1hc8c4h index-box-2">
        <div className="css-dlistbox" style={{ minHeight: 800 }}>
          <div className="css-dlistbox-top css-dlistbox-sub">
            <div className="ss-dlistbox-top-text">
              <p className="ss-dlistbox-top-p1">
                Bắt đầu hành trình tiền điện tử của bạn ngay bây giờ
              </p>
              <p className="ss-dlistbox-top-p2">
                RemitanoCông ty toàn cầu có nhiều tính năng khiến nó trở thành
                một nơi lý tưởng để mua và bán tài sản kỹ thuật số
              </p>
            </div>
          </div>
          <div className="css-dlistbox-l css-dlistbox-sub">
            <div className="css-dlistbox-l-item1">
              <div className="css-dlistbox-l-content">
                <div className="icon1-73beb614 icon-73beb614" />
                <p className="data-p-title">Quản lý tài sản của bạn</p>
                <p>
                  Thực hiện các giao dịch tại chỗ với một đòn bẩy cao tới 5 lần
                </p>
              </div>
              <div className="css-dlistbox-l-content">
                <div className="icon2-73beb614 icon-73beb614" />
                <p className="data-p-title">Thanh toán thẻ tín dụng</p>
                <p>
                  Mua tiền điện tử với thẻ tín dụng của bạn thông qua đối tác
                  của chúng tôi
                </p>
              </div>
              <div className="css-dlistbox-l-content css-dlistbox-l-content-4">
                <div className="icon3-73beb614 icon-73beb614" />
                <p className="data-p-title">Lưu trữ an toàn</p>
                <p className="data-p-content">
                  Quỹ của khách hàng được lưu trữ trên một đa số chuyên dụng
                </p>
                <p className="data-p-content">
                  Trong ví lạnh. 24/7 TẤT CẢ ĐƯỢC AN TOÀN AN TOÀN
                </p>
                <p className="data-p-content">
                  Dự trữ bảo mật đặc biệt 20.000 BTC
                </p>
              </div>
              <div className="css-dlistbox-l-content">
                <div className="icon-73beb614" />
                <p className="data-p-title">Ghé thăm khắp nơi</p>
                <p className="data-p-content">
                  Đối với các ứng dụng di động mà chúng tôi sử dụng cho Android
                  và iOS, 24/7
                </p>
                <p className="data-p-content">
                  Tiền gửi thời gian, rút &ZeroWidthSpace;&ZeroWidthSpace;tiền
                  và giao dịch
                </p>
              </div>

              <div className="css-dlistbox-l-download css-dlistbox-l-content">
                <div className="icon-73beb614" />
                <div className="ios-down-73beb614" />
                <div className="android-down-73beb614" />
              </div>
            </div>
          </div>
          <div className="css-dlistbox-r css-dlistbox-sub">
            <div className="css-dlistbox-bg" />
            <div className="css-dlistbox-phone" />
            <div className="img1" />
            <div className="img2" />
          </div>
        </div>
      </div>
      <div className="css-1hc8c4h index-box-3">
        <div className="css-dlistbox" style={{ minHeight: 300 }}>
          <div className="css-dlistbox-top-desc css-dlistbox-sub">
            <div className="css-dlistbox-l-item1">
              <div className="">
                <p className="ss-dlistbox-top-p1 tcl">
                  The most complete trading cryptocurrency platform
                </p>
                <p className="ss-dlistbox-top-p2 tcl">
                  Here are a few reasons why you should choose Remitano{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="css-dlistbox-top-desc css-dlistbox-sub-desc">
            <div className="css-dlistbox-desc-box col-4">
              <div className="home_infoWrapper__G_KFW">
                <img src={icon_margin} className="home_margin__qse_K" alt="" />
                <p>Maximize profit with leverage</p>
              </div>
            </div>
            <div className="css-dlistbox-desc-box col-4">
              <div className="home_infoWrapper__G_KFW">
                <img
                  src={icon_contract}
                  className="home_margin__qse_K"
                  alt=""
                />
                <p>Up to 125x leverage with superior spreads</p>
              </div>
            </div>
            <div className="css-dlistbox-desc-box col-4">
              <div className="home_infoWrapper__G_KFW">
                <img src={icon_etf} className="home_margin__qse_K" alt="" />
                <p>Increased leverage, no liquidation risk</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Feature1;
