import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useNotify } from "_common/component/NotifyProvider";
import { Link, useSearchParams } from "react-router-dom";
import classNames from "classnames";

import ChartTrade from "./ChartTrade";
import Transaction from "./Transaction";

const SpotTradeMobilePage = observer(() => {
  const {
    authStore: { games, user },
  } = useStores();

  const { updatePrice } = useNotify();

  const [params] = useSearchParams();
  const tab = params?.get("tab") || "chart";

  const [activeTab, setActiveTab] = useState("chart");

  useEffect(() => {
    setActiveTab(tab);
  }, [tab]);

  return (
    <>
      <div className="bg_header">
        <div className="tabs">
          <div
            className={classNames("tab ", {
              active: activeTab === "chart",
            })}
            onClick={() => setActiveTab("chart")}
          >
            Biểu đồ
          </div>
          <div
            className={classNames("tab ", {
              active: activeTab === "trade",
            })}
            onClick={() => setActiveTab("trade")}
          >
            Giao dịch
          </div>
        </div>
      </div>
      {activeTab === "chart" && <ChartTrade onChangeTab={setActiveTab} />}
      {activeTab === "trade" && <Transaction />}
    </>
  );
});

export default SpotTradeMobilePage;
