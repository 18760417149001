import React from "react";
import { useStores } from "_common/hooks";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export default function Language() {
  const {
    authStore: { setLanguage, languages, language },
  } = useStores();

  return (
    <div className="container language-page !mt-[50px]">
      <HeaderTop title="Sự lựa chọn ngôn ngữ" />
      <div className="list-lang">
        {languages?.map((item, index) => (
          <div
            className={classNames("item", {
              active: language === item?.code,
            })}
            key={`item-${index.toString()}`}
            onClick={() => setLanguage(item?.code)}
          >
            <div>
              <img src={item?.flag} alt="" className="flag" />
              <span>{item?.text}</span>
            </div>
            {language === item?.code && (
              <FontAwesomeIcon icon={faCheck} width="20" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
