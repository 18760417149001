import React, { useMemo, useState } from "react";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import icon1 from "assets/img/icon/chongzhi.png";
import icon2 from "assets/img/icon/tixin.png";
import icon3 from "assets/img/icon/buy.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { observer } from "mobx-react-lite";

const General = observer(() => {
  const {
    authStore: { display_balance, setDisplayBalance, user, tokens },
  } = useStores();

  const usdtBalance = useMemo(
    () => user?.tokens?.find((obj) => obj.symbol === "usdt"),
    [user]
  );

  const [active, setActive] = useState("main");

  const listCoin = useMemo(() => {
    if (active === "coin")
      return tokens?.filter((obj) => obj?.symbol === "btc");

    return tokens;
  }, [active, tokens]);

  return (
    <div id="general">
      <div className="flex flex-col w-full gap-10 ">
        <div className="flex flex-1">
          <div className="flex flex-col w-full gap-4">
            <div className="allmoneybox">
              <div className="allmbox_1">
                <input type="hidden" id="st" value="1" />
              </div>
              <div className="allmbox_2">
                <span className="fzmm fe6im text-white">
                  Tổng quan sản của tài khoản
                </span>
              </div>
              <div className="allmbox_3">
                <span className="fe6im zhehebox text-[24px] text-white">
                  {formatBalance(usdtBalance?.amount, display_balance)}
                </span>
                <span className="fe6im zhehebox text-[24px] text-white ml-[5px]">
                  USDT
                </span>
                <FontAwesomeIcon
                  onClick={() => setDisplayBalance(!display_balance)}
                  className="fe6im zhehebox text-[24px] text-white ml-[5px]"
                  icon={display_balance ? faEyeSlash : faEye}
                />
              </div>
              <div className="allmbox_3">
                <span className="zhehebox text-[14px] !text-[#9b9b9b]">
                  ~{formatBalance(usdtBalance?.amount, display_balance)}USD
                </span>
              </div>
              <div className="allmbox_3">
                <span className="zhehebox text-[14px] text-white ">
                  Lợi nhuận ngày nay:{` `}
                  <span className="!text-[#00c511]">
                    ~{formatNumber(user?.profit_total_today, "0,0.00")}(0%)
                  </span>
                  {` `} USDT
                </span>
              </div>
              <div className="allmbox_btn">
                <div className="allmbox_btn_1">
                  <Link to="/member/recharge">
                    <div className="allmbox_cbtn ">
                      <img src={icon1} alt="" width="23" className="mr-[5px]" />
                      <span className="fe6im">Nạp tiền</span>
                    </div>
                  </Link>
                  <Link to="/member/withdraw">
                    <div className="allmbox_tbtn ">
                      <img src={icon2} alt="" width="23" className="mr-[5px]" />
                      <span className="fe6im">Rút</span>
                    </div>
                  </Link>
                  <Link to="/member">
                    <div className="allmbox_tbtn ">
                      <img src={icon3} alt="" width="23" className="mr-[5px]" />
                      <span className="fe6im">Của Tôi</span>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div className="findbox_1">
              <div className="findbox_2">
                <div className="findbox_3">
                  <span className="fzmm fe6im">Danh sách tài sản</span>
                </div>
              </div>
            </div>
            <div className="list-token">
              {listCoin?.map((item) => (
                <div key={item} className="listbox">
                  <div className="listbox_title">
                    <div className="listbox_title_l">
                      <span className="fe6im text-[16px] text-[500]">USDT</span>
                    </div>
                  </div>
                  <div className="w-full h-[60px]">
                    <div className="w-[33.33%] h-[60px] inline-block">
                      <div className="w-full h-[30px] leading-[40px]">
                        <span className="fcc">Có sẵn</span>
                      </div>
                      <div className="w-full h-[30px] leading-[20px]">
                        <span className="fe6im f12" id="num_usdt">
                          {formatBalance(item?.amount, display_balance)}
                        </span>
                      </div>
                    </div>
                    <div className="w-[33.33%] h-[60px] inline-block">
                      <div className="w-full h-[30px] leading-[40px]">
                        <span className="fcc">Đóng băng</span>
                      </div>
                      <div className="w-full h-[30px] leading-[20px]">
                        <span className="fe6im f12" id="numd_usdt">
                          0
                        </span>
                      </div>
                    </div>
                    <div className="w-[33.33%] h-[60px] inline-block">
                      <div className="w-full h-[30px] leading-[40px] text-right">
                        <span className="fcc">Miễn giảm(USDT)</span>
                      </div>
                      <div className="w-full h-[30px] leading-[20px] text-right">
                        <span className="fe6im f12" id="zhehe_usdt">
                          0
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
export default General;
