/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { observer } from "mobx-react-lite";
import QRCode from "react-qr-code";
import { useStores } from "_common/hooks";
import { handleCopyLink } from "_common/utils/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import LeftNav from "./LeftNav";

const AuthPage = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <main className="css-1wr4jig">
      <main className="css-xry4yv !flex-row">
        <LeftNav />
        <div className="css-1wr4jig">
          <div className="css-1u0m1fa">
            <div className="css-fhl5lc">
              <span className="css-1cdfkn6">Quản lý xác thực thực sự</span>
            </div>
          </div>
          <div className="css-1bbywci">
            <div className="css-1pl31wt">
              <div className="css-k2y2sp">
                <div className="css-1868gi1">Thông tin chứng nhận</div>
              </div>
            </div>
            <div className="css-z32tht">
              <div className="css-joa6mv">
                <span className="f12 fcy">
                  Sau khi hoàn thành xác thực thực tế, bạn có thể nhận được các
                  quyền và lợi ích tương ứng
                </span>
              </div>
              <div className="css-1op9i22">
                <div className="w-[400px] min-h-[400px]">
                  <div className="css-vurnku">
                    <div className="css-154a57d">
                      <input
                        placeholder="Vui lòng nhập số điện thoại"
                        className="css-16fg16t"
                        defaultValue={user?.phone}
                      />
                      <label className="bn-input-label css-5vzups">
                        <div className="css-kiaw5d">Số điện thoại di động</div>
                      </label>
                    </div>
                  </div>
                  <div className="w-full h-[30px]" />
                  <div className="css-vurnku">
                    <div className="css-154a57d !min-h-[120px] !max-h-[200px] !border-[0]">
                      <img
                        src={user?.front_image_url}
                        alt=""
                        className="h-[100px]"
                      />
                      <label className="bn-input-label css-5vzups">
                        <div className="css-kiaw5d">Tải lên thẻ ID của bạn</div>
                      </label>
                    </div>
                  </div>
                  <div className="w-full h-[30px]" />
                  <div className="css-vurnku">
                    <div className="css-154a57d !min-h-[120px] !max-h-[200px] !border-[0]">
                      <img
                        src={user?.back_image_url}
                        alt=""
                        className="h-[100px]"
                      />
                      <label className="bn-input-label css-5vzups">
                        <div className="css-kiaw5d">Tải mặt sau của thẻ ID</div>
                      </label>
                    </div>
                  </div>
                  <div className="w-full h-[30px]" />
                  <div className="css-x5jwjg">
                    <button
                      type="button"
                      className="css-3kuzxc !py-[6px] !px-[30px]"
                    >
                      Đánh giá chứng nhận thành công
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </main>
  );
});

export default AuthPage;
