import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { PaymentApi } from "states/api/payment";
import { useForm } from "react-hook-form";
import { handleCopyLink } from "_common/utils/helper";
import UploadFile from "_common/component/UploadFile";
import { formatNumber } from "_common/utils/formatValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import QRCode from "react-qr-code";
import i18next from "i18next";

const schema = yup
  .object({
    amount: yup.number().required(i18next.t("verify_require")),
  })
  .required();

const DepositPage = observer(() => {
  const {
    authStore: { user, general, user_wallet, game_wallet, coins },
  } = useStores();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const [params] = useSearchParams();

  const symbol = params.get("symbol");
  const network = params.get("network");

  const [address, setAddress] = useState();
  const [payment_images, setPaymentImage] = useState();

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const mainCoin = useMemo(
    () => coins?.find((obj) => obj?.symbol === symbol),
    [coins, symbol]
  );

  useEffect(() => {
    if (!mainCoin) return;
    if (symbol === "usdt") {
      if (network === "trc20") {
        setAddress(mainCoin?.trc20_address);
      } else if (network === "erc20") {
        setAddress(mainCoin?.erc20_address);
      } else {
        setAddress(mainCoin?.primary_address);
      }
    } else {
      setAddress(mainCoin?.primary_address);
    }
  }, [mainCoin, symbol, network]);

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) =>
      PaymentApi.deposit({
        params: {
          ...variables,
          symbol,
          receive_address: address,
          network,
          payment_image: payment_images,
          type: "deposit",
        },
      }),
    {
      onSuccess: (res) => {
        reset();
        setPaymentImage();
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["payment", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("withdrawal_successful")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    const amount = values?.amount;
    if (amount < Number(general?.min_deposit || 0))
      return NotificationManager.error(
        `${i18next.t("enter_large_amount_msg")} ${formatNumber(
          general?.min_deposit
        )}`
      );
    if (amount > Number(general?.max_deposit || 0))
      return NotificationManager.error(
        `${i18next.t("enter_smaller_amount_msg")} ${formatNumber(
          general?.max_deposit
        )}`
      );
    onSubmit({ ...values });
    return null;
  };

  const onReceivePaymentImages = (images) => {
    setPaymentImage(images[0]?.url);
  };

  return (
    <main className="css-1wr4jig">
      <main className="css-xry4yv !flex-row">
        <div className="css-1ay57iv">
          <div className="css-8hzjvg">
            <div className="css-1eklhqk">
              <div className="css-190uhut">
                <div className="css-11y6cix" onClick={() => navigate(-1)}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="css-146agw4"
                  >
                    <path
                      d="M16.414 18.586L15 20l-8-8 8-8 1.414 1.414L9.828 12l6.586 6.586z"
                      fill="#76808F"
                    />
                  </svg>
                  <div className="css-1djsyd6">Tái chế tiền kỹ thuật số</div>
                </div>
              </div>
            </div>
          </div>
          <div className="css-1dihobw">
            <div className="css-1eklhqk">
              <div className="css-tu2ioc w-[60%] float-left">
                <form className="css-1ekpz1z" onSubmit={handleSubmit(onSave)}>
                  <div className="czbox">
                    <div className="czbox_1">
                      <span className="fch f16">Chọn tiền tệ</span>
                    </div>
                    <div className="czbox_2">
                      <div className="czbox_3">
                        <span className="fch f12">Cặp tiền</span>
                      </div>
                      <div className="czbox_4">
                        <span className="fch f14">{symbol}</span>
                      </div>
                    </div>
                  </div>
                  <div className="czbox !mb-[0]">
                    <div className="czbox_1">
                      <span className="fch f16">Mạng nạp lại</span>
                    </div>
                    <div className="czbox_2">
                      <div className="czbox_3">
                        <span className="fch f12">Mạng</span>
                      </div>
                      <div className="czbox_4">
                        <span className="fch f14">
                          {symbol === "USDT" ? "TRC20" : symbol}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="czbox !min-h-[200px]">
                    <div className="czbox_1" />
                    <div className="czbox_2">
                      <div className="w-full h-[200px] mt-[10px]">
                        <div className="w-full h-[120px] text-center p-[20px]">
                          {user.link_ref && (
                            <QRCode
                              size={256}
                              viewBox="0 0 256 256"
                              className="w-[100px] h-[100px] inline"
                              value={user.link_ref}
                            />
                          )}
                        </div>
                        <div className="w-full h-[40px] leading-[40px] text-center">
                          <span>
                            0x7B0e854F0b399ff961A2EBf51BC65661F88cA33F
                          </span>
                          <FontAwesomeIcon
                            icon={faCopy}
                            className="fcy !w-[16px] !h-[16px] ml-[5px]"
                            onClick={() =>
                              handleCopyLink(
                                "0x7b0e854f0b399ff961a2ebf51bc65661f88ca33f"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="czbox">
                    <div className="czbox_1">
                      <span className="fch f16">Số lượng nạp tiền</span>
                    </div>
                    <div className="czbox_2">
                      <div className="czbox_3">
                        <span className="fch f12">Số lượng</span>
                      </div>
                      <div className="czbox_6">
                        <input
                          type="number"
                          inputMode="decimal"
                          min={0}
                          max={1000000000000}
                          step={0.000001}
                          name="address"
                          autoComplete="off"
                          className="czbox_5 !w-full !h-full !border-[#f5f5f5] !p-[0] !pl-[15px]"
                          {...register("amount")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="czbox !h-[150px]">
                    <div className="czbox_1 !h-[150px]">
                      <span className="fch f16">
                        Tải chứng từ chuyển khoản lên
                      </span>
                    </div>
                    <div className="czbox_2 !h-[150px]">
                      <div className="czbox_3">
                        <span className="fch f12">Chuyển phiếu chứng từ</span>
                      </div>
                      <div className="czbox_4 min-h-[150px] text-center !border-[0]">
                        <UploadFile
                          onReceiveImages={onReceivePaymentImages}
                          image={payment_images}
                          folder="payment"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="czbox">
                    <div className="czbox_2">
                      <div className="czbox_3" />
                      <button
                        type="submit"
                        disabled={!isValid}
                        className="czbox_4 text-center"
                        style={{
                          background:
                            "linear-gradient(to left,#eeb80d,#ffe35b)",
                        }}
                      >
                        <span className="fch f14">Gửi đi</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="w-[35%] h-[600px] p-[10px] float-right">
                <div className="w-full h-[60px] leading-[60px]">
                  <span>Lời khuyên tử tế</span>
                </div>
                <div className="w-full min-h-[180px] p-[10px]">
                  <span className="f14 text-[#ff0000]">
                    Số lượng nạp tiền tối thiểu：10.00USDT，Việc nạp tiền nhỏ
                    hơn số tiền tối thiểu sẽ không thể kiểm tra tài khoản và
                    không thể trả lại
                  </span>
                  <br />
                  <br />
                  <span className="f12 fch">
                    Vui lòng chọn mạng Kênh nạp tiền chính xác, nếu không các
                    tài sản sẽ không được truy xuất
                  </span>
                  <br />
                  <br />
                  <span className="f14 fch">
                    Số lượng nạp tiền tối thiểu：10.00USDT，Việc nạp tiền nhỏ
                    hơn số tiền tối thiểu sẽ không thể kiểm tra tài khoản và
                    không thể trả lại
                  </span>
                  <br />
                  <br />
                  <span className="f14 fch">
                    Địa chỉ nạp tiền của bạn sẽ không thay đổi thường xuyên, bạn
                    có thể lặp lại việc nạp tiền; nếu có những thay đổi, chúng
                    tôi sẽ cố gắng thông báo cho bạn thông qua thông báo trang
                    web hoặc thư
                  </span>
                  <br />
                  <br />
                  <span className="f14 fch">
                    Vui lòng xác nhận bảo mật của máy tính và trình duyệt để
                    ngăn thông tin bị giả mạo hoặc bị rò rỉ
                  </span>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </main>
  );
});

export default DepositPage;
