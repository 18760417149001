import { useQuery } from "@tanstack/react-query";
import i18next from "i18next";
import { get } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { GamePlayerApi } from "states/api/gamePlayer";
import { formatNumber } from "_common/utils/formatValue";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faLongArrowAltLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

export default function HistoryData() {
  const navigate = useNavigate();
  const [item, setItem] = useState();

  const { data, refetch } = useQuery(
    ["game-spot", "list", "complete"],
    () =>
      GamePlayerApi.getList({
        params: {
          page: 1,
          limit: 10,
          type: "trade",
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    refetch();
  }, []);

  const renderItemDetail = () => (
    <>
      <div className="no_header relative">
        <span
          className="btn_back absolute left-[10px] translate-y-[50%]"
          onClick={() => setItem()}
        >
          <FontAwesomeIcon icon={faLongArrowAltLeft} color="#707A8A" />
        </span>
        <div className="infotbox text-center">
          <b className="text-[24px] text-[#00b897] text-uppercase">
            {item?.game?.name} Chi tiết hợp đồng
          </b>
        </div>
      </div>
      <div className="infobox">
        <div className="lbox">
          <div className="lbox_l">
            <span className="fe6im fzmm">Giá trị hợp đồng</span>
          </div>
          <div className="lbox_r">
            <span className="fe6im fzmmm">
              {formatNumber(item?.amount)}USDT
            </span>
          </div>
        </div>
        <div className="lbox">
          <div className="lbox_l">
            <span className="fe6im fzmm">Thời hạn hợp đồng</span>
          </div>
          <div className="lbox_r">
            <span className="fe6im fzmmm">{formatNumber(item?.type)}s</span>
          </div>
        </div>
        <div className="lbox">
          <div className="lbox_l">
            <span className="fe6im fzmm">Loại hợp đồng</span>
          </div>
          <div className="lbox_r">
            <span
              className={`fzmmm text-[#0ecb81] ${
                item?.bet_game === "sell" && "text-[#f5465c]"
              }`}
            >
              {i18next.t(item?.bet_game)}
            </span>
          </div>
        </div>
        <div className="lbox">
          <div className="lbox_l ">
            <span className="fe6im fzmm">Tình trạng hợp đồng</span>
          </div>
          <div className="lbox_r">
            <span
              className={classNames("fzmmm", {
                "text-[#f5465c]": item?.status === "lose",
                "text-[#0ecb81]": item?.status === "win",
              })}
            >
              {i18next.t(item?.status)}
            </span>
          </div>
        </div>
        <div className="lbox">
          <div className="lbox_l">
            <span className="fe6im fzmm">Đơn giá</span>
          </div>
          <div className="lbox_r">
            <span className="fe6im fzmm">
              {formatNumber(item?.open, "0,0.[0000]")}
            </span>
          </div>
        </div>
        <div className="lbox">
          <div className="lbox_l">
            <span className="fe6im fzmm">Thiết lập thời gian</span>
          </div>
          <div className="lbox_r">
            <span className="fe6im fzmm">
              {moment(item?.created_at).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </div>
        </div>
        <div className="lbox">
          <div className="lbox_l">
            <span className="fe6im fzmm">Thời gian định vị</span>
          </div>
          <div className="lbox_r">
            <span className="fe6im fzmm">
              {moment(item?.created_at).format("YYYY-MM-DD HH:mm:ss")}
            </span>
          </div>
        </div>
        <div className="lbox">
          <div className="lbox_l">
            <span className="fe6im fzmm">Lợi nhuận và thua lỗ</span>
          </div>
          <div className="lbox_r">
            <span
              className={classNames("fzmmm", {
                "text-[#f5465c]": item?.status === "lose",
                "text-[#0ecb81]": item?.status === "win",
              })}
            >
              {formatNumber(item?.amount_payback, "0,0.[0000]")}
            </span>
          </div>
        </div>
      </div>
    </>
  );

  return item ? (
    renderItemDetail()
  ) : (
    <div className="mx-auto w-full">
      <div className="list-history-data bg-[#121420]">
        {get(data, "data", [])?.map((item) => (
          <div key={item.id} className="listbox">
            <div className="listbox_title">
              <div className="listbox_title_l">
                <span
                  className={`f14 text-[16px] text-[500] text-[#0ecb81] ${
                    item?.bet_game === "sell" && "text-[#f5465c]"
                  }`}
                >
                  {i18next.t(item?.bet_game)}:
                </span>
                <span className="fcc f14 fe6im text-[16px] text-[500] text-uppercase">
                  {item?.game?.name}
                </span>
              </div>
              <div onClick={() => setItem(item)}>
                <div className="listbox_title_r">
                  <span className="fcc text-[12px] text-[#A9AEB8]">
                    Đã kết toán
                  </span>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    color="#e6e6e6"
                    className="ml-[5px]"
                  />
                </div>
              </div>
            </div>
            <div className="w-full h-[60px]">
              <div className="w-[33%] h-[60px] inline-block">
                <div className="w-full h-[30px] leading-[40px]">
                  <span className="f12 text-[#6B7785]">Số tiền đầu tư</span>
                </div>
                <div className="w-full h-[30px] leading-[30px]">
                  <span className="f12 text-[#A9AEB8]">
                    {formatNumber(item?.amount)}
                  </span>
                </div>
              </div>
              <div className="w-[33%] h-[60px] inline-block">
                <div className="w-full h-[30px] leading-[40px]">
                  <span className="f12 text-[#6B7785]">Đơn giá</span>
                </div>
                <div className="w-full h-[30px] leading-[30px]">
                  <span className="f12 text-[#A9AEB8]">
                    {" "}
                    {formatNumber(item?.open, "0,0.[0000]")}
                  </span>
                </div>
              </div>
              <div className="w-[33%] h-[60px] inline-block">
                <div className="w-full h-[30px] leading-[40px] text-right">
                  <span className="f12 text-[#6B7785]">
                    Thiết lập thời gian
                  </span>
                </div>
                <div className="w-full h-[30px] leading-[30px] text-right">
                  <span className="f12 text-[#A9AEB8]">
                    {moment(item?.created_at).format("MM-DD HH:mm")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
