import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { useMediaQuery } from "_common/component/MediaQueryProvider";

const News = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { isMobile } = useMediaQuery();

  const {
    authStore: { banners, top_coin, games, authenticated },
  } = useStores();

  if (isMobile)
    return (
      <Swiper
        spaceBetween={30}
        centeredSlides
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="swiper2 w-[50%] rounded-[20px]"
      >
        {banners.map((item) => (
          <SwiperSlide key={item?.id}>
            {item.type === "image" && (
              <a href="/">
                <img alt="" src={item?.image_url} height="287" />
              </a>
            )}
            {item.type === "video" && (
              <iframe
                width="430"
                height="287"
                src={item?.src}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                title="video"
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    );

  return (
    <div className="news__slider" id="tns1">
      {banners?.map((item) => (
        <div className="item_news" aria-hidden="true" key={`item-${item?.id}`}>
          <a href="openleverage" title={item?.title} className="d-block img">
            {item.type === "image" && (
              <a href="/">
                <img
                  alt={item?.title}
                  src={item?.image_url}
                  className="img-fluid"
                />
              </a>
            )}
            {item.type === "video" && (
              <iframe
                width="430"
                height="287"
                src={item?.src}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
                title="video"
              />
            )}
          </a>
        </div>
      ))}
    </div>
  );
});

export default News;
