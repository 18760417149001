import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import { useStores } from "_common/hooks";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import i18next from "i18next";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Loading from "_common/component/Loading";
import { observer } from "mobx-react-lite";
import InputPassword from "_common/component/InputPassword";
import classNames from "classnames";

const inputText = {
  email: "Nhập Email",
  phone: "Nhập Số điện thoại",
  username: "Nhập Tên tài khoản",
};

const schema = yup
  .object({
    username: yup.string().required(i18next.t("verify_require")),
    password: yup.string().required(i18next.t("verify_require")),
  })
  .required();

const LoginMobile = observer(() => {
  const navigate = useNavigate();
  const { authStore } = useStores();

  const [activeTab, setActiveTab] = useState("email");

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.login(variables),
    {
      onSuccess: (res) => {
        authStore.updateUser(res?.data);
        setTimeout(() => {
          navigate("/");
        }, 200);
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleLogin = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  return (
    <>
      <div className="no_headers header_box">
        <div
          className="fl bhalf allhg txtl"
          style={{ lineHeight: "55px", paddingLeft: "1rem" }}
        >
          <FontAwesomeIcon
            icon={faClose}
            style={{ fontSize: "24px", color: "#fff" }}
          />
        </div>
        <div
          className="fr bhalf allhg txtr !text-[#4ba5ff] "
          style={{ lineHeight: "50px", paddingRight: "1rem" }}
          onClick={() => navigate("/auth/register")}
        >
          Đăng ký
        </div>
      </div>
      <form
        className="auth-sign-in"
        onSubmit={handleSubmit(handleLogin)}
        autoComplete="off"
      >
        {isLoading && <Loading />}
        <h3 className="title px-4">Vui lòng đăng nhập</h3>
        <div className="sbui-space-col sbui-space-y-6 pr-[1rem] pl-[1rem]">
          <div className="fd-box">
            <span
              className={classNames({
                "fsl active": activeTab === "email",
                "fsr dfs": activeTab !== "email",
              })}
              onClick={() => setActiveTab("email")}
            >
              Email
            </span>
            <span
              className={classNames({
                "fsl active": activeTab === "phone",
                "fsr dfs": activeTab !== "phone",
              })}
              onClick={() => setActiveTab("phone")}
            >
              Số điện thoại
            </span>
            <span
              className={classNames({
                "fsl active": activeTab === "username",
                "fsr dfs": activeTab !== "username",
              })}
              onClick={() => setActiveTab("username")}
            >
              Tên tài khoản
            </span>
          </div>
          <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive !mt-[0]">
            <div className="sbui-formlayout__content-container-horizontal">
              <div className="sbui-input-container">
                <input
                  autoComplete="off"
                  type="search"
                  placeholder={inputText[activeTab]}
                  className="sbui-input"
                  {...register("username")}
                />
              </div>
            </div>
          </div>
          <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
            <div className="sbui-formlayout__content-container-horizontal">
              <div className="sbui-input-container">
                <InputPassword
                  autoComplete="current-password"
                  placeholder="Mật khẩu"
                  type="password"
                  className="sbui-input"
                  {...register("password")}
                />
              </div>
            </div>
          </div>
          <span className="sbui-btn-container sbui-btn--w-full">
            <button
              className="text-[#ffffff] font-[600] !bg-[#0081ff] w-full min-h-[44px] rounded-[12px]"
              type="submit"
              disabled={!isValid || isLoading}
            >
              <span> {i18next.t("login")}</span>
            </button>
          </span>
          <div className="flex justify-center items-center">
            <img src={authStore?.logo} alt="" className="w-[100px]" />
          </div>
        </div>
      </form>
    </>
  );
});

export default LoginMobile;
