import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { NotificationManager } from "react-notifications";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { useStores } from "_common/hooks";
import Loading from "_common/component/Loading";
import classNames from "classnames";
import i18next from "i18next";
import InputPassword from "_common/component/InputPassword";
import ActiveCode from "./ActiveCode";

const schema = yup
  .object({
    username: yup.string().required(i18next.t("verify_require_username")),
    password: yup.string().required(i18next.t("verify_require_password")),
  })
  .required();

function RegisterMobile() {
  const navigate = useNavigate();
  const { authStore } = useStores();
  const [params] = useSearchParams();

  const ref_no = params?.get("ref_no");

  const [isSuccess, setIsSuccess] = useState(false);
  const [activeTab, setActiveTab] = useState("email");

  const { mutate, isLoading } = useMutation(
    (variables) => AuthApi.register(variables),
    {
      onSuccess: (res) => {
        authStore.updateToken(res?.data);
        authStore.updateUser(res?.data);
        setTimeout(() => {
          navigate("/");
        }, 200);
        // setIsSuccess(true);
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleRegister = (values) => {
    if (isLoading) return;
    mutate(values);
  };

  useEffect(() => {
    if (!ref_no) return;
    setValue("ref_no", ref_no);
  }, [ref_no]);

  useEffect(() => {
    reset();
  }, [activeTab]);

  if (isSuccess) return <ActiveCode />;

  return (
    <>
      <div className="no_headers header_box">
        <div
          className="fl bhalf allhg txtl"
          style={{ lineHeight: "55px", paddingLeft: "1rem" }}
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon
            icon={faClose}
            style={{ fontSize: "24px", color: "#fff" }}
          />
        </div>
        <div
          className="fr bhalf allhg txtr !text-[#4ba5ff] "
          style={{ lineHeight: "50px", paddingRight: "1rem" }}
          onClick={() => navigate("/auth/login")}
        >
          Đăng nhập
        </div>
      </div>
      <form id="auth-sign-up" onSubmit={handleSubmit(handleRegister)}>
        {isLoading && <Loading />}
        <h3 className="title px-4">Đăng ký ngay lập tức</h3>
        <div className="sbui-space-col sbui-space-y-6 pr-[1rem] pl-[1rem]">
          <div className="fd-box">
            <span
              className={classNames({
                "fsl active": activeTab === "email",
                "fsr dfs": activeTab !== "email",
              })}
              onClick={() => setActiveTab("email")}
            >
              Email
            </span>
            <span
              className={classNames({
                "fsl active": activeTab === "phone",
                "fsr dfs": activeTab !== "phone",
              })}
              onClick={() => setActiveTab("phone")}
            >
              Số điện thoại
            </span>
          </div>
          <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive !mt-[0]">
            <div className="sbui-formlayout__content-container-horizontal">
              <div className="sbui-input-container">
                <input
                  autoComplete="off"
                  type="search"
                  placeholder="Tên tài khoản"
                  className="sbui-input"
                  {...register("username")}
                />
              </div>
            </div>
          </div>
          <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
            <div className="sbui-formlayout__content-container-horizontal">
              <div className="sbui-input-container flex gap-2">
                {activeTab === "phone" && (
                  <>
                    <input
                      autoComplete="off"
                      type="search"
                      className="sbui-input !w-[50px]"
                      value="+84"
                    />
                    <input
                      autoComplete="off"
                      type="search"
                      placeholder="Số điện thoại"
                      className="sbui-input"
                      {...register("phone")}
                    />
                  </>
                )}
                {activeTab === "email" && (
                  <input
                    autoComplete="off"
                    type="search"
                    placeholder="Email"
                    className="sbui-input"
                    {...register("email")}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
            <div className="sbui-formlayout__content-container-horizontal">
              <div className="sbui-input-container">
                <InputPassword
                  autoComplete="current-password"
                  placeholder="Mật khẩu"
                  type="password"
                  className="sbui-input"
                  {...register("password")}
                />
              </div>
            </div>
          </div>
          {Number(authStore.general?.allow_ref_no) === 1 && (
            <div>
              <div className="sbui-formlayout sbui-formlayout--medium sbui-formlayout--responsive">
                <div className="sbui-formlayout__content-container-horizontal">
                  <div className="sbui-input-container">
                    <input
                      disabled={!!ref_no}
                      type="text"
                      placeholder="Mã giới thiệu"
                      className="sbui-input"
                      {...register("ref_no")}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="sbui-space-col sbui-space-y-6">
            <span className="sbui-btn-container sbui-btn--w-full">
              <button
                className="text-[#ffffff] font-[600] !bg-[#0081ff] w-full min-h-[44px] rounded-[12px]"
                type="submit"
                disabled={!isValid || isLoading}
              >
                <span>{i18next.t("register")}</span>
              </button>
            </span>
          </div>
          <div className="flex justify-center items-center mb-2">
            <img src={authStore?.logo} alt="" className="w-[100px]" />
          </div>
        </div>
      </form>
    </>
  );
}

export default RegisterMobile;
