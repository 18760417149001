import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";

import banner from "assets/img/banner/banner_toy.png";

import Tab1 from "./Tab/Tab1";
import Tab2 from "./Tab/Tab2";
import Tab3 from "./Tab/Tab3";

const OrepoolPage = observer(() => {
  const {
    authStore: { ieo },
  } = useStores();
  const [activeTab, setActiveTab] = useState(1);

  return (
    <main className="css-1wr4jig !bg-white">
      <div className="css-1odg5z2 before:bg-none">
        <div className="css-1xrgo9z !my-[0] !mx-[15%] !justify-start">
          <div>
            <p className="text-[40px]">DeFi</p>
            <p className="text-[18px]">
              Tận hưởng phần thưởng khai thác, với tiền gửi, hoạt động linh hoạt
            </p>
          </div>
          <div className="absolute right-[10%]">
            <img src={banner} alt="" width="540" height="300" />
          </div>
        </div>
      </div>
      <div className="css-1ax9bc0">
        <div className="css-6mr8u2">
          <div className="css-abpumn">
            <span
              data-bn-type="text"
              className={activeTab === 1 ? "css-1m3syfi" : "css-lk1png"}
              onClick={() => setActiveTab(1)}
            >
              <div className="css-cbipbt">Sở hữu toàn</div>
            </span>
            <span
              data-bn-type="text"
              className={activeTab === 2 ? "css-1m3syfi" : "css-lk1png"}
              onClick={() => setActiveTab(2)}
            >
              <div className="css-cbipbt">Chia sẻ</div>
            </span>
            <span
              data-bn-type="text"
              className={activeTab === 3 ? "css-1m3syfi" : "css-lk1png"}
              onClick={() => setActiveTab(3)}
            >
              <div className="css-cbipbt">Máy khai thác của tôi</div>
            </span>
          </div>
        </div>
      </div>
      <div className="css-uliqdc">
        {activeTab === 1 && <Tab1 />}
        {activeTab === 2 && <Tab2 />}
        {activeTab === 3 && <Tab3 />}
      </div>
    </main>
  );
});

export default OrepoolPage;
