import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatNumber } from "_common/utils/formatValue";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import classNames from "classnames";

const Usdt = observer(({ onClose }) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { banners, top_coin, games },
  } = useStores();

  const [gameList, setGameList] = useState(games);

  const { data, refetch } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const coinListPrice = useMemo(
    () =>
      gameList.map((item) => {
        const price = data?.find(
          (obj) => obj?.symbol?.toLowerCase() === item?.code
        );

        return { ...item, price };
      }),
    [gameList, data]
  );

  const handleKeydown = (event) => {
    if (event?.keyCode === 13) {
      const valueKey = event?.target?.value;
      const cloneData = [...gameList];
      const result = cloneData.filter(
        (obj) => obj?.name?.toLowerCase()?.search(valueKey?.toLowerCase()) > -1
      );
      if (!valueKey) {
        setGameList(games);
      } else {
        setGameList(result);
      }
    }
  };

  return (
    <div className="coin-list">
      <div className="list">
        <div className="item header !bg-[transparent] !relative">
          <div className="col1 text-[#707A8A]">Giao dịch</div>
          <div className="col2 text-[#707A8A]">Giá mới nhất</div>
          <div className="col3 text-[#707A8A]">24H Số lượng</div>
        </div>
        <div className="h-[80vh] overflow-y-scroll">
          {coinListPrice?.map((item) => (
            <div
              onClick={() => {
                navigate(`/trade?symbol=${item?.code}`);
                onClose();
              }}
              className="item !bg-[transparent]"
              key={item?.id}
            >
              <div className="col1">
                <p className="name">
                  <b>{item?.name?.split("/")?.[0]}</b>
                  <b>/{item?.name?.split("/")?.[1]}</b>
                </p>
              </div>
              <div className="col2">
                <span
                  className={classNames({
                    "text-green": item?.price?.priceChangePercent > 0,
                    "text-red": item?.price?.priceChangePercent <= 0,
                  })}
                >
                  {formatNumber(item?.price?.lastPrice, "0,0.[00000]")}
                </span>
              </div>
              <div className="col3">
                <span
                  className={classNames("tag ", {
                    green: item?.price?.priceChangePercent > 0,
                    red: item?.price?.priceChangePercent <= 0,
                  })}
                >
                  {formatNumber(item?.price?.priceChangePercent, "0,0.[00]")}%
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default Usdt;
