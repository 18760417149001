import React from "react";
import { observer } from "mobx-react-lite";
import QRCode from "react-qr-code";
import { useStores } from "_common/hooks";
import { handleCopyLink } from "_common/utils/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import LeftNav from "./LeftNav";

const MyBillPage = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <main className="css-1wr4jig">
      <main className="css-xry4yv !flex-row">
        <LeftNav />
        <div className="css-160vccy">
          <div className="css-146q23">
            <div className="css-1e6doj4">
              <div className="css-6vt7sa">th</div>
              <div className="css-1sgz1lk">
                <div className="css-ize0sl">
                  <div className="css-1124n14">than***@gmail.com</div>
                  <div className="css-1uoge8i">
                    <div className="css-180eiyx">
                      <div className="css-1ap5wc6">người sử dụngID:</div>
                      7012687
                    </div>
                  </div>
                  <div className="css-lzd0h4">
                    <div className="css-bhso1m">đã xác minh</div>
                  </div>
                </div>
                <div className="css-1ry7rnu">
                  <div className="css-9cwl6c">
                    Thời gian đăng nhập cuối cùng 2023-09-10 04:05:24
                  </div>
                  <div className="css-vurnku">IP: 172.71.215.104</div>
                </div>
              </div>
            </div>
          </div>
          <div className="css-jlbk6n">
            <div className="css-ei3nni">
              <div className="css-1p01izn">
                <div className="css-1hythwr">
                  <div className="css-hwv82q">
                    <div className="css-5x6ly7">
                      <div className="css-5x6ly7">
                        <span className="css-181kvgz">Hồ sơ tài chính</span>
                      </div>
                      <div className="css-10nf7hq" />
                    </div>
                  </div>
                  <div className="css-1dcd6pv">
                    <div className="css-1h690ep">
                      <div className="css-phax11">
                        <div className="css-9b6x94">Loại hoạt động</div>
                      </div>
                      <div className="css-phax11 w-[30%]">
                        <div className="css-9b6x94">Cặp tiền</div>
                      </div>
                      <div className="css-phax11">
                        <div className="css-9b6x94">số lượng</div>
                      </div>
                      <div className="css-phax11">
                        <div className="css-9b6x94">
                          Cân bằng sau khi hoạt động
                        </div>
                      </div>
                      <div className="css-phax11">
                        <div className="css-9b6x94">Ngày hoạt động</div>
                      </div>
                    </div>
                    <div className="css-1pysja1">
                      <div className="css-kc3i4p">
                        <div className="css-phax11">
                          <div className="css-1g02g2m !text-[12px]">
                            <span className="fch fzmm">
                              Phát hành mã thông báo đăng ký
                            </span>
                          </div>
                        </div>
                        <div className="css-phax11 w-[30%]">
                          <div className="css-4cffwv">
                            <div className="css-cvky42">USDT</div>
                          </div>
                        </div>
                        <div className="css-phax11">
                          <div className="css-4cffwv">
                            <div className="css-cvky42">263.4950</div>
                          </div>
                        </div>
                        <div className="css-phax11">
                          <div className="css-4cffwv">
                            <div className="css-cvky42 !text-[#ff0000]">
                              -1360133.6389
                            </div>
                          </div>
                        </div>
                        <div className="css-phax11">
                          <div className="css-4cffwv">
                            <div className="css-cvky42">
                              2023-09-08 11:45:58
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </main>
  );
});

export default MyBillPage;
