import React from "react";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

import iconLogo from "assets/img/icon/644b9cc38fec7.png";

export default function About() {
  return (
    <div className="container !mt-[50px]">
      <HeaderTop title="Về chúng tôi" />
      <div className="box_content text-center pt-[10px]">
        <div className="logo fe6im">
          <img src={iconLogo} alt="" className="img_logo inline" />
        </div>
        <p className="title f20 fe6im !text-[20px]">Welcome to Remitano</p>
        <p className="content fe6im text-left">
          Remitano provides a hosted P2P Bitcoin marketplace where people can
          easily and securely buy and sell Bitcoin with a remarkably simple UI,
          friendly 24/7 online customer support, and minimal fees through
          leading users in the marketplace. Our team is mainly composed of
          banking professionals with extensive experience in financial products,
          e-money, payment systems and agile software
          development./r/n/r/nCurrently, it has provided services in many
          countries including Australia, Malaysia, Nigeria, Vietnam, Cambodia,
          China and is growing every day.
        </p>
      </div>
    </div>
  );
}
