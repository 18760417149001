import React from "react";
import { observer } from "mobx-react-lite";
import QRCode from "react-qr-code";
import { useStores } from "_common/hooks";
import { handleCopyLink } from "_common/utils/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import LeftNav from "./LeftNav";

const AddressListPage = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <main className="css-1wr4jig">
      <main className="css-xry4yv !flex-row">
        <LeftNav />
        <div className="css-1wr4jig">
          <div className="css-1u0m1fa">
            <div className="css-fhl5lc">
              <span className="css-1cdfkn6">Quản lý địa chỉ mẫu</span>
            </div>
          </div>
          <div className="css-1bbywci">
            <div className="css-1pl31wt">
              <div className="css-k2y2sp">
                <div className="css-1868gi1">Quản lý địa chỉ</div>
              </div>
            </div>
            <div className="css-z32tht">
              <div className="css-joa6mv" />
              <div className="css-1op9i22">
                <div className="rc-table css-xdf65a rc-table-scroll-horizontal">
                  <div className="rc-table-container">
                    <div className="rc-table-content overflow-auto overflow-hidden">
                      <table className="w-[970px] min-w-full table-auto">
                        <thead className="rc-table-thead">
                          <tr>
                            <th className="rc-table-cell">Tên ngân hàng</th>
                            <th className="rc-table-cell">Tên</th>
                            <th className="rc-table-cell">Số điện thoại</th>
                            <th className="rc-table-cell">Số tài khoản</th>
                          </tr>
                        </thead>
                        <tbody className="rc-table-tbody">
                          <tr className="rc-table-row rc-table-row-level-0">
                            <td className="rc-table-cell">
                              <div className="css-15oyuc7">
                                <div className="css-1c82c04">
                                  <div className="css-1c82c04">NamAbank</div>
                                </div>
                              </div>
                            </td>
                            <td className="rc-table-cell">
                              <div className="css-15oyuc7">
                                <div className="css-16sm7a0">
                                  <div className="css-1c82c04">trunguretn</div>
                                </div>
                              </div>
                            </td>
                            <td className="rc-table-cell">
                              <div className="css-15oyuc7">
                                <div className="css-1f9551p">
                                  <div className="css-1c82c04">2132344343</div>
                                </div>
                              </div>
                            </td>
                            <td className="rc-table-cell">
                              <div className="css-15oyuc7">
                                <div className="css-1c82c04">1232343545</div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </main>
  );
});

export default AddressListPage;
