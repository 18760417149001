import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import icon_margin from "assets/icons/icon_margin.svg";
import icon_contract from "assets/icons/icon_contract.svg";
import icon_etf from "assets/icons/icon_etf.svg";

const JoinTeam = observer(() => {
  const navigate = useNavigate();

  const {
    authStore: { banners, top_coin, games, authenticated },
  } = useStores();

  return (
    <div className="index-box-4">
      <div className="css-1hc8c4h css-1hc8c4h-box-4 st">
        <div className="css-dlistbox css-dlistbox-4">
          <div className="css-dlistbox-top-desc css-dlistbox-sub">
            <div className="css-dlistbox-l-item1">
              <div className="">
                <p className="ss-dlistbox-top-p1 tcl">
                  Join the Remitano Community Today
                </p>
                <p className="ss-dlistbox-top-p2 tcl">Always there for you</p>
              </div>
            </div>
          </div>
          <div className="css-1xamyaw  css-1xamyaw-l" style={{ padding: 20 }}>
            {!authenticated && (
              <form className="form-inline">
                <input
                  type="email"
                  className="form-control mb-2 mr-sm-2 reginput"
                  placeholder="Vui lòng nhập hộp thư hoặc điện thoại di động"
                  id="email"
                />
                <button
                  type="submit"
                  className=" mb-2  ml-2 regbtn"
                  onClick={() => navigate("/auth/login")}
                >
                  Đăng ký
                </button>
              </form>
            )}
          </div>{" "}
          <div className="svg_list" style={{ padding: 20 }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 24 24"
              className="footer_svg"
              fill="#f21515"
            >
              <path d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-7.7 7h-.6l-1.1.01c-1.48.03-3.7.1-4.46.29-.65.16-1.15.65-1.33 1.26-.18.64-.25 1.7-.29 2.46l-.02.82v.75c.03.76.1 2.09.31 2.85.18.61.68 1.1 1.33 1.26.74.19 2.87.26 4.34.29l1.41.01h1.16c1.45-.03 4-.09 4.81-.3a1.84 1.84 0 0 0 1.33-1.26c.2-.75.28-2.05.3-2.82v-.93c0-.67-.06-2.26-.3-3.13a1.84 1.84 0 0 0-1.33-1.26 25.9 25.9 0 0 0-3.88-.28L12.3 7zM10.46 9.9L14.39 12l-3.92 2.11V9.89z" />
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 24 24"
              className="footer_svg"
              fill="#4495ee"
            >
              <path d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-4 7.28V4.5h-2.29c-2.1 0-3.42 1.6-3.42 3.89v1.67H8v2.77h2.29v6.67h2.85v-6.67h2.29l.57-2.77h-2.86V8.94c0-1.1.58-1.66 1.72-1.66H16z" />
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 24 24"
              className="footer_svg"
              fill="#5815b1"
            >
              <path d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zM8.95 9.4H6.16v8.1h2.8V9.4zm6.84-.19c-1.32 0-2 .63-2.38 1.16l-.13.18V9.4h-2.79l.01.49V17.5h2.78v-4.52a1.52 1.52 0 0 1 1.52-1.64c.96 0 1.37.66 1.41 1.66v4.5H19v-4.64c0-2.49-1.37-3.65-3.2-3.65zM7.58 5.5C6.62 5.5 6 6.1 6 6.9c0 .73.54 1.32 1.38 1.4h.18c.97 0 1.57-.62 1.57-1.4-.01-.8-.6-1.4-1.55-1.4z" />
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 24 24"
              className="footer_svg"
              fill="#000000"
            >
              <path d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-5.08 7.63l-.23-.01c-1.06 0-2.05.7-2.6.7-.57 0-1.47-.68-2.42-.66a3.6 3.6 0 0 0-3.05 1.88c-1.3 2.3-.34 5.7.93 7.56l.22.33c.58.8 1.25 1.6 2.1 1.57.94-.04 1.3-.62 2.42-.62 1.13 0 1.45.62 2.43.6.78-.01 1.33-.55 1.83-1.22l.1-.14.33-.48c.42-.63.7-1.26.86-1.66l.1-.3L18 15l-.12-.05a3.34 3.34 0 0 1-.55-5.64l.14-.1.14-.1a3.4 3.4 0 0 0-2.53-1.47l-.16-.01-.23-.01h.23zM14.93 4c-.74.03-1.63.5-2.17 1.14-.47.56-.89 1.45-.78 2.32.83.06 1.67-.43 2.19-1.07.51-.63.86-1.51.76-2.39z" />
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 24 24"
              className="footer_svg"
              fill="#15d756"
            >
              <path d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-7.86 4.5a7.34 7.34 0 0 0-6.46 10.82l.15.26L4.5 19.5l4.08-1.3a7.38 7.38 0 0 0 10.92-6.4c0-4.03-3.3-7.3-7.36-7.3zm0 1.16c3.41 0 6.19 2.76 6.19 6.15a6.17 6.17 0 0 1-9.37 5.27l-.23-.15-2.38.76.77-2.28a6.08 6.08 0 0 1-1.17-3.6 6.17 6.17 0 0 1 6.19-6.15zM9.66 8.47a.67.67 0 0 0-.48.23l-.14.15c-.2.23-.5.65-.5 1.34 0 .72.43 1.41.64 1.71l.14.2a7.26 7.26 0 0 0 3.04 2.65l.4.14c1.44.54 1.47.33 1.77.3.33-.03 1.07-.43 1.22-.85.15-.42.15-.78.1-.85-.02-.05-.08-.08-.15-.12l-1.12-.54a5.15 5.15 0 0 0-.3-.13c-.17-.06-.3-.1-.41.09-.12.18-.47.58-.57.7-.1.1-.18.13-.32.08l-.4-.18a4.64 4.64 0 0 1-2.13-1.98c-.1-.18-.01-.28.08-.37l.27-.31c.1-.1.12-.18.18-.3a.3.3 0 0 0 .01-.26l-.1-.23-.48-1.15c-.15-.36-.3-.3-.4-.3l-.35-.02z" />
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 24 24"
              className="footer_svg"
              fill="#005cfc"
            >
              <path d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm3.193 7c-1.586 0-2.872 1.243-2.872 2.777 0 .217.025.43.074.633a8.251 8.251 0 0 1-5.92-2.902c-.247.41-.389.887-.389 1.397 0 .963.507 1.813 1.278 2.311a2.94 2.94 0 0 1-1.301-.348v.036c0 1.345.99 2.467 2.304 2.723a2.98 2.98 0 0 1-1.298.047c.366 1.103 1.427 1.906 2.683 1.928a5.889 5.889 0 0 1-3.567 1.19c-.231 0-.46-.014-.685-.04A8.332 8.332 0 0 0 9.903 18c5.283 0 8.172-4.231 8.172-7.901 0-.12-.002-.24-.008-.36A5.714 5.714 0 0 0 19.5 8.302a5.869 5.869 0 0 1-1.65.437 2.8 2.8 0 0 0 1.263-1.536 5.87 5.87 0 0 1-1.824.674A2.915 2.915 0 0 0 15.193 7z" />
            </svg>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 24 24"
              className="footer_svg"
              fill="#1578d7"
            >
              <path d="M12 0a12 12 0 1 1 0 24 12 12 0 0 1 0-24zM8.87 5A3.85 3.85 0 0 0 5 8.82c0 .7.2 1.36.53 1.93a6.63 6.63 0 0 0 7.76 7.8 3.9 3.9 0 0 0 1.84.45 3.85 3.85 0 0 0 3.47-5.51 6.63 6.63 0 0 0-7.67-7.9c-.6-.37-1.3-.59-2.06-.59zm3.16 2.44c.6 0 1.12.07 1.56.2.44.14.81.32 1.1.55.3.22.51.46.65.72.14.25.2.5.2.75a.9.9 0 0 1-.26.63.92.92 0 0 1-.69.28.86.86 0 0 1-.58-.17 2.16 2.16 0 0 1-.4-.53 2.19 2.19 0 0 0-.6-.73c-.22-.17-.6-.25-1.1-.25-.49 0-.88.1-1.17.28-.27.18-.4.39-.4.63 0 .15.03.28.12.39.1.11.23.21.4.3.18.08.36.15.54.2l.3.08.62.14c.54.12 1.04.25 1.48.38.45.14.83.32 1.14.52.32.2.58.47.76.78.18.32.27.7.27 1.16 0 .54-.16 1.04-.47 1.47-.3.43-.77.77-1.36 1.01-.58.24-1.28.37-2.08.37-.96 0-1.77-.17-2.4-.5a3.1 3.1 0 0 1-1.1-.96c-.28-.4-.42-.8-.42-1.19 0-.24.1-.45.28-.62a.99.99 0 0 1 .7-.26.9.9 0 0 1 .58.2c.13.1.23.25.33.43l.06.14c.12.27.25.5.39.67.13.17.32.31.56.43.24.1.57.17.97.17.55 0 1-.12 1.34-.35.34-.22.5-.5.5-.82a.8.8 0 0 0-.26-.62c-.18-.17-.42-.3-.71-.4a12.6 12.6 0 0 0-.98-.24l-.25-.05a9.91 9.91 0 0 1-1.75-.52c-.48-.2-.86-.48-1.15-.83-.28-.35-.43-.8-.43-1.31 0-.5.15-.95.46-1.34.3-.38.73-.68 1.3-.88.55-.2 1.2-.3 1.95-.3z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
});

export default JoinTeam;
