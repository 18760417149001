import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faAngleRight } from "@fortawesome/free-solid-svg-icons";

export default function Recharge() {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="topbox">
        <div className="topbox_1">
          <div className="topbox_1_b">
            <div className="topbox_1_c">
              <FontAwesomeIcon icon={faSearch} />
            </div>
            <div className="topbox_1_d">
              <input
                type="text"
                id="symbol"
                className="sybolc"
                placeholder="Tìm kiếm loại tiền bạn cần"
              />
            </div>
          </div>
        </div>
        <div className="topbox_2">
          <span className="fzmmm fe6im" onClick={() => navigate(-1)}>
            Hủy bỏ
          </span>
        </div>
      </div>
      <div id="withdraw" className="pt-[50px]">
        <div className="w-full h-[30px] leading-[30px]">
          <span className="fzmmm fe6im p-[5px] border-b-[3px] border-[#00b897]">
            Có thể rút danh sách tiền tệ
          </span>
        </div>
        <div className="list-coin-deposit">
          <Link to="/member/withdraw/submit?symbol=usdt" className="item">
            <div className="left">
              <span>USDT</span>
            </div>
            <div className="center">
              <span>USDT</span>
            </div>
            <div className="right">
              <span className="fzmm fcc mr-[5px]">Array</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/member/withdraw/submit?symbol=eth" className="item">
            <div className="left">
              <span>ETH</span>
            </div>
            <div className="center">
              <span>ETH</span>
            </div>
            <div className="right">
              <span className="fzmm fcc mr-[5px]">Array</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/member/withdraw/submit?symbol=btc" className="item">
            <div className="left">
              <span>BTC</span>
            </div>
            <div className="center">
              <span>BTC</span>
            </div>
            <div className="right">
              <span className="fzmm fcc mr-[5px]">Array</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/member/withdraw/submit?symbol=usd" className="item">
            <div className="left">
              <span>USD</span>
            </div>
            <div className="center">
              <span>USD</span>
            </div>
            <div className="right">
              <span className="fzmm fcc mr-[5px]">Array</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/member/withdraw/submit?symbol=vnd" className="item">
            <div className="left">
              <span>VND</span>
            </div>
            <div className="center">
              <span>VND</span>
            </div>
            <div className="right">
              <span className="fzmm fcc mr-[5px]">Array</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/member/withdraw/submit?symbol=cny" className="item">
            <div className="left">
              <span>CNY</span>
            </div>
            <div className="center">
              <span>CNY</span>
            </div>
            <div className="right">
              <span className="fzmm fcc mr-[5px]">Array</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/member/withdraw/submit?symbol=hkd" className="item">
            <div className="left">
              <span>HKD</span>
            </div>
            <div className="center">
              <span>HKD</span>
            </div>
            <div className="right">
              <span className="fzmm fcc mr-[5px]">Array</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/member/withdraw/submit?symbol=jpy" className="item">
            <div className="left">
              <span>JPY</span>
            </div>
            <div className="center">
              <span>JPY</span>
            </div>
            <div className="right">
              <span className="fzmm fcc mr-[5px]">Array</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/member/withdraw/submit?symbol=gbp" className="item">
            <div className="left">
              <span>GBP</span>
            </div>
            <div className="center">
              <span>GBP</span>
            </div>
            <div className="right">
              <span className="fzmm fcc mr-[5px]">Array</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
          <Link to="/member/withdraw/submit?symbol=eur" className="item">
            <div className="left">
              <span>EUR</span>
            </div>
            <div className="center">
              <span>EUR</span>
            </div>
            <div className="right">
              <span className="fzmm fcc mr-[5px] mr-[5px]">Array</span>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}
