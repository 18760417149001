import React, { useMemo } from "react";
import { useStores } from "_common/hooks";
import { useParams } from "react-router-dom";
import i18next from "i18next";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";

export default function IntroductionDetail() {
  const params = useParams();
  const {
    authStore: { questions },
  } = useStores();

  const id = params?.id;

  const content = useMemo(
    () => questions?.find((obj) => obj.id === Number(id)),
    [questions, id]
  );

  return (
    <div className="container introduce-page !mt-[50px]">
      <HeaderTop title={i18next.t(content?.title)} />
      <div className="content-questions !text-[#707A8A]">
        {i18next.t(content?.content)}
      </div>
    </div>
  );
}
