import React from "react";
import { observer } from "mobx-react-lite";
import QRCode from "react-qr-code";
import { useStores } from "_common/hooks";
import { handleCopyLink } from "_common/utils/helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import LeftNav from "./LeftNav";

const TGCodePage = observer(() => {
  const {
    authStore: { user },
  } = useStores();

  return (
    <main className="css-1wr4jig">
      <main className="css-xry4yv !flex-row">
        <LeftNav />
        <div className="css-160vccy">
          <div className="css-146q23">
            <div className="css-1e6doj4">
              <div className="css-6vt7sa">th</div>
              <div className="css-1sgz1lk">
                <div className="css-ize0sl">
                  <div className="css-1124n14">than***@gmail.com</div>
                  <div className="css-1uoge8i">
                    <div className="css-180eiyx">
                      <div className="css-1ap5wc6">người sử dụngID:</div>
                      7012687
                    </div>
                  </div>
                  <div className="css-lzd0h4">
                    <div className="css-bhso1m">đã xác minh</div>
                  </div>
                </div>
                <div className="css-1ry7rnu">
                  <div className="css-9cwl6c">
                    Thời gian đăng nhập cuối cùng 2023-09-10 04:05:24
                  </div>
                  <div className="css-vurnku">IP: 172.71.215.104</div>
                </div>
              </div>
            </div>
          </div>
          <div className="css-jlbk6n">
            <div className="css-kvkii2">
              <div className="css-1p01izn">
                <div className="css-1hythwr">
                  <div className="css-1hz1mz6">
                    <div className="css-5x6ly7">
                      <div className="css-5x6ly7">
                        <span className="css-181kvgz !bg-[transparent]">
                          Chi tiết đội
                        </span>
                      </div>
                      <div className="css-10nf7hq">
                        <div className="css-4cffwv">
                          <div className="css-noqr05" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="css-vurnku">
                    <div className="css-gnqbje">
                      <div className="css-ysetcg">
                        <div className="css-ct0qa6">
                          <div className="css-1bliacb">
                            <div className="css-vurnku">
                              <div className="css-1f978ju">
                                <div className="css-ize0sl">
                                  <span className="css-1kbdyxh">
                                    Thống kê thành viên ba tập hợp:
                                  </span>
                                </div>
                                <div className="css-n5mzgu">
                                  <div className="css-off8uh">
                                    <div className="css-1t9tl2o">1</div>
                                    <div className="w-[4px]" />
                                    <div className="css-vurnku">
                                      Mọi người&nbsp;&nbsp;đã xác minh
                                    </div>
                                  </div>
                                </div>
                                <div className="css-n5mzgu">
                                  <div className="css-off8uh">
                                    <div className="css-1t9tl2o">3</div>
                                    <div className="w-[4px]" />
                                    <div className="css-vurnku">
                                      Mọi người&nbsp;&nbsp;không công nhận,
                                      không chứng nhận
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="css-1bliacb">
                            <div className="css-vurnku">
                              <div className="css-1f978ju">
                                <div className="css-ize0sl">
                                  <span className="css-1kbdyxh">
                                    Thành viên một -Generation:
                                  </span>
                                </div>
                                <div className="css-n5mzgu">
                                  <div className="css-off8uh">
                                    <div className="css-1t9tl2o">1</div>
                                    <div className="w-[4px]" />
                                    <div className="css-vurnku">
                                      Mọi người&nbsp;&nbsp;đã xác minh
                                    </div>
                                  </div>
                                </div>
                                <div className="css-n5mzgu">
                                  <div className="css-off8uh">
                                    <div className="css-1t9tl2o">3</div>
                                    <div className="w-[4px]" />
                                    <div className="css-vurnku">
                                      Mọi người&nbsp;&nbsp;không công nhận,
                                      không chứng nhận
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="css-1bliacb">
                            <div className="css-vurnku">
                              <div className="css-1f978ju">
                                <div className="css-ize0sl">
                                  <span className="css-1kbdyxh">
                                    Thành viên thứ hai:
                                  </span>
                                </div>
                                <div className="css-n5mzgu">
                                  <div className="css-off8uh">
                                    <div className="css-1t9tl2o">0</div>
                                    <div className="w-[4px]" />
                                    <div className="css-vurnku">
                                      Mọi người&nbsp;&nbsp;đã xác minh
                                    </div>
                                  </div>
                                </div>
                                <div className="css-n5mzgu">
                                  <div className="css-off8uh">
                                    <div className="css-1t9tl2o">0</div>
                                    <div className="w-[4px]" />
                                    <div className="css-vurnku">
                                      Mọi người&nbsp;&nbsp;không công nhận,
                                      không chứng nhận
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="css-1bliacb">
                            <div className="css-vurnku">
                              <div className="css-1f978ju">
                                <div className="css-ize0sl">
                                  <span className="css-1kbdyxh">
                                    Thành viên ba tập hợp:
                                  </span>
                                </div>
                                <div className="css-n5mzgu">
                                  <div className="css-off8uh">
                                    <div className="css-1t9tl2o">0</div>
                                    <div className="w-[4px]" />
                                    <div className="css-vurnku">
                                      Mọi người&nbsp;&nbsp;đã xác minh
                                    </div>
                                  </div>
                                </div>
                                <div className="css-n5mzgu">
                                  <div className="css-off8uh">
                                    <div className="css-1t9tl2o">0</div>
                                    <div className="w-[4px]" />
                                    <div className="css-vurnku">
                                      Mọi người&nbsp;&nbsp;không công nhận,
                                      không chứng nhận
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="css-omwf4y">
              <div className="css-vt77s9">
                <div className="css-1hythwr">
                  <div className="css-hwv82q">
                    <div className="css-5x6ly7">
                      <div className="css-5x6ly7">
                        <span className="css-181kvgz">Mã giới thiệu</span>
                      </div>
                      <div className="css-10nf7hq">
                        <span className="css-1s6nhe2">
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            className="css-155meta"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="css-65w75">
                    <div className="LinesEllipsis p-[20px]">
                      {user.link_ref && (
                        <QRCode
                          size={256}
                          viewBox="0 0 256 256"
                          className="w-[100px] h-[100px]"
                          value={user.link_ref}
                        />
                      )}
                    </div>
                    <div className="LinesEllipsis">
                      Mã lời mời của tôi：{user?.ref_no}
                      <wbr />
                    </div>
                    <div
                      className="css-1joqi3u !text-[#00b897]"
                      onClick={() => handleCopyLink(user?.link_ref)}
                    >
                      Sao chép liên kết lời mời
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="css-ei3nni">
              <div className="css-1p01izn">
                <div className="css-1hythwr">
                  <div className="css-hwv82q">
                    <div className="css-5x6ly7">
                      <div className="css-5x6ly7">
                        <span className="css-181kvgz">Đăng nhập nhật ký</span>
                      </div>
                      <div className="css-10nf7hq" />
                    </div>
                  </div>
                  <div className="css-1dcd6pv">
                    <div className="css-1h690ep">
                      <div className="css-phax11">
                        <div className="css-9b6x94">Đăng nhập</div>
                      </div>
                      <div className="css-phax11">
                        <div className="css-9b6x94">Đăng nhập IP</div>
                      </div>
                      <div className="css-phax11">
                        <div className="css-9b6x94">Thời gian đăng nhập</div>
                      </div>
                    </div>
                    <div className="css-1pysja1">
                      <div className="css-kc3i4p">
                        <div className="css-phax11">
                          <div className="css-1g02g2m">Đăng nhập email</div>
                        </div>
                        <div className="css-phax11">
                          <div className="css-4cffwv">
                            <div className="css-cvky42">172.71.215.104</div>
                          </div>
                        </div>
                        <div className="css-phax11">
                          <div className="css-1g02g2m">2023-09-10 04:05:24</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </main>
  );
});

export default TGCodePage;
