import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { useStores } from "_common/hooks";

import img from "assets/img/icon/629a22523fe41.png";

const IssuePage = observer(() => {
  const {
    authStore: { ieo },
  } = useStores();

  return (
    <main className="css-1wr4jig !bg-white">
      <div className="css-1odg5z2">
        <div className="css-1xrgo9z !mt-[30px] !ml-[-55%]">
          <div>
            <p className="text-[40px]">IEO Launchpad</p>
            <p className="text-[18px]">Ưu đãi đặc biệt Snapshot</p>
          </div>
        </div>
      </div>
      <div className="css-uliqdc !h-[102px] !bg-[#f5f5f5]">
        <div
          style={{
            height: "102px",
            background:
              "linear-gradient(180deg,rgba(57,194,139,.1),hsla(0,0%,100%,0)),#fff",
            width: "70%",
            margin: "0px auto",
            marginTop: "-51px",
            zIndex: "99",
            borderRadius: "10px",
          }}
        >
          <div className="m-[22px]">
            <p className="fch !text-[18px] !m-[0] !mb-[10px]">IEO Launchpad</p>
            <p className="text-[16px] text-[#97a1a9] m-[0]">
              Cung cấp cho người dùng cơ hội đầu tư cho các dự án thu thập thấp
            </p>
          </div>
        </div>
      </div>
      <div className="css-uliqdc">
        <div className="min-h-[500px] bg-[#f5f5f5] px-[15%]">
          <div className="h-[50px]">
            <p className="text-[18px] text-[#151617] font-[700] leading-[24px] mb-[0]">
              SNAP -UP Khu vực
            </p>
          </div>
          <div
            className="w-full h-[290px] bg-white rounded-[5px] mb-[20px]"
            style={{ boxShadow: "0 2px 10px 0 rgb(0 0 0 / 10%)" }}
          >
            <div className="w-full h-[240px]">
              <div className="w-[50%] h-[240px] p-[5px] float-left">
                <div className="w-full h-[240px] bg-white">
                  <div className="w-full h-[230px]">
                    <div className="w-[25%] h-[230px] leading-[150px] text-center float-left">
                      <img src={img} alt="" className="w-[60%] inline" />
                    </div>
                    <div className="py-[10px]">
                      <div className="w-full min-h-[30px]">
                        <span className="f16 fch">Subscribe</span>
                      </div>
                      <div className="w-full min-h-[30px]">
                        <span className="f14 fcc">Loại：USDZ</span>
                      </div>
                      <div className="w-full min-h-[30px]">
                        <span className="f14 fcc">
                          Thời gian bắt đầu：2022-03-24 11:02:32
                        </span>
                      </div>
                      <div className="w-full min-h-[30px]">
                        <span className="f14 fcc">
                          Thời gian kết thúc：2023-01-18 11:02:32
                        </span>
                      </div>
                      <div className="w-full min-h-[30px]">
                        <span className="f14 fcc">
                          Tổng phân phối：99999999.000000 USDZ
                        </span>
                      </div>
                      <div className="w-full min-h-[30px]">
                        <span className="f14 fcc">
                          Số lượng tham gia：15000000.000000 USDZ
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[50%] h-[240px] p-[5px] float-left">
                <div className="w-full h-[240px]">
                  <div className="w-full h-[240px] py-[10px] pl-[10px]">
                    <div className="w-full min-h-[30px]">
                      <span className="f16 fch">Hướng dẫn dự án</span>
                    </div>
                    <div className="w-full min-h-[30px]">
                      <span className="f14 fcc">
                        Đăng ký giới hạn trên ：9999999.000000
                      </span>
                    </div>
                    <div className="w-full min-h-[30px]">
                      <span className="f14 fcc">
                        Tối thiểu ：15000000.000000
                      </span>
                    </div>
                    <div className="w-full min-h-[30px]">
                      <span className="f14 fcc">
                        Đăng ký giới hạn trên ：10000.000000
                      </span>
                    </div>
                    <div
                      className="w-full min-h-[30px]"
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <span className="f14 fcc">
                        <h2 className="text-[32px] text-[#74777A] bg-white">
                          Industry background
                        </h2>
                      </span>
                      <span className="f14 fcc">
                        The birth of the blockchain marked the beginning of the
                        construction of a truly trustworthy Internet. By combing
                        through the rise and development of the blockchain, it
                        can be found that the focus of the blockchain is that it
                        can establish a reliable peerto-peer trust in the
                        network, so that the value transfer process removes the
                        interference of the intermediary, which not only
                        discloses information but also protects privacy , Both
                        making joint decision-making and protecting individual
                        rights and interests. This mechanism improves the
                        efficiency of value interaction and reduces costs. From
                        an economic point of view, the new value interaction
                        paradigm created by the blockchain is based on
                        &ldquo;&ldquo;weak centralization&ldquo;&ldquo;, but
                        this does not mean the complete disappearance of various
                        &ldquo;&ldquo;centers&ldquo;&ldquo; in the traditional
                        society, and a large number of blockchains will appear
                        in the future. The “multi-center” system is based on
                        alliance chains, private chains or hybrid chains.
                        Blockchain will further improve the operating efficiency
                        of the “center” and reduce a considerable part of its
                        cost. From a technical point of view, we believe that
                        blockchain is a technology system that is jointly
                        maintained by multiple parties, stores data in a
                        blockchain structure, uses cryptography to ensure
                        transmission and access security, and can achieve
                        consistent data storage, non-tampering, and
                        non-repudiation. This technology has brought unlimited
                        space for imagination to the world. The global interest
                        in blockchain continues to heat up. Major global
                        economies have begun to study blockchain technology and
                        development trends from the national strategic level.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-[50px] mt-[10px] px-[20px]">
              <div className="w-[80%] h-[40px] float-left">
                <div className="progress">
                  <div
                    className="progress-bar"
                    style={{ width: "72.256609104534%" }}
                  >
                    72.256609104534%
                  </div>
                </div>
              </div>
              <div className="w-[20%] h-[40px] float-left">
                <div className="w-full h-[40px] text-center">
                  <Link
                    to="/issue/detail/3"
                    className="css-bhso1m !text-white !py-[5px] !px-[15px]"
                  >
                    Tham gia ngay lập tức
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
});

export default IssuePage;
