import React from "react";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import HistoryData from "./GameBoard/HistoryData";

export default function HistoryTradePage() {
  return (
    <div className="container page-history pt-[50px]">
      <HeaderTop title="Hồ sơ hợp đồng" />
      <HistoryData />
    </div>
  );
}
