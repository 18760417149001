import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import HeaderTop from "_common/component/Layout/Header/HeaderTop";
import { useStores } from "_common/hooks";
import { Link } from "react-router-dom";

const StackingDesktop = observer(() => {
  const {
    authStore: { ieo },
  } = useStores();
  const { isMobile } = useMediaQuery();

  return (
    <>
      <HeaderTop title="Launchpad" theme="light" />
      <div className="ieo-page">
        <div className="oreimgbox">
          <div className="top-title-a">
            <div className="top-title-a-nfo">
              <p className="f18 fcf top-title-msg">Launchpad</p>
              <p className="f30 fcf wt80b">Ưu đãi đặc biệt Snapshot</p>
            </div>
          </div>
          <div className="top-title-b">
            <p
              className="f16 fcc"
              style={{ color: "#181818", marginBottom: 0 }}
            >
              Launchpad
            </p>
            <p className="f14 fcc" style={{ color: "#444545" }}>
              Cung cấp cho người dùng cơ hội đầu tư cho các dự án thu thập thấp
            </p>
          </div>
        </div>
        <div className="issuebox" id="zlbox">
          {ieo?.map((item) => (
            <Link to="/ieo" key={item?.id}>
              <div className="orebox">
                <div style={{ width: "100%", height: 120 }}>
                  <div
                    style={{
                      width: "30%",
                      height: 120,
                      lineHeight: 120,
                      float: "left",
                      textAlign: "center",
                    }}
                  >
                    <img
                      src={item?.image_url}
                      style={{ width: "80%" }}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      width: "70%",
                      height: 120,
                      float: "left",
                      padding: 5,
                      position: "relative",
                    }}
                  >
                    <p className="fzmmm fcc fw" style={{ marginBottom: 3 }}>
                      Subscribe
                    </p>
                    <p className="fzmm fcc" style={{ marginBottom: 3 }}>
                      Loại：{item?.coin}
                    </p>
                    <p className="fzmm fcc" style={{ marginBottom: 3 }}>
                      Thời gian bắt đầu：{item?.start_date}
                    </p>
                    <p className="fzmm fcc" style={{ marginBottom: 3 }}>
                      Thời gian kết thúc：{item?.end_date}
                    </p>

                    <div className="ieo-buy-go" />
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: `${item?.count}%` }}
                      aria-valuenow={item?.count}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      {item?.count}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: 60,
                    marginTop: 15,
                    padding: "0px 15px",
                  }}
                >
                  <div className="obbox" style={{ width: "40%" }}>
                    <div className="obbox_h">
                      <span className="fzmm fcc">Tổng phân phối</span>
                    </div>
                    <div className="obbox_h">
                      <span className="fzmm fcc">{item?.supply}</span>
                    </div>
                  </div>
                  <div className="obbox" style={{ width: "30%" }}>
                    <div className="obbox_h" style={{ textAlign: "center" }}>
                      <span className="fzmm fcc">Đăng ký Đơn giá</span>
                    </div>
                    <div className="obbox_h" style={{ textAlign: "center" }}>
                      <span className="fzmm fcc">{item?.price}</span>
                    </div>
                  </div>
                  <div className="obbox" style={{ width: "30%" }}>
                    <div className="obbox_h" style={{ textAlign: "right" }}>
                      <span className="fzmm fcc">Thời gian khóa</span>
                    </div>
                    <div className="obbox_h" style={{ textAlign: "right" }}>
                      <span className="fzmm fcc">{item?.time_lock}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </>
  );
});

export default StackingDesktop;
