import React from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "_common/component/MediaQueryProvider";
import LoginMobile from "./LoginMobile";
import LoginDesktop from "./LoginDesktop";

function LoginPage() {
  const { isMobile } = useMediaQuery();
  if (isMobile) return <LoginMobile />;

  return <LoginMobile />;
}

export default LoginPage;
