/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { useMutation } from "@tanstack/react-query";
import { ProfileApi } from "states/api";
import { NotificationManager } from "react-notifications";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import i18next from "i18next";
import InputPassword from "_common/component/InputPassword";
import LeftNav from "./LeftNav";

const schema = yup
  .object({
    new_password: yup
      .string()
      .min(6, "Vui lòng nhập mật khẩu tối thiểu 6 ký tự")
      .required("Vui lòng nhập mật khẩu mới"),
    confirmed: yup
      .string()
      .required("Vui lòng nhập lại mật khẩu mới")
      .oneOf([yup.ref("new_password")], "Mật khẩu không khớp"),
  })
  .required();

const RePasswordPage = observer(() => {
  const {
    authStore: { clear },
  } = useStores();

  const { mutate, isLoading } = useMutation(
    (variables) => ProfileApi.changePassword({ params: variables }),
    {
      onSuccess: (res) => {
        clear();
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("action_success_msg")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSave = () => {
    if (isLoading) return;
    trigger();
    const formValue = getValues();
    const isValid = schema.isValidSync(formValue);
    if (isValid) {
      mutate(formValue);
    }
  };

  return (
    <main className="css-1wr4jig">
      <main className="css-xry4yv !flex-row">
        <LeftNav />
        <div className="css-1wr4jig">
          <div className="css-1u0m1fa">
            <div className="css-fhl5lc">
              <span className="css-1cdfkn6">Đổi mật khẩu</span>
            </div>
          </div>
          <div className="css-1bbywci">
            <div className="css-1pl31wt">
              <div className="css-k2y2sp">
                <div className="css-1868gi1">Sửa đổi mật khẩu đăng nhập</div>
              </div>
            </div>
            <div className="css-z32tht">
              <div className="css-joa6mv">
                <span className="f12 fcy">
                  *Đối với bảo mật tài chính của bạn, hãy rút tiền trong vòng 24
                  giờ sau khi mật khẩu đăng nhập được sửa đổi
                </span>
              </div>
              <div className="css-1op9i22">
                <form
                  className="w-[400px] min-h-[400px]"
                  onSubmit={handleSubmit(onSave)}
                >
                  <div className="css-vurnku">
                    <div className="css-154a57d">
                      <InputPassword
                        placeholder="Vui lòng nhập mật khẩu cũ"
                        className="css-16fg16t"
                      />
                      <label className="bn-input-label css-5vzups">
                        <div className="css-kiaw5d">Mật khẩu cũ</div>
                      </label>
                    </div>
                  </div>
                  <div className="w-full h-[30px]" />
                  <div className="css-vurnku">
                    <div className="css-154a57d">
                      <InputPassword
                        type="password"
                        name="password"
                        placeholder="Vui lòng nhập mật khẩu mới"
                        className="css-16fg16t"
                        {...register("new_password")}
                      />
                      <label className="bn-input-label css-5vzups">
                        <div className="css-kiaw5d">Mật khẩu mới</div>
                      </label>
                    </div>
                  </div>
                  <div className="w-full h-[30px]" />
                  <div className="css-vurnku">
                    <div className="css-154a57d">
                      <InputPassword
                        type="password"
                        name="confirm_password"
                        placeholder="Vui lòng nhập mật khẩu xác nhận"
                        className="css-16fg16t"
                        {...register("confirmed")}
                      />
                      <label className="bn-input-label css-5vzups">
                        <div className="css-kiaw5d">Xác nhận mật khẩu</div>
                      </label>
                    </div>
                  </div>
                  <div className="w-full h-[30px]" />
                  <div className="css-x5jwjg">
                    <button
                      type="submit"
                      className="css-3kuzxc !py-[6px] !px-[50px]"
                    >
                      Gửi đi
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
    </main>
  );
});

export default RePasswordPage;
