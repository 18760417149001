import React from "react";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

const ProfitListPage = observer(() => (
  <main className="css-1wr4jig">
    <main className="css-xry4yv !flex-row">
      <div className="css-foka8b">
        <Link to="/finance" className="css-6ijtmk">
          <div className="css-10j588g">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              className="css-hd27fe"
            >
              <path d="M6.093 8.889c-.567 0-1.031-.438-1.031-.972 0-.535.464-.973 1.03-.973h12.846V5H6.093C4.38 5 3 6.303 3 7.917v8.166C3 17.697 4.381 19 6.093 19H21V8.889H6.093zm12.845 8.167H6.093c-.567 0-1.031-.438-1.031-.973v-5.415c.33.107.68.165 1.03.165h12.846v6.223z" />
              <path
                d="M15.845 12.573l-1.453 1.371 1.453 1.38 1.464-1.38-1.464-1.37z"
                fill="#00b897"
              />
            </svg>
            <div className="css-1n0484q">Tổng quan về ví</div>
          </div>
        </Link>
        <Link to="/finance/deposit-history" className="css-6ijtmk">
          <div className="css-10j588g">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              className="css-hd27fe"
            >
              <g clipPath="url(#sidebar-payment-s24_svg__clip0)">
                <path d="M12.589 11.251v-1.88a2.17 2.17 0 011.15.688l.031.032 1.126-1.024-.024-.032a3.208 3.208 0 00-2.14-1.065V6.858h-1.42V7.97c-1.486.184-2.332 1.008-2.332 2.273 0 1.208.806 1.968 2.483 2.32v2.05a2.724 2.724 0 01-1.589-.873l-.032-.024-1.086.992-.032.024.032.032a3.781 3.781 0 002.555 1.249V17.1h1.421v-1.136a2.448 2.448 0 001.624-.765 2.457 2.457 0 00.668-1.668c0-1.185-.75-1.889-2.435-2.28zm.734 2.417a.938.938 0 01-.734.912v-1.76c.654.216.734.584.734.848zm-1.86-2.673c-.559-.168-.783-.392-.783-.8 0-.456.256-.728.783-.848v1.648z" />
                <path d="M11.973 5.6c1.263 0 2.497.376 3.547 1.079a6.397 6.397 0 012.352 2.872 6.413 6.413 0 01-1.384 6.974 6.38 6.38 0 01-6.958 1.387 6.388 6.388 0 01-2.866-2.357A6.41 6.41 0 015.588 12 6.414 6.414 0 017.46 7.477 6.385 6.385 0 0111.973 5.6zm0-1.6a7.97 7.97 0 00-4.435 1.348 7.996 7.996 0 00-2.94 3.59 8.017 8.017 0 001.73 8.719 7.965 7.965 0 008.699 1.734 7.985 7.985 0 003.583-2.946 8.013 8.013 0 00-.993-10.102A7.98 7.98 0 0011.973 4z" />
              </g>
              <defs>
                <clipPath id="sidebar-payment-s24_svg__clip0">
                  <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
              </defs>
            </svg>
            <div className="css-1n0484q">Lịch sử nạp tiền</div>
          </div>
        </Link>
        <Link to="/finance/withdraw-history" className="css-6ijtmk">
          <div className="css-10j588g">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              className="css-hd27fe"
            >
              <g clipPath="url(#sidebar-payment-s24_svg__clip0)">
                <path d="M12.589 11.251v-1.88a2.17 2.17 0 011.15.688l.031.032 1.126-1.024-.024-.032a3.208 3.208 0 00-2.14-1.065V6.858h-1.42V7.97c-1.486.184-2.332 1.008-2.332 2.273 0 1.208.806 1.968 2.483 2.32v2.05a2.724 2.724 0 01-1.589-.873l-.032-.024-1.086.992-.032.024.032.032a3.781 3.781 0 002.555 1.249V17.1h1.421v-1.136a2.448 2.448 0 001.624-.765 2.457 2.457 0 00.668-1.668c0-1.185-.75-1.889-2.435-2.28zm.734 2.417a.938.938 0 01-.734.912v-1.76c.654.216.734.584.734.848zm-1.86-2.673c-.559-.168-.783-.392-.783-.8 0-.456.256-.728.783-.848v1.648z" />
                <path d="M11.973 5.6c1.263 0 2.497.376 3.547 1.079a6.397 6.397 0 012.352 2.872 6.413 6.413 0 01-1.384 6.974 6.38 6.38 0 01-6.958 1.387 6.388 6.388 0 01-2.866-2.357A6.41 6.41 0 015.588 12 6.414 6.414 0 017.46 7.477 6.385 6.385 0 0111.973 5.6zm0-1.6a7.97 7.97 0 00-4.435 1.348 7.996 7.996 0 00-2.94 3.59 8.017 8.017 0 001.73 8.719 7.965 7.965 0 008.699 1.734 7.985 7.985 0 003.583-2.946 8.013 8.013 0 00-.993-10.102A7.98 7.98 0 0011.973 4z" />
              </g>
              <defs>
                <clipPath id="sidebar-payment-s24_svg__clip0">
                  <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
              </defs>
            </svg>
            <div className="css-1n0484q">Lịch sử rút tiền</div>
          </div>
        </Link>
        <Link to="/orepool/profit-list" className="css-z87e9z">
          <div className="css-10j588g">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              className="css-14thuu2"
            >
              <g clipPath="url(#sidebar-payment-s24_svg__clip0)">
                <path d="M12.589 11.251v-1.88a2.17 2.17 0 011.15.688l.031.032 1.126-1.024-.024-.032a3.208 3.208 0 00-2.14-1.065V6.858h-1.42V7.97c-1.486.184-2.332 1.008-2.332 2.273 0 1.208.806 1.968 2.483 2.32v2.05a2.724 2.724 0 01-1.589-.873l-.032-.024-1.086.992-.032.024.032.032a3.781 3.781 0 002.555 1.249V17.1h1.421v-1.136a2.448 2.448 0 001.624-.765 2.457 2.457 0 00.668-1.668c0-1.185-.75-1.889-2.435-2.28zm.734 2.417a.938.938 0 01-.734.912v-1.76c.654.216.734.584.734.848zm-1.86-2.673c-.559-.168-.783-.392-.783-.8 0-.456.256-.728.783-.848v1.648z" />
                <path d="M11.973 5.6c1.263 0 2.497.376 3.547 1.079a6.397 6.397 0 012.352 2.872 6.413 6.413 0 01-1.384 6.974 6.38 6.38 0 01-6.958 1.387 6.388 6.388 0 01-2.866-2.357A6.41 6.41 0 015.588 12 6.414 6.414 0 017.46 7.477 6.385 6.385 0 0111.973 5.6zm0-1.6a7.97 7.97 0 00-4.435 1.348 7.996 7.996 0 00-2.94 3.59 8.017 8.017 0 001.73 8.719 7.965 7.965 0 008.699 1.734 7.985 7.985 0 003.583-2.946 8.013 8.013 0 00-.993-10.102A7.98 7.98 0 0011.973 4z" />
              </g>
              <defs>
                <clipPath id="sidebar-payment-s24_svg__clip0">
                  <path fill="#fff" d="M0 0h24v24H0z" />
                </clipPath>
              </defs>
            </svg>
            <div className="css-iizq59">Thu nhập máy khai thác</div>
          </div>
        </Link>
      </div>
      <div className="css-1wr4jig">
        <div className="css-1s52m11">
          <div className="css-1lu4p3l">
            <div className="css-b80wxf">
              <div className="css-1s8q8od">
                <div className="css-u95vxr">Hồ sơ thu nhập máy khai thác</div>
              </div>
              <div className="css-1k0kvxy" />
            </div>
            <div className="css-11ag26e" />
            <div className="css-9cxewd">
              <div className="css-1inwbdu">
                <span className="css-9wsvyu">Hồ sơ thu nhập máy khai thác</span>
              </div>
              <div className="css-1ammkwy">
                <div className="coinbox">
                  <div className="cointitle">
                    <div className="titleop !w-[40%]">
                      <span className="f14 fch">Tên máy khai thác</span>
                    </div>
                    <div className="titleop !w-[15%]">
                      <span className="f14 fch">Loại</span>
                    </div>
                    <div className="titleop !w-[15%]">
                      <span className="f14 fch">Khoản thu nhập</span>
                    </div>
                    <div className="titleop !w-[15%]">
                      <span className="f14 fch">Ngày thu nhập</span>
                    </div>
                    <div className="titleop !w-[15%]">
                      <span className="f14 fch">Phát hành trạng thái</span>
                    </div>
                  </div>
                  <div className="coincontent">
                    <div className="contentop !w-[40%]">
                      <span className="f14 fch">ETH mining mgytf</span>
                    </div>
                    <div className="contentop !w-[15%]">
                      <span className="f14 fch">USDT</span>
                    </div>
                    <div className="contentop !w-[15%]">
                      <span className="f14 fch">10.000000</span>
                    </div>
                    <div className="contentop !w-[15%]">
                      <span className="f14 fch fw">2023-08-27 23:30:01</span>
                    </div>
                    <div className="contentop !w-[15%]">
                      <span className="f14 fgreen fw">Phóng thích</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </main>
));

export default ProfitListPage;
