import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "_common/hooks";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { PriceApi } from "states/api/price";
import { useQuery } from "@tanstack/react-query";
import { formatNumber } from "_common/utils/formatValue";
import classNames from "classnames";
import i18next from "i18next";

import iconLanguage from "assets/img/icon/lanuage.png";
import iconLTop from "assets/img/icon/iconltop.png";
import iconSpot from "assets/icons/icon_spot.svg";
import iconSpeaker from "assets/icons/icon_speaker.svg";
import iconNotice from "assets/icons/icon_notice.svg";
import iconNav1 from "assets/img/icon/icon-1.png";
import iconNav2 from "assets/img/icon/icon-2.png";
import iconNav3 from "assets/img/icon/icon-3.png";
import iconNav4 from "assets/img/icon/icon-4.png";
import iconNav5 from "assets/img/icon/icon-5.png";

const MobileHome = observer(() => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const {
    authStore: { user, banners, top_coin, games, general, authenticated },
  } = useStores();

  const { data, refetch } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
          ...params,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const coinTopPrices = useMemo(
    () =>
      top_coin.map((item) => {
        const price = data?.find((obj) => obj?.symbol === item?.code);

        return { ...item, price };
      }),
    [top_coin, data]
  );

  const coinListPrice = useMemo(
    () =>
      games.map((item) => {
        const price = data?.find(
          (obj) => obj?.symbol?.toLowerCase() === item?.code
        );

        return { ...item, price };
      }),
    [games, data]
  );

  return (
    <div className="home-page">
      <div className="no_headers header_box flex items-center">
        <div className="w-[50%] pl-[1rem]">
          {authenticated ? (
            <Link
              to="/member"
              className="flex items-center gap-2 h-[50px] leading-[50px]"
            >
              <svg
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
              >
                <path
                  d="M513.023306 401.066445m-149.158135 0a149.158135 149.158135 0 1 0 298.31627 0 149.158135 149.158135 0 1 0-298.31627 0Z"
                  fill="#2acc8e"
                />
                <path
                  d="M513.023306 65.459874c-247.133563 0-447.474404 200.340841-447.474404 447.474405S265.889743 960.408683 513.023306 960.408683s447.474404-200.340841 447.474405-447.474404S760.155846 65.459874 513.023306 65.459874z m272.202519 754.331188c4.88424-20.698414 7.468089-42.284035 7.468089-64.474431 0-100.883665-53.416584-189.292204-133.508714-238.484579-34.151821 43.062771-86.931908 70.681806-146.161894 70.681806s-112.010073-27.619034-146.162917-70.680783C286.767236 566.024427 233.351676 654.432966 233.351676 755.316631c0 22.189372 2.583848 43.776016 7.468088 64.474431-84.643796-75.140351-137.981584-184.76919-137.981584-306.85576 0-226.538503 183.6456-410.185126 410.185126-410.185127S923.207409 286.395776 923.207409 512.934279c0 122.086569-53.336766 231.716432-137.981584 306.856783z"
                  fill="#2acc8e"
                />
              </svg>
              <span className=" font-bold text-[16px] text-gray-500">
                {user?.username}
              </span>
            </Link>
          ) : (
            <>
              <Link
                to="/auth/login"
                className="h-[50px] leading-[50px] font-bold text-[16px] text-gray-500"
              >
                Đăng nhập {` `}/{` `}
              </Link>
              <Link
                to="/auth/register"
                className="h-[50px] leading-[50px] font-bold text-[16px] text-gray-500"
              >
                Đăng ký
              </Link>
            </>
          )}
        </div>
        <div className="w-[50%] pr-[1rem]">
          <Link
            to="/member/language"
            className="h-[50px] leading-[50px] flex flex-col justify-center items-end"
          >
            <img src={iconLanguage} alt="" width="25" height="25" />
          </Link>
        </div>
      </div>
      <div className="flex">
        <Swiper
          spaceBetween={30}
          centeredSlides
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
          className="swiper2 w-[50%] rounded-[20px]"
        >
          {banners.map((item) => (
            <SwiperSlide key={item?.id}>
              {item.type === "image" && (
                <a href="/">
                  <img alt="" src={item?.image_url} height="287" />
                </a>
              )}
              {item.type === "video" && (
                <iframe
                  width="430"
                  height="287"
                  src={item?.src}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  title="video"
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="w-[50%] pl-[7px]">
          <div className="h-[200px] bg-[#1b1d2a] rounded-[20px]">
            <img src={iconLTop} alt="" className="w-full h-full" />
          </div>
          <div
            className="h-[80px] text-white bg-[#1b1d2a] p-[1rem] rounded-[20px] mt-[7px] flex items-center"
            onClick={() => navigate("/board/spot")}
          >
            <div className="w-[66%] fl shuffl-title-rbottom-l">
              <p className="shuffl-title-rbottom-p fcf">Tôi muốn mua tiền xu</p>
              <p className="shuffl-title-rbottom-p fcf">Visa,Mastercard</p>
            </div>
            <div className="w-[33%] fl shuffl-title-rbottom-r">
              <img src={iconSpot} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="h-[90px] bg-[#1b1d2a] rounded-[20px] mt-[1rem] py-[5px] px-[8px] text-white">
        <div className="w-full h-[80px] flex">
          <Link to="/member/recharge" className="w-[20%] h-[80px]">
            <div className="oplist text-center text-center">
              <div className="opimg leading-[50px]">
                <img src={iconNav1} alt="" className="w-[35px] inline-block" />
              </div>
              <div className="optext">
                <span className="fzmm fcf">Nạp tiền</span>
              </div>
            </div>
          </Link>
          <Link to="/member/withdraw" className="w-[20%] h-[80px]">
            <div className="oplist text-center">
              <div className="opimg leading-[50px]">
                <img src={iconNav2} alt="" className="w-[35px] inline-block" />
              </div>
              <div className="optext">
                <span className="fzmm fcf">Rút</span>
              </div>
            </div>
          </Link>
          <Link to="/ieo" className="w-[20%] h-[80px]">
            <div className="oplist text-center">
              <div className="opimg leading-[50px]">
                <img src={iconNav3} alt="" className="w-[35px] inline-block" />
              </div>
              <div className="optext">
                <span className="fzmm fcf">IEO</span>
              </div>
            </div>
          </Link>
          <Link to="/staking" className="w-[20%] h-[80px]">
            <div className="oplist text-center">
              <div className="opimg leading-[50px]">
                <img src={iconNav4} alt="" className="w-[35px] inline-block" />
              </div>
              <div className="optext">
                <span className="fzmm fcf">Staking</span>
              </div>
            </div>
          </Link>
          <Link to={general?.link_support} className="w-[20%] h-[80px]">
            <div className="oplist text-center">
              <div className="opimg leading-[50px]">
                <img src={iconNav5} alt="" className="w-[35px] inline-block" />
              </div>
              <div className="optext">
                <span className="fzmm fcf">CSKH</span>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="h-[150px] bg-[#1b1d2a] rounded-[20px] mt-[1rem]">
        <div className="top-coin">
          {coinTopPrices.map((item) => (
            <Link key={item?.code} to="/" className="item">
              <h5>
                <b>{item?.name?.split("/")?.[0]}</b>/
                <span>{item?.name?.split("/")?.[1]}</span>
              </h5>
              <h4
                className={classNames("price", {
                  "text-green": item?.price?.priceChangePercent > 0,
                  "text-red": item?.price?.priceChangePercent <= 0,
                })}
              >
                {formatNumber(item?.price?.lastPrice, "0,0.[00000]")}
              </h4>
              <p
                className={classNames({
                  "text-green": item?.price?.priceChangePercent > 0,
                  "text-red": item?.price?.priceChangePercent <= 0,
                })}
              >
                {formatNumber(item?.price?.priceChangePercent, "0,0.[00]")}%
              </p>
            </Link>
          ))}
        </div>
        <div className="h-[1px] bg-[#272936] mx-[7%]" />
        <div className="notice">
          <div className="left">
            <img src={iconSpeaker} alt="" />
            <span>{i18next.t("10_langs_msg")}</span>
            <Link to="/member/introduction">
              <img src={iconNotice} alt="" />
            </Link>
          </div>
        </div>
      </div>
      <div className="coin-list pb-[1rem] bg-[#1b1d29] rounded-[12px] mt-[1rem]">
        <div className="list">
          <div className="item header !static !transform-none">
            <div className="col1 text-[#707A8A] !items-center">Giao dịch</div>
            <div className="col2 text-[#707A8A]">
              {i18next.t("latest_price")}
            </div>
            <div className="col3 text-[#707A8A]">24H Số lượng</div>
          </div>
          <div className="emptybox" />
          {coinListPrice?.map((item) => (
            <Link
              className="item"
              to={`/board/spot?symbol=${item?.code}`}
              key={item?.id}
            >
              <div className="col1 !flex-row">
                <img
                  src={item?.image_url}
                  alt=""
                  width="20"
                  height="20"
                  className="mr-[10px]"
                />
                <p className="name">
                  <b>{item?.name?.split("/")?.[0]}</b>
                  <span>/{item?.name?.split("/")?.[1]}</span>
                </p>
              </div>
              <div className="col2">
                {formatNumber(item?.price?.lastPrice, "0,0.[00000]")}
              </div>
              <div className="col3">
                <span
                  className={classNames("tag ", {
                    green: item?.price?.priceChangePercent > 0,
                    red: item?.price?.priceChangePercent <= 0,
                  })}
                >
                  {formatNumber(item?.price?.priceChangePercent, "0,0.[00]")}%
                </span>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
});

export default MobileHome;
