import React, { useEffect, useMemo } from "react";
import { useStores } from "_common/hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NotificationManager } from "react-notifications";
import { PaymentApi } from "states/api/payment";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { formatBalance, formatNumber } from "_common/utils/formatValue";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLongArrowAltLeft,
  faChevronRight,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";
import InputPassword from "_common/component/InputPassword";
import classNames from "classnames";

const schema = yup
  .object({
    address: yup.string().required(i18next.t("verify_require")),
    amount: yup.number().required(i18next.t("verify_require")),
    wallet_password: yup.string().required(i18next.t("verify_require")),
  })
  .required();

export default function WithdrawSubmit() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const symbol = params.get("symbol");

  const {
    authStore: { user, my_bank, general, coins },
  } = useStores();

  const {
    register,
    trigger,
    handleSubmit,
    formState: { errors, isValid, isSubmitted },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const mainCoin = useMemo(
    () => coins?.find((obj) => obj?.symbol === symbol),
    [coins, symbol]
  );

  const mainBalance = useMemo(
    () => user?.tokens?.find((obj) => obj?.symbol === symbol),
    [user, symbol]
  );

  const { mutate: onSubmit, isLoading } = useMutation(
    (variables) =>
      PaymentApi.withdraw({
        params: {
          ...variables,
          symbol,
          type: "withdraw",
        },
      }),
    {
      onSuccess: (res) => {
        reset();
        queryClient.invalidateQueries(["get_profile"]);
        queryClient.invalidateQueries(["payment", "list"]);
        NotificationManager.success(
          i18next.t(res?.msg) || i18next.t("withdrawal_successful")
        );
      },
      onError: (error) => {
        const errorMessage =
          i18next.t(error?.message) ?? i18next.t("action_failed_msg");
        NotificationManager.error(errorMessage);
      },
    }
  );

  const onSave = (values) => {
    if (isLoading) return null;
    const amount = values?.amount;

    if (amount < Number(general?.min_withdraw || 0))
      return NotificationManager.error(
        `${i18next.t("enter_large_amount_msg")} ${formatNumber(
          general?.min_withdraw
        )}`
      );
    if (amount > Number(general?.max_withdraw || 0))
      return NotificationManager.error(
        `${i18next.t("enter_smaller_amount_msg")} ${formatNumber(
          general?.max_withdraw
        )}`
      );
    onSubmit({ ...values });
    return null;
  };

  useEffect(() => {
    setValue("address", my_bank?.bank_number);
  }, [my_bank]);

  return (
    <div className="container">
      <div className="topbox flex justify-between items-center">
        <span className="btn_back" onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faLongArrowAltLeft} color="#707A8A" />
        </span>
        <div className="fr allhg txtr w-[10%] leading-[40px]">
          <svg
            viewBox="0 0 1024 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            className="inline-block"
          >
            <path
              d="M914.9 158.4H183.5c-15.2 0-27.6-12.4-27.6-27.6v-4.1c0-15.2 12.4-27.6 27.6-27.6h731.4c15.2 0 27.6 12.4 27.6 27.6v4.1c0.1 15.2-12.3 27.6-27.6 27.6zM914.9 819.9H183.5c-15.2 0-27.6-12.4-27.6-27.6v-4.1c0-15.2 12.4-27.6 27.6-27.6h731.4c15.2 0 27.6 12.4 27.6 27.6v4.1c0.1 15.2-12.3 27.6-27.6 27.6zM574.7 489.2H176.6c-11.4 0-20.7-9.3-20.7-20.7v-18.1c0-11.4 9.3-20.7 20.7-20.7h398.1c11.4 0 20.7 9.3 20.7 20.7v18.1c0 11.4-9.3 20.7-20.7 20.7z"
              fill="#00b897"
            />
          </svg>
        </div>
      </div>
      <div id="withdraw" className="pt-[50px] px-[15px]">
        <div className="contentbox_top">
          <span className="fe6im text-[18px] text-[500]">Rút</span>
        </div>
        <div className="contentbox_line">
          <div className="line_1">
            <span className="fzmmm fe6im">Cặp tiền</span>
          </div>
          <div className="line_2" onClick={() => navigate(-1)}>
            <b className="text-[18px] text-[#00b897] text-uppercase">
              {symbol}
            </b>
            <span className="fzmmm fe6im mr-[5px]">
              &nbsp;&nbsp;{mainCoin?.name}
            </span>
            <FontAwesomeIcon icon={faChevronRight} color="#707A8A" />
          </div>
        </div>
        <form className="imgbox" onSubmit={handleSubmit(onSave)}>
          <div className="imgbox_4 h-[370px] !border-[0] !mt-[0]">
            <div className="imgbox_1 mt-[10px]">
              <span className="fzmmm fe6im">Số tài khoản</span>
            </div>
            <div className="tx_inputbox">
              <div className="tx_inputbox_l">
                <input
                  type="text"
                  className="w-full h-[30px] bg-[#e6e6e6] border-[1px] border-[#e6e6e6]"
                  placeholder="Nhập hoặc nhấn dài địa chỉ dán"
                  {...register("address")}
                />
              </div>
              <div className="tx_inputbox_r">
                <FontAwesomeIcon
                  icon={faClipboardList}
                  width="22"
                  color="#444545"
                />
              </div>
            </div>
            <div className="imgbox_1 mt-[10px] flex justify-between">
              <div className="h-[40px] leading-[40px]">
                <span className="fzmmm fe6im">Số tiền rút</span>
              </div>
              <div className="h-[40px] leading-[40px] text-right">
                <span className="fzm fcc">Có sẵn&nbsp;&nbsp;</span>
                <span className="fzm fcc">
                  {formatBalance(mainBalance?.amount)}&nbsp;&nbsp;
                </span>
                <span className="fzm fcc">{mainCoin?.name}</span>
              </div>
            </div>
            <div className="tx_inputbox">
              <div className="tx_inputbox_l !w-[65%]">
                <input
                  type="number"
                  inputMode="decimal"
                  min={0}
                  max={1000000000000}
                  autoComplete="off"
                  className="w-full h-[30px] bg-[#e6e6e6] border-[1px] border-[#e6e6e6]"
                  placeholder={i18next.t("coins_withdraw_msg")}
                  {...register("amount")}
                />
              </div>
              <div className="tx_inputbox_r !w-[35%]">
                <div className="inputbox_r_l">
                  <span className="fzmmm fcc !text-[14px]">
                    {mainCoin?.name}
                  </span>
                </div>
                <div className="inputbox_r_r">
                  <span
                    className={classNames("fzmmm fcc !text-[14px]", {
                      "!text-[#000000]": mainBalance?.amount > 0,
                    })}
                    onClick={() =>
                      setValue("amount", Number(mainBalance?.amount))
                    }
                  >
                    Toàn bộ
                  </span>
                </div>
              </div>
            </div>
            <div className="imgbox_1 mt-[10px]">
              <span className="fzmmm fe6im">Mật khẩu rút tiền</span>
            </div>
            <div className="tx_inputbox">
              <div className="tx_inputbox_l !w-full">
                <InputPassword
                  type="password"
                  placeholder="Mật khẩu rút tiền"
                  className="w-full h-[30px] text-[#707a8a] bg-[#e6e6e6] border-[1px] border-[#e6e6e6]"
                  {...register("wallet_password")}
                />
              </div>
            </div>
            <div className="imgbox_8 !h-[unset] mt-[20px]">
              <div className="imgbox_10 !bg-[#00b897]" id="sumbtn">
                <button
                  type="submit"
                  className="fzmm fe6im"
                  disabled={!isValid}
                >
                  Gửi đi
                </button>
              </div>
            </div>
            <div className="imgbox_1 h-[40px] flex justify-between items-center">
              <div className="w-[50%] h-[40px] leading-[40px]">
                <span className="fzm fcc">Tài khoản thực tế</span>
              </div>
              <div className="w-[50%] h-[40px] leading-[40px] text-right">
                <span className="fzm fcc">0</span>
                <span className="fzm fcc">&nbsp;&nbsp;{mainCoin?.name}</span>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
