import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { useStores } from "_common/hooks";
import { useQuery } from "@tanstack/react-query";
import { PriceApi } from "states/api/price";
import { useNotify } from "_common/component/NotifyProvider";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons";
import HeaderDesktop from "_common/component/Layout/Header/HeaderDesktop";

import imgEmpty from "assets/img/empty-dark.png";

import GameChart from "./GameChart";
import GameBoard from "./GameBoard";
import GameHeader from "./GameHeader";
import HistoryData from "./GameBoard/HistoryData";

const gameTypeList = [
  {
    time: 60,
    profit: 10,
    text: "1Phút",
  },
  {
    time: 120,
    profit: 20,
    text: "2Phút",
  },
  {
    time: 180,
    profit: 30,
    text: "3Phút",
  },
  {
    time: 240,
    profit: 50,
    text: "4Phút",
  },
  {
    time: 300,
    profit: 80,
    text: "5Phút",
  },
  {
    time: 360,
    profit: 100,
    text: "6Phút",
  },
];

const ContractDesktop = observer(() => {
  const {
    authStore: { games, user },
  } = useStores();

  const navigate = useNavigate();
  const { updatePrice } = useNotify();

  const [params] = useSearchParams();
  const symbol = params?.get("symbol") || "btcusdt";
  const time = params?.get("time") || "1m";
  const theme = params?.get("theme") || "dark";

  const [game_id, setGameId] = useState(games[0]?.code);
  const [isLight, setIsLight] = useState(false);
  const [amount, setAmount] = useState();
  const [option, setOption] = useState();
  const [activeTab, setActiveTab] = useState(1);

  const { data: dataPrice, refetch: getPrice } = useQuery(
    ["prices", "list"],
    () =>
      PriceApi.getList({
        params: {
          page: 1,
          limit: 10,
        },
      }),
    {
      staleTime: 300000,
    }
  );

  const priceBtc = useMemo(
    () => dataPrice?.find((obj) => obj?.symbol === game_id?.toUpperCase()),
    [dataPrice, game_id]
  );

  useEffect(() => {
    if (!theme) return;
    setIsLight(theme === "light");
  }, [theme]);

  useEffect(() => {
    const interval = setInterval(() => {
      getPrice();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!priceBtc?.lastPrice) return;
    updatePrice({ user_id: user?.id, lastPrice: priceBtc?.lastPrice });
  }, [priceBtc?.lastPrice, user]);

  useEffect(() => {
    setGameId(symbol);
  }, [symbol]);

  return (
    <div className="css-1wr4jig !bg-[#151617] !border-t-[1px] !border-[#151617]">
      <div className="w-full flex">
        <div className="w-[20%] min-h-[650px] pr-[10px] float-left">
          <div className="w-full min-h-[680px] bg-[#1a1b1c]">
            <div className="w-full h-full px-[10px]">
              <div className="w-full">
                <div className="dong_sel_box">
                  <span className="fcgs dong_sel_span">USDT</span>
                </div>
              </div>
              <div className="symbol_list">
                <div className="usdt-shadow">
                  <div className="sy_list_box">
                    <div className="sy_list_boxl">
                      <span className="f14 fccs">Cặp tiền</span>
                    </div>
                    <div className="sy_list_boxl fccs f14 tcc !text-center">
                      Số lượng 24H
                    </div>
                    <div className="sy_list_boxr fccs f14">Tăng</div>
                  </div>
                </div>
                <div className="usdt-shadow">
                  <Link to="/trade?coin=btc">
                    <div className="sy_list_box">
                      <div className="sy_list_boxl">
                        <span className="f12 fcf">BTC/USDT</span>
                      </div>
                      <div className="sy_list_boxl f12 tcc !text-center">
                        <span className="fzmm red">25847.25</span>
                      </div>
                      <div className="sy_list_boxr f12">
                        <span className="fzmm red">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 320 512"
                            fill="#ff0000"
                            className="inline"
                          >
                            <path d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z" />
                          </svg>
                          0.19%
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[80%] h-[550px] min-h-[650px] leading-[550px] text-center float-left">
          <div
            className="w-full h-[50px]"
            style={{ boxShadow: "0 2px 10px 0 rgb(0 0 0 / 5%)" }}
          >
            <div className="right-border-css w-[65%] h-[50px] float-left">
              <div className="right-border-css w-[70%] h-[50px] !pr-[0] !border-[0] !bg-[#1a1b1c] float-left">
                <div className="klinetitle">
                  <div className="klinetitle_l">
                    <p className="newpricebox fw f14 fcf">BTC/USDT</p>
                    <p className="changebox fw f14 fcf">Đặt lệnh</p>
                  </div>
                  <div className="klinetitle_l">
                    <p className="newpricebox fw">
                      <span className="green text-[22px]" />
                    </p>
                    <p className="changebox fw">
                      <span className="red fw">-0.2%</span>
                    </p>
                  </div>
                  <div className="klinetitle_r">
                    <div className="col-4 klinetitle-s-box">
                      <div className="newpricebox f14 fccs">Thấp nhất</div>
                      <div className="changebox f14 fccs">25766.75</div>
                    </div>
                    <div className="col-4 klinetitle-s-box">
                      <div className="newpricebox f14 fccs">Cao nhất</div>
                      <div className="changebox f14 fccs">25950.48</div>
                    </div>
                    <div className="col-4 klinetitle-s-box">
                      <div className="newpricebox f14 fccs">24hsố lượng</div>
                      <div className="changebox f14 fccs">1392.4588284586</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-border-css w-[30%] h-[50px] !bg-[#1a1b1c] !pl-[0] !border-[0] float-left" />
            </div>
            <div className="w-[35%] h-[50px] leading-[100px] bg-[#1a1b1c] pl-[20px] float-left">
              <div className="tcl w-full h-[40px] leading-[50px]">
                <div className="newpricebox fw f14 fcgs">BTC/USDT</div>
                <div className="changebox fw f14 fcgs">Hợp đồng</div>
              </div>
            </div>
          </div>
          <div className="w-full min-h-[630px] bg-[#1a1b1c]">
            <div className="w-full min-h-[550px] border-t-[2px] border-[#151617]">
              <div className="w-full min-h-[550px] float-left">
                <div className="right-border-css w-[65%] h-[630px] float-left">
                  <div className="w-full h-[600px] p-[8px]">
                    <div className="changInterval flex">
                      <span className="interval-item fcf !text-[14px] !border-[0]">
                        Thành Công
                      </span>
                      <div className="interval-item fcf active">1 M</div>
                      <div className="interval-item fcf">5 M</div>
                      <div className="interval-item fcf">15 M</div>
                      <div className="interval-item fcf">30 M</div>
                      <div className="interval-item fcf">60 M</div>
                      <div className="interval-item fcf">4 H</div>
                      <div className="interval-item fcf">1 Day</div>
                    </div>
                    <GameChart game_id={game_id} isLight={isLight} />
                  </div>
                </div>
                <div className="w-[35%] h-[550px] float-left">
                  <div className="w-full h-[550px] px-[10px]">
                    <div className="dong_order_c">
                      <p className="dong_order_p  tcl fccs fcf f14 !leading-[60px]">
                        Chu kỳ lựa chọn
                      </p>
                      <div className="dong_order_option">
                        {gameTypeList.map((item) => (
                          <div
                            key={item?.time}
                            className={`dong_order_option_list ${
                              option === item?.time && "!bg-[#f5465c]"
                            }`}
                            onClick={() => setOption(item?.time)}
                          >
                            <div className="w-full h-[20px] leading-[20px] text-center mt-[5px]">
                              <span className="fcf f12 vo-time">
                                {item?.text}
                              </span>
                            </div>
                            <div className="w-full h-[20px] leading-[20px] text-center mt-[5px]">
                              <span className="fcf f12 vo-ykbl">
                                {item?.profit}%
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                      <p className="dong_order_p tcl fccs fcf f14">Mua</p>
                      <div className="dong_money_list">
                        <div className="w-full h-[40px] text-center">
                          <input
                            type="text"
                            placeholder="Vui lòng nhập số tiền"
                            value={amount}
                            onChange={(event) => setAmount(event.target.value)}
                            className="w-[98%] h-[35px] leading-[35px] !text-white !bg-[#2c2d2e] pl-[15px] !border-[0] mt-[2px] m-[auto]"
                          />
                        </div>
                      </div>
                      <div className="dong_money_list">
                        <div className="dong_money_list_box !w-full">
                          <div className="dong_money_list_box_l !h-[60px]">
                            <div
                              className={`dong_money_list_box_option moveclass ${
                                amount === "100" && "!bg-[#f5465c]"
                              }`}
                              onClick={() => setAmount("100")}
                            >
                              <span className="fzm f12 fcf">100</span>
                            </div>
                            <div
                              className={`dong_money_list_box_option moveclass ${
                                amount === "200" && "!bg-[#f5465c]"
                              }`}
                              onClick={() => setAmount("200")}
                            >
                              <span className="fzm f12 fcf">200</span>
                            </div>
                            <div
                              className={`dong_money_list_box_option moveclass ${
                                amount === "500" && "!bg-[#f5465c]"
                              }`}
                              onClick={() => setAmount("500")}
                            >
                              <span className="fzm f12 fcf">500</span>
                            </div>
                            <div
                              className={`dong_money_list_box_option moveclass ${
                                amount === "1000" && "!bg-[#f5465c]"
                              }`}
                              onClick={() => setAmount("1000")}
                            >
                              <span className="fzm f12 fcf">1000</span>
                            </div>
                            <div
                              className={`dong_money_list_box_option moveclass ${
                                amount === "5000" && "!bg-[#f5465c]"
                              }`}
                              onClick={() => setAmount("5000")}
                            >
                              <span className="fzm f12 fcf">5000</span>
                            </div>
                            <div
                              className={`dong_money_list_box_option moveclass ${
                                amount === "10000" && "!bg-[#f5465c]"
                              }`}
                              onClick={() => setAmount("10000")}
                            >
                              <span className="fzm f12 fcf">10000</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tcl w-full h-[40px] leading-[30px] mt-[80px]">
                        <span className="dong_order_p f14 fccs !mb-[5px]">
                          Số dư tài khoản：1359897.1339 USD
                        </span>
                      </div>
                      <div className="tcc w-full h-[40px] leading-[30px] mt-[35px] flex justify-between">
                        <div className="ks_buy_up w-[45%] h-[40px] leading-[40px] text-center rounded-[10px] float-left">
                          <span className="f12 ks_buy">Mua</span>
                        </div>
                        <div className="ks_buy_down w-[40%] h-[40px] leading-[40px] text-center rounded-[10px] float-left">
                          <span className="f12 ks_buy">Bán</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full min-h-[360px] bg-[#151617] border-t-[1px] border-[#2c2d2e] mt-[10px]">
        <div className="order-top">
          <div
            className={`order-top-span order-top-current fcfs ${
              activeTab === 1 && "order-top-select"
            } `}
            onClick={() => setActiveTab(1)}
          >
            Hiện đang giao dịch
          </div>
          <div
            className={`order-top-span order-top-current fcfs ${
              activeTab === 2 && "order-top-select"
            } `}
            onClick={() => setActiveTab(2)}
          >
            Lịch sử
          </div>
          <div className="refresh-icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="inline"
            >
              <path
                d="M42 8V24"
                stroke="#303131"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 24L6 40"
                stroke="#303131"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 24C6 33.9411 14.0589 42 24 42C28.8556 42 33.2622 40.0774 36.5 36.9519"
                stroke="#303131"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M42.0007 24C42.0007 14.0589 33.9418 6 24.0007 6C18.9152 6 14.3223 8.10896 11.0488 11.5"
                stroke="#303131"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="order-main">
          <div
            className={`table-box order-main-table-current ${
              activeTab === 1 ? "!block" : "!hidden"
            }`}
          >
            <div className="table-history-more">
              <img src={imgEmpty} alt="" className="empty-svg inline" />
              <p className="tcc !text-[16px] !text-[#212529]">暂无订单</p>
            </div>
          </div>
          <div
            className={`table-box order-main-table-history ${
              activeTab === 2 ? "!block" : "!hidden"
            }`}
          >
            <table className="table tcc order-main-table w-full">
              <thead>
                <tr className="fccs">
                  <td>Cặp tiền/td&gt;</td>
                  <td>Lệnh</td>
                  <td>Tình Trạng</td>
                  <td>Số tiền vào lệnh</td>
                  <td>Giá mua vào</td>
                  <td>Giá bán ra</td>
                  <td>Thời gian vào lệnh</td>
                  <td>Thời gian kết thúc</td>
                  <td>Phí giao dịch</td>
                </tr>
              </thead>
              <tbody>
                <tr className="fcf f12">
                  <td>BTC/USDT</td>
                  <td className="f14 fw fgreen">Mua lên</td>
                  <td>Đã kết toán</td>
                  <td>100.00</td>
                  <td>25950.99</td>
                  <td>25950.23</td>
                  <td>2023-08-28 13:31:54</td>
                  <td>2023-08-28 13:33:54</td>
                  <td className="fcf f14 fred"> -10.00</td>
                </tr>
              </tbody>
            </table>
            <div className="table-history-more">
              <Link to="/trade/contractpc">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 384 512"
                  fill="#e6ecf2"
                  className="inline"
                >
                  <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" />
                </svg>
                <span className="tcc !text-[16px] !text-[#007bff] ml-[5px]">
                  Xem thêm
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ContractDesktop;
