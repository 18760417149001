import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faCopy,
  faGlobe,
  faWallet,
  faShield,
  faQuestionCircle,
  faBell,
  faArrowLeftLong,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import { useStores } from "_common/hooks";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApi } from "states/api";
import { observer } from "mobx-react-lite";
import i18next from "i18next";
import { handleCopyLink } from "_common/utils/helper";

const MemberPage = observer(() => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    authStore: { user, game_wallet, general, clear },
  } = useStores();

  const { mutate: onLogout } = useMutation(AuthApi.logout, {
    onSuccess: (res) => {
      clear();
    },
    onError: (error) => {
      clear();
      const errorMessage =
        i18next.t(error?.message) ?? i18next.t("action_failed_msg");
      NotificationManager.error(errorMessage);
    },
  });

  const onGetProfile = () => {
    queryClient.invalidateQueries(["get_profile"]);
  };

  return (
    <>
      <div className="no_header w-full max-w-[500px] fixed top-[0px] bg-[transparent] px-[10px] z-[9999]">
        <div className="fl bhalf allhg txtl leading-[50px]">
          <FontAwesomeIcon
            icon={faArrowLeftLong}
            width="24"
            height="24"
            color="white"
            className="w-[24px] h-[24px]"
            onClick={() => navigate(-1)}
          />
        </div>
      </div>
      <div className="member-page">
        <div className="userbox">
          <div className="userbox_left">
            <img src={user?.avatar_url} className="avatar" alt="" />
          </div>
          <div className="userbox_right">
            <p className="text-[20px] mt-[20px] mb-[5px]">
              {user?.full_name || user?.username}
            </p>
            <span className="fzm">Mã mời：{user?.ref_no}&nbsp;&nbsp;</span>
            <FontAwesomeIcon
              icon={faCopy}
              width="40"
              height="20"
              color="white"
              onClick={() => handleCopyLink(user?.ref_no)}
            />
          </div>
        </div>
        <div className="no_inbox lbox_f_br !mt-[-60px]">
          <Link to="/member/kyc">
            <div className="lbox lbox_f_br lbox-j">
              <div className="lbox_l flex items-center">
                <svg
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  fontSize="22"
                >
                  <path
                    d="M511.953455 1002.146909c-142.987636 0-408.901818-218.763636-408.901818-425.634909L103.051636 164.421818l40.657455-0.674909c0.861091 0 91.624727-1.931636 185.274182-39.936 96.046545-39.028364 157.998545-83.828364 158.580364-84.247273l24.273455-17.687273 24.482909 17.687273c0.581818 0.442182 62.533818 45.218909 158.580364 84.247273 93.649455 38.004364 184.413091 39.936 185.367273 39.936l40.471273 0.674909 0.186182 412.090182C920.948364 783.36 655.034182 1002.146909 511.953455 1002.146909L511.953455 1002.146909zM185.623273 243.409455l0 333.079273c0 159.953455 231.633455 343.063273 326.330182 343.063273 94.72 0 326.330182-183.109818 326.330182-343.063273L838.283636 243.409455c-40.471273-4.375273-106.170182-15.429818-174.405818-43.124364-69.934545-28.439273-123.042909-59.345455-151.947636-77.754182-28.811636 18.408727-81.989818 49.314909-151.854545 77.754182C291.793455 228.002909 226.071273 239.034182 185.623273 243.409455L185.623273 243.409455zM490.077091 731.345455l-173.614545-147.898182 53.387636-62.813091 111.383273 94.813091 211.386182-243.525818 62.417455 54.155636L490.077091 731.345455 490.077091 731.345455zM490.077091 731.345455"
                    fill="#707a8a"
                  />
                </svg>
                <span className="fe6im f16">
                  &nbsp;&nbsp;
                  {!user?.is_verify && (
                    <span className="text-[#f5465c]">
                      {i18next.t("un_verify")}
                    </span>
                  )}
                  {user?.is_verify === 1 && (
                    <span className="text-[#f5465c]">
                      {i18next.t("verification_progress")}
                    </span>
                  )}
                  {user?.is_verify >= 2 && (
                    <span className="text-green">{i18next.t("verified")}</span>
                  )}
                </span>
              </div>
              <div className="lbox_r">
                {!user?.is_verify && (
                  <span className="text-[#f5465c]">
                    {i18next.t("un_verify")}
                  </span>
                )}
                {user?.is_verify === 1 && (
                  <span className="text-[#f5465c]">
                    {i18next.t("verification_progress")}
                  </span>
                )}
                {user?.is_verify >= 2 && (
                  <span className="text-green">{i18next.t("verified")}</span>
                )}
                <FontAwesomeIcon
                  icon={faChevronRight}
                  width="16"
                  color="#707A8A"
                />
              </div>
            </div>
          </Link>
          <Link to="/member/language">
            <div className="lbox ">
              <div className="lbox_l flex items-center">
                <FontAwesomeIcon
                  icon={faGlobe}
                  width="22"
                  fontSize="22"
                  color="#707A8A"
                />
                <span className="fe6im f16">&nbsp;&nbsp;Ngôn ngữ</span>
              </div>
              <div className="lbox_r">
                <FontAwesomeIcon
                  icon={faChevronRight}
                  width="16"
                  color="#707A8A"
                />
              </div>
            </div>
          </Link>
          <Link to="/member/bank">
            <div className="lbox ">
              <div className="lbox_l flex items-center">
                <FontAwesomeIcon
                  icon={faWallet}
                  width="22"
                  fontSize="22"
                  color="#707A8A"
                />
                <span className="fe6im f16">&nbsp;&nbsp;Số tài khoản</span>
              </div>
              <div className="lbox_r">
                <FontAwesomeIcon
                  icon={faChevronRight}
                  width="16"
                  color="#707A8A"
                />
              </div>
            </div>
          </Link>
          <Link to="/board/history">
            <div className="lbox ">
              <div className="lbox_l flex items-center">
                <FontAwesomeIcon
                  icon={faFile}
                  width="22"
                  fontSize="22"
                  color="#707A8A"
                />
                <span className="fe6im f16">&nbsp;&nbsp;Biến động số dư</span>
              </div>
              <div className="lbox_r">
                <FontAwesomeIcon
                  icon={faChevronRight}
                  width="16"
                  color="#707A8A"
                />
              </div>
            </div>
          </Link>
          <Link to="/member/security">
            <div className="lbox lbox-j">
              <div className="lbox_l flex items-center">
                <FontAwesomeIcon
                  icon={faShield}
                  width="22"
                  fontSize="22"
                  color="#707A8A"
                />
                <span className="fe6im f16">&nbsp;&nbsp;Cài đặt mật khẩu</span>
              </div>
              <div className="lbox_r">
                <FontAwesomeIcon
                  icon={faChevronRight}
                  width="16"
                  color="#707A8A"
                />
              </div>
            </div>
          </Link>
          <Link to="/member/introduction">
            <div className="lbox">
              <div className="lbox_l flex items-center">
                <FontAwesomeIcon
                  icon={faQuestionCircle}
                  width="22"
                  fontSize="22"
                  color="#707A8A"
                />
                <span className="fe6im f16">
                  &nbsp;&nbsp;Trung tâm trợ giúp
                </span>
              </div>
              <div className="lbox_r">
                <FontAwesomeIcon
                  icon={faChevronRight}
                  width="16"
                  color="#707A8A"
                />
              </div>
            </div>
          </Link>
          <Link to="/member/notice">
            <div className="lbox ">
              <div className="lbox_l flex items-center">
                <FontAwesomeIcon
                  icon={faBell}
                  width="22"
                  fontSize="22"
                  color="#707A8A"
                />
                <span className="fe6im f16">&nbsp;&nbsp;Thông báo</span>
              </div>
              <div className="lbox_r">
                <span className="f12 fe6im">Thông báo mới</span>{" "}
                <FontAwesomeIcon
                  icon={faChevronRight}
                  width="16"
                  color="#707A8A"
                />
              </div>
            </div>
          </Link>
          <Link to="/member/sharing">
            <div className="lbox">
              <div className="lbox_l flex items-center">
                <svg
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  fontSize="22"
                >
                  <path
                    d="M901.12 1024H122.88C57.344 1024 4.096 970.752 4.096 905.216v-389.12c0-43.008 22.528-79.872 55.296-100.352 32.768-18.432 71.68-18.432 106.496 2.048l311.296 186.368c20.48 12.288 47.104 12.288 67.584 0L860.16 415.744c36.864-18.432 77.824-16.384 108.544 4.096s49.152 55.296 49.152 98.304v389.12c2.048 63.488-51.2 116.736-116.736 116.736zM112.64 493.568c-2.048 0-4.096 0-6.144 2.048-6.144 4.096-10.24 12.288-10.24 22.528v389.12c0 14.336 12.288 26.624 26.624 26.624h778.24c14.336 0 26.624-12.288 26.624-26.624v-389.12c0-10.24-2.048-18.432-6.144-20.48-4.096-2.048-10.24-2.048-20.48 2.048l-309.248 186.368c-49.152 30.72-112.64 30.72-161.792 0L120.832 495.616c-4.096 0-6.144-2.048-8.192-2.048zM815.104 376.832c-24.576 0-45.056-20.48-45.056-45.056v-204.8c0-18.432-16.384-34.816-34.816-34.816H288.768c-18.432 0-34.816 16.384-34.816 34.816v204.8c0 24.576-20.48 45.056-45.056 45.056-24.576 0-45.056-20.48-45.056-45.056v-204.8C163.84 55.296 219.136 0 288.768 0h444.416C802.816 0 860.16 55.296 860.16 124.928v204.8c0 26.624-20.48 47.104-45.056 47.104z"
                    fill="#707a8a"
                  />
                  <path
                    d="M593.92 370.688h-165.888c-24.576 0-45.056-20.48-45.056-45.056 0-24.576 20.48-45.056 45.056-45.056H593.92c24.576 0 45.056 20.48 45.056 45.056 2.048 24.576-18.432 45.056-45.056 45.056z"
                    fill="#707a8a"
                  />
                  <path
                    d="M466.944 407.552v-165.888c0-24.576 20.48-45.056 45.056-45.056 24.576 0 45.056 20.48 45.056 45.056v165.888c0 24.576-20.48 45.056-45.056 45.056-24.576 2.048-45.056-18.432-45.056-45.056z"
                    fill="#707a8a"
                  />
                </svg>
                <span className="fe6im f16">&nbsp;&nbsp;Lời mời của tôi</span>
              </div>
              <div className="lbox_r">
                <FontAwesomeIcon
                  icon={faChevronRight}
                  width="16"
                  color="#707A8A"
                />
              </div>
            </div>
          </Link>
          <Link to="/member/about">
            <div className="lbox  lbox-j">
              <div className="lbox_l flex items-center">
                <svg
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                >
                  <path
                    d="M512 64c247.424 0 448 200.576 448 448s-200.576 448-448 448S64 759.424 64 512 264.576 64 512 64z m0 85.333333C311.701333 149.333333 149.333333 311.701333 149.333333 512s162.368 362.666667 362.666667 362.666667 362.666667-162.368 362.666667-362.666667S712.298667 149.333333 512 149.333333z m21.333333 277.333334a21.333333 21.333333 0 0 1 21.333334 21.333333v298.666667a21.333333 21.333333 0 0 1-21.333334 21.333333h-42.666666a21.333333 21.333333 0 0 1-21.333334-21.333333V448a21.333333 21.333333 0 0 1 21.333334-21.333333h42.666666z m0-170.666667a21.333333 21.333333 0 0 1 21.333334 21.333333v42.666667a21.333333 21.333333 0 0 1-21.333334 21.333333h-42.666666a21.333333 21.333333 0 0 1-21.333334-21.333333v-42.666667a21.333333 21.333333 0 0 1 21.333334-21.333333h42.666666z"
                    fill="#707a8a"
                  />
                </svg>
                <span className="fe6im f16">&nbsp;&nbsp;Về chúng tôi</span>
              </div>
              <div className="lbox_r">
                <FontAwesomeIcon
                  icon={faChevronRight}
                  width="16"
                  color="#707A8A"
                />
              </div>
            </div>
          </Link>
          <Link to="/member/msb">
            <div className="lbox">
              <div className="lbox_l flex items-center">
                <svg
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  fontSize="22"
                >
                  <path
                    d="M511.5 81c22.108 0 44.217 8.434 61.08 25.3l77.944 77.947h107.59c47.71 0 86.38 38.674 86.38 86.38v107.59l74.204 74.204c33.736 33.733 33.736 88.426 0 122.16l-74.205 74.205v111.329c0 47.706-38.67 86.38-86.38 86.38H646.786l-74.205 74.204C555.716 937.566 533.608 946 511.5 946c-22.11 0-44.218-8.434-61.081-25.3l-74.205-74.205H268.626c-47.704 0-86.38-38.674-86.38-86.38V652.528l-77.944-77.947c-33.736-33.733-33.736-88.426 0-122.16l77.943-77.947V270.628c0-47.705 38.677-86.38 86.38-86.38h103.85l77.943-77.947C467.283 89.435 489.39 81 511.498 81z m160.077 343.217c-11.677-11.677-30.608-11.677-42.285 0L486.564 566.944l-61.54-59.428c-11.76-11.357-30.43-11.147-41.932 0.385l-0.347 0.353-0.01 0.01c-11.47 11.878-11.14 30.807 0.739 42.278l81.098 78.316a29.762 29.762 0 0 0 13.637 7.533c10.422 3.505 22.393 1.106 30.696-7.197l162.682-162.682c11.677-11.677 11.677-30.609 0-42.285z"
                    fill="#707a8a"
                  />
                </svg>
                <span className="fe6im f16">&nbsp;&nbsp;Chứng nhận MSB</span>
              </div>
              <div className="lbox_r">
                <FontAwesomeIcon
                  icon={faChevronRight}
                  width="16"
                  color="#707A8A"
                />
              </div>
            </div>
          </Link>
          <Link to="https://download.aismex.one">
            <div className="lbox">
              <div className="lbox_l flex items-center">
                <svg
                  width="22"
                  fontSize="22"
                  height="22"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.6777 20.271C7.27476 21.3181 4 25.2766 4 30C4 35.5228 8.47715 40 14 40C14.9474 40 15.864 39.8683 16.7325 39.6221"
                    stroke="#707a8a"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M36.0547 20.271C40.4577 21.3181 43.7324 25.2766 43.7324 30C43.7324 35.5228 39.2553 40 33.7324 40C32.785 40 31.8684 39.8683 30.9999 39.6221"
                    stroke="#707a8a"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M36 20C36 13.3726 30.6274 8 24 8C17.3726 8 12 13.3726 12 20"
                    stroke="#707a8a"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17.0654 30.119L23.9999 37.0764L31.1318 30"
                    stroke="#707a8a"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M24 20V33.5382"
                    stroke="#707a8a"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="fe6im f16">
                  &nbsp;&nbsp;Tải xuống ứng dụng
                </span>
              </div>
              <div className="lbox_r">
                <FontAwesomeIcon
                  icon={faChevronRight}
                  width="16"
                  color="#707A8A"
                />
              </div>
            </div>
          </Link>
          <div onClick={onLogout}>
            <div className="lbox">
              <div className="lbox_l flex items-center fcc">
                <svg
                  width="22"
                  fontSize="22"
                  height="22"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="48"
                    height="48"
                    fill="white"
                    fillOpacity="0.01"
                  />
                  <path
                    d="M23.9917 6L6 6L6 42H24"
                    stroke="#707A8A"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M33 33L42 24L33 15"
                    stroke="#555555"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16 23.9917H42"
                    stroke="#555555"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span className="fe6im f16">&nbsp;&nbsp;Đăng xuất an toàn</span>
              </div>
              <div className="lbox_r">
                <FontAwesomeIcon
                  icon={faChevronRight}
                  width="16"
                  color="#707A8A"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default MemberPage;
