import React from "react";
import { Routes, Route } from "react-router-dom";

import BasicLayout from "_common/component/Layout/BasicLayout";
import NoFooterLayout from "_common/component/Layout/NoFooterLayout";
import UnAuthLayout from "_common/component/Layout/UnAuthLayout";
import PageNotfound from "_common/component/PageNotfound";

// auth
import LoginPage from "pages/Auth/Login";
import RegisterPage from "pages/Auth/Register";

import { MiddlewareRouter } from "routers/MiddlewareRouter";
import CheckAuth from "routers/CheckAuth";
import MemberPage from "pages/Member";
import ListHistoryBalance from "pages/Wallet/ListHistoryBalance";
import Deposit from "pages/Wallet/Deposit/Deposit";

import HomeUnAuth from "pages/HomeUnAuth";
import WalletPage from "pages/Wallet";
import FundPage from "pages/Fund";
import ReportPage from "pages/Report";
import TradePage from "pages/Trade";
import MarketListPage from "pages/Market";
import Withdraw from "pages/Wallet/Withdraw/Withdraw";
import Recharge from "pages/Wallet/Deposit/Recharge";
import KYC from "pages/Member/KYC";
import UpdateUser from "pages/Member/UpdateUser";
import Security from "pages/Member/Security";
import Password from "pages/Member/Password";
import Complain from "pages/Wallet/Complain";
import WithdrawSubmit from "pages/Wallet/Withdraw/WithdrawSubmit";
import ShareLink from "pages/Member/ShareLink";
import HistoryTradePage from "pages/Trade/Mobile/HistoryTradePage";
import About from "pages/Member/About";
import Exchange from "pages/Wallet/Exchange";
import Introduction from "pages/Member/Introduction";
import FeaturePage from "pages/Feature";
import SpotTradePage from "pages/SpotTrade";
import KYCFirst from "pages/Member/KYCFirst";
import AddBankCard from "pages/Wallet/Bank/AddBankCard";
import CheckAuthHome from "routers/CheckAuthHome";
import UserInfoPage from "pages/Member/UserInfo";
import WalletPassword from "pages/Member/WalletPassword";
import IntroductionDetail from "pages/Member/IntroductionDetail";
import WithdrawBank from "pages/Wallet/WithdrawBank/WithdrawSubmit";
import Language from "pages/Member/Language";
import FundList from "pages/Fund/FundList";
import FundDetail from "pages/Fund/FundDetail";
import FundHistory from "pages/Fund/FundHistory";
import AddBankForm from "pages/Wallet/Bank/AddBankForm";
import HistoryFeaturePage from "pages/Feature/HistoryFeaturePage";
import SetTime from "pages/Member/SetTime";
import WalletAddress from "pages/Wallet/WalletAddress";
import WalletList from "pages/Wallet/WalletAddress/WalletList";
import Notice from "pages/Member/Notice";
import MSB from "pages/Member/MSB";
import IEOPage from "pages/IEO";
import StakingPage from "pages/Staking";
import DepositBank from "pages/Wallet/Deposit/DepositBank";
import FinancePage from "pages/Finance";
import DepositPage from "pages/Finance/Deposit";
import DepositHistoryPage from "pages/Finance/DeppositHistory";
import WithdrawPage from "pages/Finance/Withdraw";
import WithdrawHistoryPage from "pages/Finance/WithdrawHistory";
import ProfitListPage from "pages/Orepool/ProfitList";
import OrderPage from "pages/Trade/Mobile/Order";
import OrderHistoryPage from "pages/Trade/Mobile/OrderHistory";
import ContractTyPage from "pages/Trade/Desktop/ContractTy";
import ContractJcPage from "pages/Trade/Desktop/ContractJc";
import ContractPcPage from "pages/Trade/Desktop/ContractPc";
import NormalMinPage from "pages/Orepool/NormalMin";
import OverDueMinPage from "pages/Orepool/OverDueMin";
import NormalIssuePage from "pages/Issue/NormalIssue";
import OverDueIssuePage from "pages/Issue/OverDueIssue";
import UserPage from "pages/User";
import AddressListPage from "pages/User/AddressList";
import AuthPage from "pages/User/Auth";
import RePasswordPage from "pages/User/RePassword";
import TGCodePage from "pages/User/TGCode";
import NoticePage from "pages/User/Notice";
import MyBillPage from "pages/User/MyBill";
import NotiCenterPage from "pages/NotiCenter";
import NotiDetailPage from "pages/NotiCenter/NotiDetail";
import IssuePage from "pages/Issue";
import OrepoolPage from "pages/Orepool";

export function Routers() {
  return (
    <Routes>
      <Route
        path="/cskh"
        element={
          <CheckAuthHome>
            <UnAuthLayout />
          </CheckAuthHome>
        }
      >
        <Route index element={<Complain />} />
      </Route>

      <Route path="/" element={<BasicLayout />}>
        <Route index element={<HomeUnAuth />} />
        <Route path="home" element={<HomeUnAuth />} />
        <Route path="market" element={<MarketListPage />} />

        <Route path="finance">
          <Route index element={<FinancePage />} />
          <Route path="deposit" element={<DepositPage />} />
          <Route path="deposit-history" element={<DepositHistoryPage />} />
          <Route path="withdraw" element={<WithdrawPage />} />
          <Route path="withdraw-history" element={<WithdrawHistoryPage />} />
        </Route>
        <Route path="orepool">
          <Route index element={<OrepoolPage />} />
          <Route path="profit-list" element={<ProfitListPage />} />
          <Route path="normalmin" element={<NormalMinPage />} />
          <Route path="overduemin" element={<OverDueMinPage />} />
        </Route>
        <Route path="issue">
          <Route index element={<IssuePage />} />
          <Route path="normalissue" element={<NormalIssuePage />} />
          <Route path="overdueissue" element={<OverDueIssuePage />} />
        </Route>
        <Route path="user">
          <Route index element={<UserPage />} />
          <Route path="addresslist" element={<AddressListPage />} />
          <Route path="authrz" element={<AuthPage />} />
          <Route path="respwd" element={<RePasswordPage />} />
          <Route path="tgcode" element={<TGCodePage />} />
          <Route path="notice" element={<NoticePage />} />
          <Route path="mybill" element={<MyBillPage />} />
        </Route>
        <Route path="gglist">
          <Route index element={<NotiCenterPage />} />
          <Route path="detail/:id" element={<NotiDetailPage />} />
        </Route>
      </Route>

      <Route
        path="/board"
        element={
          <MiddlewareRouter>
            <BasicLayout />
          </MiddlewareRouter>
        }
      >
        <Route index element={<TradePage />} />
        <Route path="home" element={<TradePage />} />
        <Route path="wallet" element={<WalletPage />} />
        <Route path="fund">
          <Route index element={<FundPage />} />
          <Route path="list" element={<FundList />} />
          <Route path="history" element={<FundHistory />} />
          <Route path="detail/:id" element={<FundDetail />} />
        </Route>
        <Route path="report" element={<ReportPage />} />
        <Route path="history" element={<ListHistoryBalance />} />
        <Route path="exchange" element={<Exchange />} />
        <Route path="spot" element={<SpotTradePage />} />
        <Route path="feature">
          <Route index element={<FeaturePage />} />
          <Route path="history" element={<HistoryFeaturePage />} />
        </Route>
      </Route>

      <Route
        path="/member"
        element={
          <MiddlewareRouter>
            <NoFooterLayout />
          </MiddlewareRouter>
        }
      >
        <Route index element={<MemberPage />} />
        <Route path="info" element={<UserInfoPage />} />
        <Route path="kyc" element={<KYC />} />
        <Route path="kyc/first" element={<KYCFirst />} />
        <Route path="update-profile" element={<UpdateUser />} />
        <Route path="sharing" element={<ShareLink />} />
        <Route path="bank" element={<AddBankCard />} />
        <Route path="bank/submit" element={<AddBankForm />} />
        <Route path="about" element={<About />} />
        <Route path="wallet" element={<WalletList />} />
        <Route path="add-wallet" element={<WalletAddress />} />
        <Route path="language" element={<Language />} />
        <Route path="line" element={<SetTime />} />
        <Route path="introduction" element={<Introduction />} />
        <Route
          path="introduction/detail/:id"
          element={<IntroductionDetail />}
        />
        <Route path="security" element={<Security />} />
        <Route path="password" element={<Password />} />
        <Route path="wallet-password" element={<WalletPassword />} />
        <Route path="recharge" element={<Recharge />} />
        <Route path="deposit" element={<Deposit />} />
        <Route path="deposit-bank" element={<DepositBank />} />
        <Route path="withdraw">
          <Route index element={<Withdraw />} />
          <Route path="submit" element={<WithdrawSubmit />} />
          <Route path="bank/submit" element={<WithdrawBank />} />
        </Route>
        <Route path="notice" element={<Notice />} />
        <Route path="msb" element={<MSB />} />
      </Route>

      <Route
        element={
          <MiddlewareRouter>
            <NoFooterLayout />
          </MiddlewareRouter>
        }
      >
        <Route path="trade" element={<TradePage />} />
        <Route path="trade/history" element={<HistoryTradePage />} />
        <Route path="ieo" element={<IEOPage />} />
        <Route path="staking" element={<StakingPage />} />
        <Route path="order" element={<OrderPage />} />
        <Route path="order-history" element={<OrderHistoryPage />} />
        <Route path="contract-ty" element={<ContractTyPage />} />
        <Route path="contractjc" element={<ContractJcPage />} />
        <Route path="contractpc" element={<ContractPcPage />} />
      </Route>

      <Route
        path="auth"
        element={
          <CheckAuth>
            <UnAuthLayout />
          </CheckAuth>
        }
      >
        <Route path="login" element={<LoginPage />} />
        <Route path="register" element={<RegisterPage />} />
      </Route>
      <Route path="*" element={<PageNotfound />} />
    </Routes>
  );
}
